<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="">
        
        <!-- <div class="progress-bar">
            <img src="../../../assets/step1of3.png" alt="step 1 of 3 progress bar" class="progress-bar-img">
        </div>

        <hr> -->

        <!-- UPDATE INFO SECTION -->
        <div class="secondaryContainer">
            
            <!-- NAME -->
            <div class="row" *ngIf="show=='name'">
                <div class="col-sm-12">
                    <h3 class="title">School Name</h3>
                    <p class="desc">Please enter the exact name of your school. You may use abbreviations or initialisms that are recognizable to your community. </p>
                   <!-- <p><strong>Current: </strong>{{ curr_name }}</p>
                    -->
                    <input type="text" [(ngModel)]="name"class="textFieldInput" placeholder="Enter Here">
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn input-btn" 
                    (click)="updateInfo('name', name)"
                    [disabled]="!name">Setup</button>
                </div> -->
            </div> 

            <!-- LOGO -->
            <div class="row" *ngIf="show=='logo'">
                <div class="col-sm-12">
                    <h3 class="title">School Logo</h3>
                    <p class="desc">Please upload a sqaure PNG image of your school logo.</p>
                    <!--<p><strong>Current:</strong></p>
                -->
                    <input type="file" name="logo_upload" (change)="uploadFile($event)" accept="image/*">
                    <br>
                    <img [src]=curr_logo alt="school logo" id="logo" width="200px" height="200px">

                    <!-- <div>{{ uploadPercent | async }}</div>
                    <a [href]="downloadURL | async">{{ downloadURL | async }}</a> -->
                </div>
                <!-- <div class="col-sm-2">
                </div> -->
            </div>

            <!-- WEBSITE -->
            <div class="row" *ngIf="show=='website'">
                <div class="col-sm-12">
                    <h3 class="title">Additional Links and Information</h3>
                    <p class="desc">Please add a URL that directs users to more information about your school's health policy. If you do not have this you may also 
                        link a calendar, or seperate page on your school's website. 
                        </p>
                    <br>
                    <!-- <p><strong>Current: </strong>{{ curr_website }}</p>
                    -->
                    <input type="text" [(ngModel)]="website" class="textFieldInput" placeholder="Enter Here">
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn input-btn" 
                    (click)="updateInfo('website', website)"
                    [disabled]="!website">Setup</button>
                </div> -->
            </div> 

           

            <!-- EMAIL -->
            <div class="row" *ngIf="show=='email'">
                <div class="col-sm-12">
                    <h3 class="title">COVID-19 Support Email</h3>
                    <p class="desc">Please enter your school's COVID-19 email hotline. This is oftentimes the front desk, school nurse, or head of school. Parents or students
                        may use this email for questions about policies regarding your school re-entry plan. 
                    </p> <!-- MARK:
            CHECK TO ENSURE THIS IS ACTUALLY AN EMAIL
            -->
                    <br>
                   <!--- <p><strong>Current: </strong>{{ curr_adminEmail }}</p>-->
                    <input type="text" [(ngModel)]="adminEmail"class="textFieldInput" placeholder="Enter Here">
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn input-btn" 
                    (click)="updateInfo('adminEmail', adminEmail)"
                    [disabled]="!adminEmail">Setup</button>
                </div> -->
            </div> 

            <!-- MAIN MESSAGE -->
            <div class="row" *ngIf="show=='main'">
                <div class="col-sm-12">
                    <h3 class="title">Main Message</h3>
                    <p class="desc">This is the message all users will see at the top of the SWS app. Below, we have automatically entered default text. You may change and modify 
                        this text at any time after registration, or enter a custom message now which you will also have the ability to modify.</p>
                    <br>

                     <!---<p><strong>Current: </strong>{{ curr_paragraph }}</p>
                    --->
                    <textarea [(ngModel)]="paragraph"class="textFieldInput"></textarea>
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn XL" 
                    (click)="updateInfo('paragraph', paragraph)"
                    [disabled]="!paragraph">Setup</button>
                </div> -->
            </div> 

            <!-- CLEARED MESSAGE -->
            <div class="row" *ngIf="show=='cleared'">
                <div class="col-sm-12">
                    <h3 class="title">Screener Cleared Message</h3>
                    <p class="desc">This is the message that will appear when users successfully complete the wellness screener on SWS. Below, we have entered default text. You may also edit/modify this
                        at any time after registration.
                    </p>
                    <br>
                     <!--<p><strong>Current: </strong>{{ curr_clearedText }}</p>--->
                    <textarea [(ngModel)]="clearedText"class="textFieldInput"></textarea>
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn" 
                    (click)="updateInfo('clearedText', clearedText)"
                    [disabled]="!clearedText">Setup</button>
                </div> -->
            </div> 

            <!-- ISSUE MESSAGE -->
            <div class="row" *ngIf="show=='issue'">
                <div class="col-sm-12">
                    <h3 class="title">Screener Issue Message</h3>
                    <p class="desc">This is the message that will appear when users have submitted one or more issues with the screener. You may also edit/modify this
                        at any time after registration.
                    </p>

                   <!--<p><strong>Current: </strong>{{ curr_issueText }}</p>--> 
                    <textarea [(ngModel)]="issueText"class="textFieldInput"></textarea>
                </div>
                <!-- <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn" 
                    (click)="updateInfo('issueText', issueText)"
                    [disabled]="!issueText">Setup</button>
                </div> -->
            </div>

            <button class="btn back-btn" (click)=back(show)> Back </button>
            <button class="btn update-btn" (click)=next(show) [disabled]="disable()"> Next </button>
        </div>
    </div>
</body>
</html>