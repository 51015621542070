<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">

        <div class="textbox" style="border: 2px solid gray; padding: 20px; border-radius: 10px;" *ngIf="!userChosen">
            <h1 style="color: rgb(94, 94, 94);">Pick User For Screener</h1> 
            <!-- <hr> -->
            <div *ngFor="let user of umbrellaUsers" style="margin-top: 0px; padding-top: 0px;">
                <button style="background: none; border: 0px;" (click)="setUser(user.id)"> 
                    <h3 style="color: rgb(177, 177, 177); font-weight: normal;"> {{ user.name }} </h3>
                </button>
            </div>
        </div>

        <div class="textbox" *ngIf="userChosen && !disclaimer_accepted">
            <h1 style="margin-bottom: 15px;">SWS Disclaimer</h1>
            <p class="question-head">
                By using this screener, you agree to our <a href="https://schoolwellnessscreener.com/privacy-policy" target="_blank">Privacy Policy</a>. 
                Questions and results are determined by {{ schoolName }}, <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank">the CDC</a>, 
                and <a href="https://www.who.int" target="_blank">the WHO</a>.</p>
            <p class="question-head">By answering the following questions, you are affirming that your answers are both fair and accurate.</p>
            <button class="btn" (click)="disclaimer_accepted = true" style="color: white; background: #348C80;">Continue</button>
            <button class="btn" (click)=backToLogin() style="color: black;">Exit</button> 
        </div>

        <div *ngIf="disclaimer_accepted">

            <!-- SCREENER QUESTIONS -->
            <div class="question" *ngIf="questionNum < questions.length">
                <div class="page">
                    <div class="textbox">
                        <h1>Question {{ questionNum + 1}}</h1>
                        <p class="question-head">{{ questions[questionNum] }}</p>
                        <br>
                        <button class="btn no-btn" (click)=no()>
                            <img src="../../assets/check_white.png" alt="clear" class="icon">
                            No</button>
                        <br>
                        <button class="btn yes-btn" (click)=yes()>
                            <img src="../../assets/exclamation_orange.png" alt="issue" class="icon">
                            Yes</button>
                    </div>
                </div>
            </div>
            
            <!-- ISSUE -->
            <div class="issue textbox" *ngIf="questionNum == questions.length && hasIssue == true">
                <div class="page">
                    <h1 style="color: #ff6b41;">Issue</h1>
                    <p class="question-head">{{ issue_msg }}</p>
                    <br> 
                    <button class="btn back-btn" style="background: #ff6b41;" (click)="backToLogin()">Back to Screener Login</button>
                    <button class="btn back-btn" style="color: gray;" (click)="chooseDifferentUser()">Choose Another User</button>
                </div>
            </div>

            <!-- CLEAR -->
            <div class="clear textbox" *ngIf="questionNum == questions.length && hasIssue == false">
                <div class="page">
                    <h1 style="color: #348C80;">Clear</h1>
                    <p class="question-head">{{ clear_msg }}</p>
                    <br>
                    <button class="btn back-btn" style="background: #348C80;" (click)="backToLogin()">Exit Screener</button>
                    <button class="btn back-btn" style="color: gray;" (click)="chooseDifferentUser()">Choose Another User</button>
                </div>
            </div>

            <!-- LOADING -->
            <div class="loading" *ngIf="questions.length == 0">
                <div class="page">
                    <h1>loading..</h1>
                </div>
            </div>

        </div>
        
        <div class="footer">
            © 2022 Vision Applications, Inc. & SWS™
        </div>

    </div>
</body>
</html>