import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { Student } from './../objects/Student';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-screener',
  templateUrl: './web-screener.component.html',
  styleUrls: ['./web-screener.component.scss']
})
export class WebScreenerComponent implements OnInit, OnDestroy {

  // login info
  schoolID: string = ""
  user: Student = new Student("test", "test", "test", "test", "test", "TEST ID", "test", "test")
  schoolName:string = ""

  umbrellaUsers: object[] = []

  submitUserID = ""

  // questions info
  questions: string[] = []
  issues: string[] = []

  questionNum: number = 0;

  // error status info
  hasIssue: boolean

  // messages
  issue_msg = ""
  clear_msg = ""

  userChosen = false;
  disclaimer_accepted = false;

  // SUBSCRIPTIONS
  authSub: Subscription

  constructor(
    private fireservice: AngularfireService, 
    private authservice: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authSub = this.authservice.authState.pipe(first()).subscribe(authState => {
      this.fireservice.getUserDetails(authState).pipe(first()).subscribe(info => {
        // load user
        this.user = new Student(info["name"], info["email"], "", info["account"], "", Object.keys(info["people"])[0], info["umbrella"], info["comments"])

        // load school info/questions
        this.schoolID = info["school"]
                
        this.fireservice.getUmbrellaUsers(this.schoolID, this.user.umbrella).pipe(first()).subscribe(users => {
          this.umbrellaUsers = [];
          users.forEach(user => {
            this.umbrellaUsers.push({name: user.name, id: user.id})
          })
        })

        this.loadQuestions()
        this.loadInfo()
      })
    })
  }

  ngOnDestroy(): void {
    if(this.authSub) this.authSub.unsubscribe()
  }

  setUser(id: string){
    this.submitUserID = id;
    this.userChosen = true;
  }

  loadQuestions(){
    this.fireservice.getQuestions(this.schoolID).pipe(first()).subscribe(questions => {
      // clear old questions
      this.questions = []
      // load new questions
      for(let i = 0; i < questions.length; i++){
        this.questions.push(String(questions[i].payload.doc.data()["title"]))
      }
    })
  }

  loadInfo(){
    this.fireservice.getInfo(this.schoolID).pipe(first()).subscribe(info => {
      this.issue_msg = info["issue"]
      this.clear_msg = info["cleared"]
      this.schoolName = info["name"]
    })
  }

  yes(){
    this.issues.push(this.questions[this.questionNum])
    this.next()
  }

  no(){
    this.next()
  }

  next(){
    this.questionNum++;
    if(this.questionNum >= this.questions.length) this.submit()
  }

  submit(){
    if(this.issues[0]) {this.hasIssue = true}
    else {this.hasIssue = false}
    this.fireservice.logScreenerArchive(this.schoolID, this.submitUserID, this.issues).catch(error => console.log(error))
    this.fireservice.logScreenerStatus(this.schoolID, this.submitUserID, this.issues).catch(error => console.log(error))
  }

  chooseDifferentUser(){
    this.userChosen = false;
    this.disclaimer_accepted = false;
    this.questionNum = 0;
  }

  backToLogin(){
    this.authservice.logout()
    this.router.navigateByUrl('screener-login')
  }

}
