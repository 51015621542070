import { AngularfireService } from './angularfire.service';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { json2csv } from 'json-2-csv';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class DownloadCsvService {

  // TODAY DATE INFO
  today = new Date()  
  month: string
  day: string
  year: string
  date: string

  constructor(
    private fireservice: AngularfireService
  ) { 
    this.setAllDateInfo()
  }

  // CSV CHUNKY METHODS
  downloadAllUsers(schoolID: string){
    this.fireservice.getSchoolAccountsManage(schoolID).pipe(first()).subscribe(accounts => {

      if(accounts.length != 0) {
        let Obj: Object[] = []
        accounts.forEach((doc,index,array) => {

          // get document snapshot values
          var doc_id = doc.payload.doc.id
          var value = doc.payload.doc.data()

          // get firebase values
          let obj: Object = {}
          let name = value["name"]
          let emails = value["emails"]
          let grade = value["grade"]
          let type = value["type"]
          let id = value["id"]
          let umbrella = value["umbrella"]
          let power = value["power"]

          // checking to make sure user isn't null --> if it is, delete
          if( (name == null     || name == ""     || name == "n/a" ) &&
              (emails == null   || emails == ""   || emails == "n/a") &&
              (grade == null    || grade == ""    || grade == "n/a") &&
              (type == null     || type == ""     || type == "n/a")  &&
              (id == null       || id == ""       || id == "n/a")    &&
              (power == null    || power == ""    || power == "n/a") &&
              (umbrella == null || umbrella == "" || umbrella == "n/a") ){

            this.fireservice.removeUserFromAll(schoolID, doc_id)
          } 
          // if user ISNT null then add to spreadsheet 
          else {
            obj["name"] = name
            obj["user type"] = type
            obj["grade"] = grade
            obj["email"] = emails[0]
            obj["id"] = id
            obj["comments"] = value["comments"]

            Obj.push(obj)
          }          
        })
        this.downloadObjectAsCSV(Obj)
      } 
    })
  }

  downloadAttendance(schoolID: string, date: string, type:string, sort: string){
    var promise = new Promise<void>((resolve, reject) => {
      let getAttendance = this.fireservice.getAttendance(schoolID, date, type).pipe(first())
      getAttendance.subscribe(records => {
        if(records.length != 0){
          let Obj: Object[] = []
          let movement = "Enter"
          if(type == "exiting") movement = "Exit"
          records.forEach((value,index,array) => {
            if(value){
              let obj: Object = {}
              obj["name"] = value["name"]
              obj["user type"] = value["type"]
              obj["grade"] = value["grade"]
              obj['time'] = value["date"]
              obj["movement"] = movement
              Obj.push(obj)
            }
          })

          // SORT
          Obj = this.customSort(sort, Obj)

          // download CSV
          this.downloadObjectAsCSV(Obj)
          resolve()
        } else {
          reject("No attendance records for this date")
        }
      })
    });
    return promise
  }

  async downloadLast14Days(schoolID: string, date: string){
    let days = 14
    return await this.downloadLastXDaysArchive(schoolID, date, days)
  }

  downloadLast30Days(schoolID: string, date: string){
    let days = 30
    return this.downloadLastXDaysArchive(schoolID, date, days)
  }

  private async downloadLastXDaysArchive(schoolID: string, date: string, x: number){
    var promise = new Promise<void> (async (resolve, reject) => {
      let Obj: object[] = []
      var getArchive
      for(let i =0; i<x; i++){
        getArchive = this.fireservice.getArchive(schoolID, date).pipe(first())
        getArchive.subscribe(archive => {
          if(archive && archive.length != 0){
            
            archive.forEach(record => {
              let id = record.payload.doc.id
              let doc = record.payload.doc.data()

              var symptoms = ""
              doc["results"].forEach(s => {
                symptoms += s + '\n'
              });

              this.fireservice.getUserFromID(id, schoolID).pipe(first()).subscribe(user => {
                if(user && user["name"] && user["emails"][0] && user["grade"] && user["type"]){
                  Obj.push({
                    date: date,
                    name: user["name"],
                    email: user["emails"][0],
                    grade: user["grade"],
                    type: user["type"],
                    symptoms: symptoms
                  })
                } //else reject("User missing neccessary information")
              })
            })
          } //else reject("No archive data available for " + date)
        })
        await getArchive.toPromise()
        date = this.getYesterdayDateStringFromDateString(date)
      }
      this.downloadObjectAsCSV(Obj)
      resolve()
    })
    return promise
  }

  // CSV HELPERS
  downloadObjectAsCSV(Obj: Object[]) {
      let array = this.mapToArray(Obj)
      json2csv(array, (err, csv) => {
        csv = "data:text/csv;charset=utf-8," + csv
        let encodedUri = encodeURI(csv); 
        window.open(encodedUri, "_blank");  // MAKE SURE TO TURN OFF AD_BLOCKER!!
      })
  }

  mapToArray(map: Object[]){
    let array: Object[] = []
    for(let entry of map){
      array.push(entry)
    }
    return array;
  }

  customSort(type: string, Obj: Object[]): Object[]{
    if(type == "alphabetical"){                             // sort alphabetical by 'name' field
      // IF BY NAME
      Obj.sort(function(a:Object, b:Object){
        let aName = a["name"].toString()
        let bName = b["name"].toString()
        return aName.localeCompare(bName)
      })
    }
    else if(type == "grade"){                               // sort by 'grade' field
      // IF BY GRADE
      Obj.sort(function(a:Object, b:Object){
        let aGrade = a["grade"].toString()
        let bGrade = b["grade"].toString()

        // grade 10
        if(aGrade == "Grade 10") aGrade = "h"
        if(bGrade == "Grade 10") bGrade = "h"

        // grade 11
        if(aGrade == "Grade 11") aGrade = "i"
        if(bGrade == "Grade 11") bGrade = "i"

        // grade 12
        if(aGrade == "Grade 12") aGrade = "j"
        if(bGrade == "Grade 12") bGrade = "j"

        // Pre-K
        if(aGrade == "Pre-K") aGrade = "a"
        if(bGrade == "Pre-K") bGrade = "a"

        // K
        if(aGrade == "K") aGrade = "b"
        if(bGrade == "K") bGrade = "b"

        return aGrade.localeCompare(bGrade)
      })
    }
    else if(type == "time"){                              // sort chronologically by 'time' field
      // sort array by time
      Obj.sort(function (a: Object, b: Object) {
        let firstTime: string = a["time"]
        let first: number = parseInt( firstTime.split(":")[0] )
        let secondTime: string  = b["time"]
        let second: number = parseInt( secondTime.split(":")[0] )
        return first - second;
      })
    }
    else {
      console.log("UNEXPECTED SORT TYPE")
    }

    return Obj;
  }

  // SET DATE METHODS
  private setMonth(){ this.month = (this.today.getMonth() + 1).toString() }
  private setDay(){   this.day = this.today.getDate().toString() }
  private setYear(){ this.year = this.today.getFullYear().toString() }
  private setAllDateInfo(){
    this.setMonth()
    this.setDay()
    this.setYear()

    // trim zeros if needed
    let formatted = this.trimZeros(this.month, this.day)
    this.month = formatted[0]
    this.day = formatted[1]

    // set date string
    this.date = this.formatDateString(this.month, this.day, this.year)
  }

  // DATE AND TIME ACCESSOR METHODS
  getTodayDateObject(){ return this.today }
  getTodayDay(){ return this.day }
  getTodayMonth(){ return this.month }
  getTodayYear(){ return this.year }
  getTodayDateString(){ return this.date }

  getDateStringFromDateObject(date: Date){
    let month = (date.getMonth() + 1).toString()
    let day = date.getDate().toString()
    let year = date.getFullYear().toString()
    return this.formatDateString(month, day, year)
  }

  getYesterdayDateObject(date: Date){
    date.setDate(date.getDate() -1 )
    return date
  }

  getYesterdayDateString(date: Date){
    let yesterday = this.getYesterdayDateObject(date)
    let month = (yesterday.getMonth() + 1).toString()
    let day = yesterday.getDate().toString()
    let year = yesterday.getFullYear().toString()
    return this.formatDateString(month, day, year)
  }

  getYesterdayDateStringFromDateString(date_str: string){
    let date = this.getDateObjectFromDateString(date_str)
    date.setDate(date.getDate()-1)
    return this.getDateStringFromDateObject(date)
  }

  getDateObjectFromDateString(date: string){
    let today = new Date()
    let month = date.substring(0, date.indexOf("-"))
    date = date.substring( date.indexOf("-") + 1)
    let day = date.substring(0, date.indexOf("-"))
    date = date.substring( date.indexOf("-") + 1)
    let year = date
    today.setFullYear(parseInt(year))
    today.setMonth(parseInt(month)-1)
    today.setDate(parseInt(day))
    return today
  }

  private trimZeros(month: string, day: string){
    if(month[0] == '0') month = month.substring(1)
    if(day[0] == '0') day = day.substring(1)
    return [month, day]
  }

  formatDateString(month: string, day: string, year: string): string{
    let formatted = this.trimZeros(month, day)
    month = formatted[0]
    day = formatted[1]
    return month + "-" + day + "-" + year;
  }
}