<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>


  <header class="mainHeaderContent">
      <ul class="listContent">
        <h1 class="largeTitle">SWS Dashboard</h1>
        <li class="listContainer">
          <a class="navigationButton" routerLink="home">Home</a>
          <a class="navigationButton" routerLink="download">Download Data</a>
          <a class="navigationButton" routerLink="users">Manage Users</a>
          <a class="navigationButton" routerLink="info">Update Content</a>
          <a class="navigationButton" routerLink="questions">Update Questions</a>

          <!-- <a class="navigationButton" routerLink="email-uncompleted">Send a Reminder</a> -->
          <a class="navigationButton" (click)="logout()">Logout</a>


        </li>
        <p class="schoolName">{{ schoolName }}</p>

      </ul>

  </header>





        <!-- navbar -->
        <!-- <div class="navbar">
            <div class="container">
                <div class="row">
                    <div class="col-sm-2">
                        <img src="../../assets/mainLogo.png" height="55px" routerLink="home"
                        style="padding-top: 7.5px; padding-bottom: 7.5px; padding-right: 30px; border-right: 1px solid gray;">
                    </div>
                    <div class="col-sm-1">
                        <img [src]=schoolIcon height="55px">
                    </div>
                    <div class="col-sm-9">
                        <div class="">
                            <button class="btn nav-btn" routerLink="download">Download Data</button>
                            <button class="btn nav-btn" routerLink="info">Update App Info</button>
                            <button class="btn nav-btn" routerLink="questions">Update Questions</button>
                            <button class="btn nav-btn" routerLink="users">Manage Users</button>
                            <button class="btn nav-btn" routerLink="email-uncompleted" style="color: #ff6b41;">Send Reminder</button>
                            <button class="btn nav-btn" (click)="logout()"><strong>Logout</strong></button>
                        </div>
                    </div>
                </div>

                <hr>

            </div>
        </div> -->

      <div class="secondaryContainer">
        <router-outlet></router-outlet>
      </div>

        <div class="mainHeaderContent">
          <ul class="listContent">
              <p class="footerTitle">© Vision Applications, Inc.</p>

              <a class="footerButton" href="https://schoolwellnessscreener.com/privacy-policy">Privacy Policy</a>
              <!-- <a class="footerButton" routerLink="info">Terms and Conditions</a> -->
              <a class="footerButton" href="mailto:support@schoolwellnessscreener.com">Support</a>




              <!-- <p class="footerBody">

                

              </p> -->

            </ul>
            <div class="breaker"></div>
        </div>



<!-- BOOTSTRAP -->
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js"
integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js"
integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>

</body>
</html>
