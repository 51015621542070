<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">

        <button class="btn clear-btn" (click)="goBack()" routerLink="../users">

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
            </svg>
        Go Back</button>

       
        <!-- <button (click)="test()">TEST ACCOUNT BY ID</button><br> -->

        <div class="row" >
            <div class="col-lg-12">
              <h1 class="title">{{user.name}}
                <!-- <button class="editButton">  
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg>
               </button> -->

                
              </h1> 
                            <!-- <p class="secondary">{{ user.email.toLowerCase() }}</p>  -->

              <p style="display: inline;" class="secondary">{{ user.email.toLowerCase() }}</p>
              <a href="mailto:{{ user.email.toLowerCase() }}" style="color:black; font-size: 15px; margin-left: 2px" class="remove-btn">(Send Email)</a>
 
            </div>
        </div>

        <!-- <hr> -->


        <div class="row">
            <div class="col-sm-12">
                <h3 class="catTitle">Family Member Accounts
                
                
                    <button (click)="showFamilyMemberAccounts=true"class="downArrow" *ngIf="!showFamilyMemberAccounts">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
                     </button>


                     <button (click)="showFamilyMemberAccounts=false"class="downArrow" *ngIf="showFamilyMemberAccounts">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg>
                     </button>
                </h3>

<!-- *ngIf="linkedAccounts.length != 0" -->
                
                <div *ngIf="!showFamilyMemberAccounts">
                    <div *ngFor="let email of linkedAccounts; index as i">

                        <div class="row">
                            <div class="col-sm-10">
                                <div class="umbrella-user" style="color:darkslategray; font-size: 18px; display: inline;">
                                    <p style="display:inline;">{{ email }}</p>
                                </div>
                                <button class="remove-btn" (click)="confirm_delete_linked[i]=true">(Remove Email From Account)</button>

                            </div>
                        </div>

                        <!-- confirm -->
                        <div class="row confirm" *ngIf="confirm_delete_linked[i]">
                            <div class="col-sm-10" style="padding-top: 13px; padding-bottom: auto;">
                                <p>You are about to remove {{ email }} from this account. Do you wish to proceed?</p>
                            </div>
                            <div class="col-sm-2">
                                <button (click)="confirm_delete_linked[i] = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px;">Cancel</button>
                                <button (click)="deleteLinked(email)" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            <div *ngIf="!showFamilyMemberAccounts">
                <div *ngIf="linkedAccounts.length == 0;">
                    <div>No linked family member accounts.</div>
                </div>
            </div>
                
            </div>
        </div>


        <div class="row">
            <div class="col-sm-10">
                <h3 class="title">Personal Information
                
                    <button (click)="showPersonalInformation=true"class="downArrow" *ngIf="!showPersonalInformation">
                
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg>
                     </button>


                     <button (click)="showPersonalInformation=false"class="downArrow" *ngIf="showPersonalInformation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
                     </button>

                </h3>

            <input type="text" [(ngModel)]="updatedName" class="textFieldInput" placeholder={{user.name}} *ngIf="showPersonalInformation" style="width: 550px">
            </div>
            <div class="col-sm-2">
                <button class="btn btn-update" (click)="updateName()" [disabled]="!updatedName" *ngIf="showPersonalInformation"
                >Update</button>
            </div>
        </div>
        <div class="row" *ngIf="showPersonalInformation">
            <div class="col-sm-10">
                <h3>Update Grade</h3>
                <p>Currently: {{user.grade}}</p>
                <select [(ngModel)]="updatedGrade" class="textFieldInput" style="width: 550px" placeholder={{user.grade}}>
                    <option>Choose Grade</option>
                    <option>No Grade</option>
                    <option>Pre-K</option>
                    <option>K</option>
                    <option *ngFor="let i of grades;">Grade {{ i }}</option>
                </select>
                <label> Or Custom Grade: </label>
                <input type="text" [(ngModel)]="customGrade" class="textFieldInput">
            </div>
            <div class="col-sm-2">
                <button class="btn btn-update" (click)="updateGrade()" [disabled]="!updatedGrade && !customGrade"
                >Update</button>
            </div>
        </div>

        <div class="row" *ngIf="showPersonalInformation">
            <div class="col-sm-10">
                <h3>Update Comments</h3>
                <p>Currently: {{user.comments}}</p>
                <input type="text" [(ngModel)]="updatedComments" class="textFieldInput" style="width: 550px">
            </div>
            <div class="col-sm-2">
                <button class="btn btn-update" (click)="updateComments()" [disabled]="!updatedComments"
                >Update</button>
            </div>
        </div>



        <!-- LINKED ACCOUNTS -->
      
        <!-- <hr> -->



        <!-- <hr> -->


        
        <!-- PERMISSIONS -->
        <div class="row admin">
            <div class="col-sm-9">
                <h3 class="title">Admin Permissions

                    <button (click)="showPermissionsInformation=true"class="downArrow" *ngIf="!showPermissionsInformation">
                
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                   </button>


                   <button (click)="showPermissionsInformation=false"class="downArrow" *ngIf="showPermissionsInformation">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                   </button>

              </h3>

                <!-- <p>Currently: {{ adminStatus }}</p> -->

                <p *ngIf="showPermissionsInformation">
                    <strong>Super Admin</strong> <br>
                    Able to access the admin side of mobile app AND the web admin console. <br>
                    Use for administrators managing attendance, screener results, and editing user info for the whole school.
                </p>
                <p *ngIf="showPermissionsInformation"> 
                    <strong>Admin</strong> <br>
                    Able to access the admin side of mobile app, but not the web admin console. <br>
                    Use for faculty and staff who use only the admin side of the mobile app.
                </p>
                <p *ngIf="showPermissionsInformation">
                    <strong>No Permissions</strong> <br>
                    Not able to access the admin side of the mobile app OR the web admin console. <br>
                    Use for students, parents, and guests.
                </p>
            </div>
            <div class="col-sm-3 buttons" *ngIf="showPermissionsInformation">
                <div class="row">
                    <button class="btn btn-superadmin" (click)=makeSuperAdmin()>Make Super Admin</button>
                </div>
                <div class="row">
                    <button class="btn btn-admin" (click)=makeAdmin()>Make Admin</button>
                </div>
                <div class="row">
                    <button class="btn btn-delete" (click)=removePermissions()>Remove Permissions</button>
                </div>
            </div>
        </div>
        <div *ngIf="showPermissionsInformation">
        <div class="row success" *ngIf="adminAssigned || superadminAssigned || adminRemoved">
            <div class="col-sm-12" *ngIf="showPermissionsInformation">
                <p *ngIf="adminAssigned">Success! {{user.name}} has been assigned Admin privilages.</p>
                <p *ngIf="superadminAssigned">Success! {{user.name}} has been assigned Super Admin privilages.</p>
                <p *ngIf="adminRemoved">Success! {{user.name}}'s privilages have been removed.</p>
            </div>
        </div>
        </div>



                <hr>


                <div class="row new-section">
                    <div class="col-sm-10">
                       <h3 class="title">Add Additional Member to Account


                    <button (click)="addNewUser=true"class="downArrow" *ngIf="!addNewUser">
                
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                   </button>


                   <button (click)="addNewUser=false"class="downArrow" *ngIf="addNewUser">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                   </button>
                       </h3>

                       <div *ngIf="addNewUser">

                       <!-- name -->
                        <label>Name: </label> <br>
                        <input class="textFieldInput" style="width: 550px;"type="text" [(ngModel)]=newUserName> <br>

                        <!-- grade -->
                        <label>Grade: </label> <br>
                        <select class="textFieldInput" style="width: 550px;" [(ngModel)]=newUserGrade>
                            <option>No Grade</option>
                            <option>Pre-K</option>
                            <option>K</option>
                            <option *ngFor="let i of grades;">Grade {{ i }}</option>
                        </select>

                        <!-- custom grade -->
                        <label>  Or Custom Grade:  </label>
                        <input class="textFieldInput" style="width: 550px;" type="text" [(ngModel)]='newCustomGrade'> <br>

                        <!-- type -->
                        <label>Type: </label> <br>
                        <select [(ngModel)]=newUserType class="textFieldInput" style="width: 550px;">
                            <option>Student</option>
                            <option>Faculty/Staff</option>
                            <option>Parent</option>
                            <option>Other</option>
                        </select>

                    </div>

                    </div>
                    <div class="col-sm-2" *ngIf="addNewUser">
                        <button class="btn add-btn" (click)=addUser() [disabled]="!newUserName || !newUserType || (!newUserGrade && !newCustomGrade)"
                        >Add User</button>
                    </div>
                                    
            </div>

        <!-- <hr> -->



        <!-- OTHER USERS IN ACCOUNT -->
        <div class="row">
            <div class="col-sm-12">
                <h3 class="title">Users in Account
                    <button (click)="usersInAccount=true"class="downArrow" *ngIf="!usersInAccount">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
  
                   </button>


                   <button (click)="usersInAccount=false"class="downArrow" *ngIf="usersInAccount">
                     
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg>
                   </button>

                </h3>
                <div *ngFor="let user of umbrellaUsers; index as i">

                    <!-- user -->
                    <div class="row" *ngIf="!usersInAccount" >
                        <div class="col-sm-10">
                            <div class="umbrella-user" >
                                <h4>{{user.name}}</h4>
                                <p>{{user.grade}} | {{user.id}}</p>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn remove-btn" (click)="confirm_delete[i] = true" *ngIf="umbrellaUsers.length != 1"
                            >(Remove)</button>
                        </div>
                    </div>

                     <!-- confirm -->
                    <div class="row confirm" *ngIf="confirm_delete[i]">
                        <div class="col-sm-10">
                            <p>You are about to remove {{ user.name }} from this account. Do you wish to proceed?</p>
                        </div>
                        <div class="col-sm-2">
                            <button (click)="confirm_delete[i] = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px;">Cancel</button>
                            <button (click)="removeUser(user)" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="umbrellaUsers.length == 0">
                    <div>No users under this umbrella</div>
                </div>
            </div>
        </div>




        <!-- DELETE/MERGE ACCOUNT -->
        <div class="row" style="padding-left: 20px;">
            <div class="row">
                <h3 class="title" style="padding-left: 10px; ">Merge Into Another Account</h3>

                    <p style="padding-left: 10px;">Merging an account will link this email to the selected other account.</p>



                <div class="col-sm-3" style="display: inline; float: right; margin-left: -3px;">
                    <br>
                    <button (click)="fetchMerged()" class="btn delete-btn">Select Account</button>
                </div>




            </div>

            <!-- confirm -->
            <div class="row confirm" *ngIf="confirm_merge_account" style="width: 94%; margin-left: 0px; margin-right: auto;">

                <div class="row" style="text-align: left; padding-left: 25px; padding-bottom: 5px;">
                    <p>  <strong>Merge Account {{ user.email }} into Account: </strong>  </p>
                        <select [(ngModel)]="merge_account_primary" style="width: 92%; margin: 0px;">
                            <option *ngFor="let user of allAccounts"> {{ user.name }}:  {{ user.email }} </option>
                        </select>
                </div>

                <div class="col-sm-10" style="padding-top: 13px; padding-bottom: auto;">
                    <p>You are about to delete and merge the account under {{ user.email }} into the selected account {{ merge_account_primary}}. 
                        Both accounts will now have access to the same people registered in the account. This process is automatic, and will not require any action from the user.
                    </p>
                    <p>Do you wish to proceed?</p>
                    
                </div>
                <div class="col-sm-2">
                    <button (click)="confirm_merge_account = false" class="btn" style="width: 75px; margin: 2px; margin-top: 7px;">Cancel</button>
                    <br>
                    <button (click)="mergeAccounts(merge_account_primary, user.email)" [disabled]="!merge_account_primary" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 15px; background: #FFCC80;
                    color: black;">Confirm</button>
                </div>
            </div>

            <!-- error in merge -->
            <div class="row alert alert-danger" style="width: 94%; margin-left: 0px; margin-right: auto;" *ngIf="merge_error"
            >Something went wrong. Please contact ephraim@visionappsproducts.com for assistance.</div>
        </div>

        <div style="margin-top: 70px;">
            <button (click)="confirm_delete_account =true" class="btn delete-btn"><strong>Permanently Delete Account</strong></button>
            <p>Warning: Deleting an account will delete every person within the account and remove the account email from our system.</p>

            <!-- confirm -->
            <div class="row confirm" *ngIf="confirm_delete_account" style="width: 80%; float: left;">
                <div class="col-sm-10" style="padding-top: 13px; padding-bottom: auto;">
                    <p>You are about to delete this entire umbrella account, 
                       including all users registered as part of the account and all linked family member accounts. 
                       This will remove the account as a whole from our system.
                    </p>
                    <p>Do you wish to proceed?</p>
                    
                </div>
                <div class="col-sm-2">
                    <button (click)="confirm_delete_account = false" class="btn" style="width: 75px; margin: 2px; margin-top: 7px; ">Cancel</button>
                    <br>
                    <button (click)="deleteAccount()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;    background: #FFCC80;
                    color: black;
                    border: solid 2px #FFCC80;">Confirm</button>
                </div>
            </div>

        </div>
        

    </div>
</body>