import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-msg',
  templateUrl: './mobile-msg.component.html',
  styleUrls: ['./mobile-msg.component.scss']
})
export class MobileMsgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
