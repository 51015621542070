import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
// import { EThree } from '@virgilsecurity/e3kit-native';

@Component({
  selector: 'app-login-va',
  templateUrl: './login-va.component.html',
  styleUrls: ['./login-va.component.scss']
})
export class LoginVaComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  login(){ this.router.navigateByUrl("vaccine-upload/login") }

  createAccount(){ this.router.navigateByUrl("vaccine-upload/create-account") }
}
