import { AngularfireService } from './../angularfire.service';
import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { pipe, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  // login credentials


  schoolID: string;
  pin: string;

  // error logging in
  loginCredError = false;
  loginAlreadySetupError = false;
  loginSchoolIDInvalidError = false;

  constructor(
    private router: Router,
    private fireservice: AngularfireService,
    private fireauth: AngularFireAuth
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  tryLogin() {
    this.loginCredError = false;
    this.loginAlreadySetupError = false;
    this.loginSchoolIDInvalidError = false;

    this.fireservice.getInfo(this.schoolID).pipe(first()).subscribe(info => {
      // check if valid school ID
      if(info){
        // check if school already registered
        if(info["registered"] == false){
          // check if valid pin
          if(info["pin"]==this.pin){
            this.router.navigateByUrl('/setup/setup-info')
            localStorage.setItem('schoolID', this.schoolID);
          } else {
            this.loginCredError = true
          }
        } else {
          this.loginAlreadySetupError = true
        }
        
      } else {
        this.loginSchoolIDInvalidError = true
      }
    })
  }

}
