import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-screener-login',
  templateUrl: './screener-login.component.html',
  styleUrls: ['./screener-login.component.scss']
})
export class ScreenerLoginComponent implements OnInit, OnDestroy {

  // Subscriptions
  authStateSub: Subscription

  // errors
  loginError = false

  // login variables
  email: string
  password: string

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
    private router: Router
  ) { 
    authservice.setAuthTypeScreener()
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.authStateSub) this.authStateSub.unsubscribe()
  }

  manageAuthState() {
    this.authStateSub = this.authservice.authState.pipe(first()).subscribe((user) => {
      // check if a user correctly logged in
      if(user){
        // get school name for screener questions
        this.fireservice.getUserDetails(user).pipe(first()).subscribe(userDetails => {
          // if an account exists for this 
          if(userDetails){
              //console.log("user in an admin")
              this.loginError = false;
              this.authservice.setSchoolID(userDetails["school"])
              this.router.navigate(['web-screener']); 
          } //else console.log("No user details")
        })
      } //else console.log("no user")
    })
  }

  tryLogin() {

    this.loginError = false
    this.authservice.login(this.email, this.password).then(
      (fulfilled) => {
        this.manageAuthState()
      }, 
      (reason) => {
        console.log(reason)
        this.loginError = true;
        this.authservice.logout()//.then(() => console.log("error --> logged out"))
    })
  }

}
