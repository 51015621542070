<!-- XXX:

        © 2021 Vision Applications, Inc.
        All Rights Reserved.

 -->


 <!-- <html lang="en"> -->
    <head>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
            <!-- <link rel="stylesheet" href="login.component.css"> -->
    
    </head>
    
    <body>
        <div class="container">
    
            <div class="title-div form-group">
                <img src="../../assets/mainLogo.png" alt="SWS logo" class="logo">
                <h1>New School Setup</h1>
            </div>
            
    
            <form #loginForm="ngForm" (ngSubmit)="tryLogin()">
                <!-- EMAIL INPUT -->
                <div class="form-group">
                    <label class="labelType" for="loginEmail">School-ID</label>
                    <br>
                    <input required text [(ngModel)]="schoolID"
                    name="loginEmail" #loginEmail="ngModel"
                    id="loginEmail" type="text" class="textFieldInput"
                    placeholder="">
    
                    <!-- error messages -->
                    <div class="alert alert-danger" *ngIf="loginEmail.touched && !loginEmail.valid">
                        <div *ngIf="loginEmail.errors.required">School ID is required</div>
                        <div *ngIf="loginSchoolIDInvalidError">School ID is not valid</div>
                    </div>
                </div>
    
                <!-- PASSWORD INPUT -->
                <div class="form-group">
                    <label class="labelType" for="loginPassword">Registration Pin</label>
                    <br>
                    <input required password [(ngModel)]="pin"
                    name="loginPassword" #loginPassword="ngModel"
                    id="loginPassword" type="password" class="textFieldInput"
                    placeholder="">
    
                    <!-- error messages -->
                    <div class="alert alert-danger" *ngIf="loginPassword.touched && !loginPassword.valid">
                        <div *ngIf="loginPassword.errors.required">Pin is required</div>
                    </div>
                </div>
              
    
                <div class="alert alert-danger" *ngIf="loginCredError">  Registration pin is incorrect. </div>
                <div class="alert alert-danger" *ngIf="loginSchoolIDInvalidError"> That School-ID does not exist.</div>
                <div class="alert alert-danger" *ngIf="loginAlreadySetupError"> 
                    This School-ID already has been setup. Please use the <a routerLink="../login">Admin Web Console</a> for further updates.
                </div>
    
                <br>
                <div class="form-group">
                    <button type="submit" class="btn btn-submit" [disabled]="!loginForm.form.valid">Begin Setup</button>
                </div>
                
                <div class="reset">Looking for the main admin console? Click <a routerLink="../login">here</a> to log in.</div>
    
    
            </form>
        </div>
    
    <!-- BOOTSTRAP -->
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>
    
    </body>
    <!-- </html> -->
    