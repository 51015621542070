import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-card-va',
  templateUrl: './upload-card-va.component.html',
  styleUrls: ['./upload-card-va.component.scss']
})
export class UploadCardVaComponent implements OnInit {

  constructor(
    private router: Router
  ) {}
  ngOnInit(): void {
  }

  goBack(){ 
    // ADD IN LOGOUT FUNCTIONALITY HERE
    console.log("ADD IN LOGOUT FUNCTIONALITY HERE")
    this.router.navigateByUrl("/vaccine-upload/login")
  }

  submit(){
    // ADD IN ACTUAL SUBMISSION HERE
    this.router.navigateByUrl("/vaccine-upload/success")
  }

}
