import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-va',
  templateUrl: './success-va.component.html',
  styleUrls: ['./success-va.component.scss']
})
export class SuccessVaComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  addAnother(){
      // ADD IN LOGOUT FUNCTIONALITY HERE
      
      this.router.navigateByUrl("/vaccine-upload")
  }

  closeWindow(){ }

}
