import { Student } from './../objects/Student';
import { DownloadCsvService } from './../download-csv.service';
import { first, max } from 'rxjs/operators';
import { AuthService } from './../auth.service';
import { Router } from '@angular/router';
import { AngularfireService } from './../angularfire.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Subscription, Observable } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  // HOME DATA VARIABLES
  DAY = 1;
  DAYS_IN_MONTH = 30
  DAYS_IN_WEEK = 7;

  schoolPIN: string

  // today = new Date()
  month: string = this.downloadservice.getTodayMonth()
  day: string = this.downloadservice.getTodayDay()
  year: string = this.downloadservice.getTodayYear()
  date: string = this.downloadservice.getTodayDateString()

  additionalInformation: boolean = false
  showMessage: boolean = false


  usersSub: Subscription
  schoolSub: Subscription
  attendanceSub: Subscription
  archiveSub: Subscription[] = []

  totalUsers: number = 0;
  completedUsers: number = 0;
  scannedUsers: number = 52;

  noIssueUsersDay: number = 0;
  issueUsersDay: number = 0;
  issueUsersWeek: number = 0;
  issueUsersMonth: number = 0;

  reportedIssuesWeek: object = {};
  mostCommonIssueWeek: string = "Loading..."

  reportedIssuesMonth: object = {};
  mostCommonIssueMonth:string = "Loading..."

  symptomsTwoWeeksLoading = false;
  symptomsMonthLoading = false;


  // PI CHART --> COMPLETED
    public pieChartOptions: ChartOptions = {
      responsive: false,
      legend: {
        position: 'bottom',
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      },
      title: {
        text: 'Completed Users',
        display: true,
        fontSize: 16,
      },
      
    };
    public pieChartLabels: Label[] = [['Completed'], 'Uncompleted'];
    public pieChartData: number[] = [1, 1];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartColors = [
      {
        backgroundColor: ['#1e685e', '#FFCC80'],
      },
    ];

  // PI CHART --> SCANNED IN
    public pieChartOptions2: ChartOptions = {
      responsive: false,
      legend: {
        position: 'bottom',
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      },
      title: {
        text: 'Total Scanned-In Users',
        display: true,
        fontSize: 16,
      },
      
    };
    public pieChartLabels2: Label[] = [['Scanned In'], 'Not Scanned In'];
    public pieChartData2: number[] = [1, 1];
    public pieChartType2: ChartType = 'pie';
    public pieChartLegend2 = true;
    public pieChartColors2 = [
      {
        backgroundColor: ['#1e685e', '#FFCC80'],
      },
    ];


  // EMAIL REMINDER VARIABLES
  // school details
  grades: number[] = [1,2,3,4,5,6,7,8,9,10,11,12]

  schoolName: string
  schoolEmail: string

  // date 
  today = new Date()

  // user records
  uncompleted: Object[] = []
  uncompleted_emails: string[][] = []
  uncompleted_selected: boolean[] = []

  reminder_message: string = ""
  original_reminder: string = ""
  savedMessage = false;

  subject = ""
  message = ""

  // subscriptions
  accountsSub: Subscription
  authsub: Subscription
  infoSub: Subscription

  // user group to show
  userGroup = "all"           // 'all', 'name', or 'grade' depending on which version to show
  allUsers: Student[] = []
  nameUsers: Student[] = []

  manualSelect: boolean = false
  loading: boolean = false

  // search variable models
  searchName: string
  nameNotFound: boolean = true;

  // email
  email_loading: boolean = false;
  email_error: boolean = false
  email_sent: boolean = false
  email_enabled: boolean = true
  confirm_email: boolean = false
  no_emails: boolean = false

  confirm_email_selected: boolean = false
  email_loading_selected: boolean = false
  email_error_selected: boolean = false
  email_sent_selected: boolean = false
  no_emails_selected: boolean = false

  show_email: boolean = false
  

  constructor(
    private fireservice: AngularfireService, 
    private router: Router,
    private authservice: AuthService,
    private downloadservice: DownloadCsvService
  ) { }

  ngOnInit(): void {
    this.schoolSub = this.authservice.schoolID.subscribe(async school => {
      if(school){
        this.schoolPIN = school

        // get all users
        var getUsers = this.fireservice.getSchoolUsers(this.schoolPIN).pipe(first())
        this.usersSub = getUsers.subscribe(users => {
          this.totalUsers = users.length
        })
        //await getUsers.toPromise();


        // get daily attendance
        var getAttendance = this.fireservice.getAttendance(this.schoolPIN, this.date, "entering").pipe(first())
        this.attendanceSub = getAttendance.subscribe(attendance => {
          this.scannedUsers = attendance.length
          this.pieChartData2 = [this.scannedUsers, this.totalUsers]
        })
        //await getAttendance.toPromise();
        

        // get screener questions to prep map
        // let getQuestions = this.fireservice.getQuestionsNoNumAccess(this.schoolPIN).pipe(first())
        // getQuestions.subscribe(questions => {
        //   for(let q of questions){
        //     this.reportedIssuesWeek[q["title"]] = 0;
        //     this.reportedIssuesMonth[q["title"]] = 0;
        //   }
        // })
        // await getQuestions.toPromise()


        // get daily screener results for last 30 days
        // var date = this.downloadservice.getTodayDateObject()                          // date counter
        // for(let i =0; i<this.DAYS_IN_MONTH; i++){
        //   var date_str = this.downloadservice.getDateStringFromDateObject(date)       // string of date counter
        //   var getResults = this.fireservice.getArchiveNoIDAccess(this.schoolPIN, date_str).pipe(first())
        //   getResults.subscribe(results => {
        //     // for each person completed on the i'th day
        //     for(let j=0; j<results.length; j++){

        //       if(i == 0){
        //         this.completedUsers = results.length                             // tally completed users if on first day
        //         this.pieChartData = [this.completedUsers, this.totalUsers-this.completedUsers]
        //       }

        //       // if person reports an issue
        //       if(results[j]["results"].length != 0){
        //         if(i == 0) this.issueUsersDay++;                                 // add to daily tally if on first day
        //         for(let k = 0; k < results[j]["results"].length; k++){
        //           if(i < this.DAYS_IN_WEEK) {
        //             if(k==0) this.issueUsersWeek++;                              // add to weekly tally if in first week
        //             this.reportedIssuesWeek[results[j]["results"][k]]++;         // increment counter for this symptom
        //           }       
        //           if( i < this.DAYS_IN_MONTH){
        //             if(k==0) this.issueUsersMonth++;                             // add to monthly tally if in month
        //             this.reportedIssuesMonth[results[j]["results"][k]]++;        // increment counter for this symptom
        //           } 
        //         }
        //       } else if(i == 0) { this.noIssueUsersDay++ }                       // add to daily noIssue users if no issues on first day
        //     }

        //     // set completed/uncompleted graph if not already set
        //     this.pieChartData = [this.completedUsers, this.totalUsers-this.completedUsers]
        //     this.pieChartData2 = [this.scannedUsers, this.noIssueUsersDay]

        //     // increament date string back by 1
        //     date = this.downloadservice.getYesterdayDateObject(date)
        //   }) 
        //   //await getResults.toPromise()
        // }

        // get daily screener from TODAY ONLY
        var date = this.downloadservice.getTodayDateObject()                          // date counter
          var date_str = this.downloadservice.getDateStringFromDateObject(date)       // string of date counter
          var getResults = this.fireservice.getArchiveNoIDAccess(this.schoolPIN, date_str).pipe(first())
          getResults.subscribe(results => {
            // for each person completed on the i'th day
            for(let j=0; j<results.length; j++){
              this.completedUsers = results.length                             // tally completed users if on first day

              // if person reports an issue
              if(results[j]["results"].length != 0){
                this.issueUsersDay++;                                 // add to daily tally if on first day
              } else { this.noIssueUsersDay++ }                       // add to daily noIssue users if no issues on first day
            }

            // set completed/uncompleted graph if not already set
            this.pieChartData = [this.completedUsers, this.totalUsers-this.completedUsers]
            this.pieChartData2 = [this.scannedUsers, this.totalUsers]
          }) 


        // // get most frequent symtoms
        // let keys = Object.keys(this.reportedIssuesWeek)
        // var count = 0;
        // for(let i = 0; i< keys.length; i++){
        //   if(this.reportedIssuesWeek[keys[i]] > count){
        //     count = this.reportedIssuesWeek[keys[i]];
        //     this.mostCommonIssueWeek = keys[i]
        //   }
        // }

        // keys = Object.keys(this.reportedIssuesMonth)
        // count = 0;
        // for(let i = 0; i< keys.length; i++){
        //   if(this.reportedIssuesMonth[keys[i]] > count){
        //     count = this.reportedIssuesMonth[keys[i]];
        //     this.mostCommonIssueMonth = keys[i]
        //   }
        // }

        // setup reminder email functionality
        this.infoSub = this.fireservice.getInfo(this.schoolPIN).subscribe(info => {
          this.schoolName = info["name"] || "Your School"
          this.schoolEmail = info["adminEmail"] || "your school"
          //this.message = this.message1 + this.schoolEmail + this.message2
          this.subject = this.schoolName + " - Complete Wellness Screener";
          this.original_reminder = info["reminder"]
          this.reminder_message = info["reminder"] || this.basicMessage();
        })
      }
    })
  }

  // data methods
  ngOnDestroy(){
    // data
    if(this.schoolSub) this.schoolSub.unsubscribe()
    if(this.usersSub) this.usersSub.unsubscribe()
    if(this.attendanceSub) this.attendanceSub.unsubscribe()
    // if(this.archiveSub) this.archiveSub.unsubscribe()

    // email
    if(this.accountsSub) this.accountsSub.unsubscribe()
    if(this.authsub) this.authsub.unsubscribe()
    if(this.infoSub) this.infoSub.unsubscribe()
  }

  downloadLastWeek(){
    this.symptomsTwoWeeksLoading = true
    this.downloadservice.downloadLast14Days(this.schoolPIN, this.downloadservice.getTodayDateString()) 
      .then( () => { this.symptomsTwoWeeksLoading = false }) 
      .catch(error => console.log(error)) 
  }

  downloadLastMonth(){
    this.symptomsMonthLoading = true
    this.downloadservice.downloadLast30Days(this.schoolPIN, this.downloadservice.getTodayDateString())
      .then( () => this.symptomsMonthLoading = false) 
      .catch(error => console.log(error)) 
  }

  // email methods
  saveMessage(){
    if(this.original_reminder != this.reminder_message){ 
      this.saveEmail()
    } //else console.log("NOT changed")
    this.savedMessage = true;
    this.clearAfterXSeconds(2)
    this.show_email = false;
  }

  found(name: string){
    if (name.toLowerCase().indexOf(this.searchName.toLowerCase()) != -1 || this.searchName.toLowerCase().indexOf(name.toLowerCase()) != -1 ){ return true; }
    return false
  }

  clearSearch(){
    this.userGroup = "all"
    this.searchName = ""
    this.nameNotFound = true
  }

  // email ALL USERS -- no manual selection
  async emailUncompleted(){
    this.confirm_email = false
    this.email_loading = true
    this.email_sent = false
    this.email_error = false

    // LOAD ALL
    await this.loadUncompleted()

    // SEND EMAIL
    if(this.uncompleted_emails.length != 0){
      this.formatMessage()
      let emails = this.collectEmails()
      this.fireservice.sendUncompletedEmailArray(emails, this.subject, this.message).catch(error => {console.log(error); this.email_error = true}).then(() => this.saveEmail())
    }  else { 
      this.no_emails = true
      this.email_loading = false
      this.clearAfterXSeconds(5)
      return 
    }

    this.email_enabled = false
    this.email_loading = false
    this.email_sent = true
    this.clearAfterXSeconds(5)
    this.disableEmailForXSeconds(30)
    
  }

  collectEmails(){
    var emails: string[] = []
    for(let i = 0; i<this.uncompleted_emails.length; i++){
      let user_emails: string[] = this.uncompleted_emails[i]
      for(let j = 0; j<user_emails.length; j++){
        emails.push(this.uncompleted_emails[i][j])
      }
    }
    return emails;
  }

  emailSelected(){
    this.confirm_email_selected = false
    this.email_loading_selected = true
    this.email_sent_selected = false
    this.email_error_selected = false
    this.no_emails_selected = false

    // collect emails selected
    var emails: string[] = []
    for(let i = 0; i<this.uncompleted_emails.length; i++){
      if(this.uncompleted_selected[i]){ 
        for(let j = 0; j<this.uncompleted_emails[i].length; j++){
          emails.push(this.uncompleted_emails[i][j])
        }
      }
    }

    // SEND EMAIL
    if(emails.length != 0){
      this.formatMessage()
      this.fireservice.sendUncompletedEmailArray(emails, this.subject, this.message).catch(error => {console.log(error); this.email_error = true}).then(() => this.saveEmail())
    } else { 
      this.no_emails_selected = true
      this.email_loading = false
      this.clearAfterXSeconds(5)
      return 
    }

    this.email_enabled = false
    this.email_loading_selected = false
    this.email_sent_selected = true
    this.clearAfterXSeconds(5)
    this.disableEmailForXSeconds(30)
  }

  async loadUncompleted(){

    // check that hasn't loaded before (catch case where show/hide/shows users multiple times)
    if(this.uncompleted.length == 0){

      console.log("loading uncompleted")
      this.loading = true
      let date = this.formatDateString((this.today.getMonth() + 1).toString(), this.today.getDate().toString(), this.today.getFullYear().toString())

      // observables
      let archiveObs: Observable<any>
      let accountObs: Observable<any>

      // get all accounts
      accountObs = this.fireservice.getSchoolAccountsManage(this.schoolPIN).pipe(first())
      accountObs.subscribe(async accounts => {
        this.uncompleted_emails = []
        this.uncompleted = []
        this.uncompleted_selected = []

        // check for uncompleted
        archiveObs = this.fireservice.getArchive(this.schoolPIN, date).pipe(first())
        archiveObs.subscribe(async records => {

          // if there is at least one screener entry
          if(records.length != 0){

            // loop through all accounts
            accounts.forEach((doc, index, array) => {

              if(doc){

                // get document snapshot values
                var doc_id = doc.payload.doc.id
                var value = doc.payload.doc.data()

                // get firebase values
                let name = value["name"]
                let emails: string[] = value["emails"]
                let id = value["id"]

                // for each account check through screener records
                var found = false;
                for(let record of records){
                  if(record.payload.doc.id == id){
                    found = true;
                    break;
                  }
                }

                // if user is not found in the records, they have not filled it out
                if(!found){

                  // add to uncompleted array
                  this.uncompleted.push({
                    name: name,
                    // email: emails[0],
                    emails: emails
                  }) 

                  // load both parent and student emails
                  // for(let i = 0; i<emails.length; i++){
                    // add both emails to just emails array
                    this.uncompleted_emails.push(emails)

                    // add to selected array
                    this.uncompleted_selected.push(true)
                  // }
                  
                }
              } 

              //ERROR
              else {
                this.email_error = true
                return
              }

            })
          }

          // if no one has filled out the screener, EVERYONE is uncompleted
          else { 
            accounts.forEach(doc => {

              if(doc){
                // get document snapshot values
                var doc_id = doc.payload.doc.id
                var record = doc.payload.doc.data()

                // if valid user --> add to array
                if(record.name && record.emails[0]){
                  // add to uncompleted array
                  // this.uncompleted.push({
                  //   name: record["name"],
                  //   email: record["emails"][0]
                  // })

                  // // add to just emails array
                  // this.uncompleted_emails.push(record["emails"][0])
                  
                  // // add to selected array
                  // this.uncompleted_selected.push(true)

                  // add to uncompleted array
                  this.uncompleted.push({
                    name: record.name,
                    // email: emails[0],
                    emails: record.emails
                  }) 

                  // load both parent and student emails
                  // for(let i = 0; i<record.emails.length; i++){
                    // add both emails to just emails array
                    this.uncompleted_emails.push(record.emails)

                    // add to selected array
                    this.uncompleted_selected.push(true)
                  // }

                }
              } 
              
              //ERROR
              else {
                this.email_error = true
                return
              }
              
            })
          }

        })
        await archiveObs.toPromise()
      })
      await accountObs.toPromise()
      await archiveObs.toPromise()
      this.loading = false
      
    }
  }

  basicMessage(){
    return "Hello,\n\n" + "This is a reminder to complete the SWS daily wellness screener." + 
           "If you are having technical difficulties, please contact " + this.schoolEmail + "." +
           "\n\nThank you,\nSchool Wellness Screener (SWS)"
  }

  formatMessage(){
    this.message = this.reminder_message 
    this.message = this.message.replace(/\n/g, "<code><br /></code>")
  }

  formatDateString(month: string, day: string, year: string): string{
    return month + "-" + day + "-" + year;
  }

  disableEmailForXSeconds(x:number){
    var timeout = setTimeout( () => {
      this.email_enabled = true
    }, (x*1000));
  }

  saveEmail(){
    this.fireservice.saveEmailText(this.schoolPIN, this.reminder_message).catch(error => console.log(error));
  }

  clearAfterXSeconds(x:number){
    var timeout = setTimeout( () => {
      this.clearErrors()
    }, (x*1000));
  }

  clearErrors(){
    this.email_sent = false
    this.email_error = false
    this.no_emails = false
    this.email_sent_selected = false
    this.email_error_selected = false
    this.email_sent_selected = false
    this.savedMessage = false
  }
  
}
