import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from "src/environments/environment";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
//import * as serviceAccount from 'sws-web-app/school-health-screener-firebase-adminsdk-ol0s9-977bacb8d9.json';
//import * as admin from 'firebase-admin'
import { ChartsModule } from 'ng2-charts';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { DownloadComponent } from './download/download.component';
import { InfoComponent } from './info/info.component';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { ManageAdminsComponent } from './manage-admins/manage-admins.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { QuestionsComponent } from './questions/questions.component';
import { SetupComponent } from './setup/setup.component';
import { QsComponent } from './setup/qs/qs.component';
import { RegisterComponent } from './register/register.component';
import { InitialInfoComponent } from './setup/initial-info/initial-info.component';
import { DoneComponent } from './setup/done/done.component';
import { WebScreenerComponent } from './web-screener/web-screener.component';
import { ScreenerLoginComponent } from './screener-login/screener-login.component';
import { MobileMsgComponent } from './mobile-msg/mobile-msg.component';
import { EmailUncompletedComponent } from './email-uncompleted/email-uncompleted.component';
import { HomeComponent } from './home/home.component';
import { CreateAccountComponent } from './setup/create-account/create-account.component';
import { LoginVaComponent } from './va/login-va/login-va.component';
import { UploadCardVaComponent } from './va/upload-card-va/upload-card-va.component';
import { HeadComponent } from './va/head/head.component';
import { CreateAccountVaComponent } from './va/create-account-va/create-account-va.component';
import { PremadeVaComponent } from './va/premade-va/premade-va.component';
import { SuccessVaComponent } from './va/success-va/success-va.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    DownloadComponent,
    InfoComponent,
    UsersComponent,
    UserDetailComponent,
    ManageAdminsComponent,
    ResetPassComponent,
    QuestionsComponent,
    SetupComponent,
    QsComponent,
    RegisterComponent,
    InitialInfoComponent,
    DoneComponent,
    WebScreenerComponent,
    ScreenerLoginComponent,
    MobileMsgComponent,
    EmailUncompletedComponent,
    HomeComponent,
    CreateAccountComponent,
    LoginVaComponent,
    UploadCardVaComponent,
    HeadComponent,
    CreateAccountVaComponent,
    PremadeVaComponent,
    SuccessVaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ReactiveFormsModule,
    FormsModule,
    AngularFireStorageModule,
    ChartsModule
  ],
  providers: [
    { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  
}
