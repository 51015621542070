<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">

</head>
<body>

    <div class="contentContainer">


        <div class="search-section">
            <div class="row">
                <div class="col-sm-10">
                    <label>Search by Name</label><br>
                    <input type="text" [(ngModel)]="searchName" class="textFieldInput">
                </div>
                <div class="col-sm-2">
                    <button class="btn search-btn" [disabled]="!searchName" (click)="searchUsersByName()">Search

                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <label>Search by Email</label>
                    <input type="text" [(ngModel)]="searchEmail" class="textFieldInput">
                </div>
                <div class="col-sm-2">
                    <button class="btn search-btn" [disabled]="!searchEmail" (click)="searchUsersByEmail()">Search</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <label>Search by Grade</label>
                    <select class= "textFieldInput" [(ngModel)]="searchGrade">
                        <option>Choose Grade</option>
                        <option>No Grade</option>
                        <option>Pre-K</option>
                        <option>K</option>
                        <option *ngFor="let i of grades;">Grade {{ i }}</option>
                    </select>
                </div>
                <div class="col-sm-2">
                    <button class="btn search-btn" [disabled]="!searchGrade" (click)="searchUsersByGrade()">Search</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <label>Or by Custom Grade</label>
                    <input type="text" [(ngModel)]="searchCustomGrade" class="textFieldInput">
                </div>
                <div class="col-sm-2">
                    <button class="btn search-btn" [disabled]="!searchCustomGrade" (click)="searchUsersByCustomGrade()">Search</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <button class="btn clear-btn" (click)="clearSearch()">Clear Search</button>
                </div>
            </div>
        </div>

      <div class="users-section">
          <!-- all users -->
          <div *ngIf="userGroup == 'all'" class="user-list">
              <div *ngFor="let user of allUsers; index as i;">
                  <div class="row">
                      <div class="col-sm-9">
                          <h3>{{user.name}}</h3>
                          <p>{{user.email}} | {{user.id}}</p>
                          <p>{{user.grade}} </p>
                      </div>
                      <!-- <div class="col-sm-2">
                          <button class="btn email-btn" (click)="emailUser(allUsers[i])"
                          >Email User</button>
                      </div> -->
                      <div class="col-sm-3">
                          <button class="btn update-btn" (click)="updateUser(allUsers[i])" routerLink="../user-detail"
                          >Edit User</button>
                      </div>
                  </div>
                  <br>
              </div>
              <!-- if no matching users -->
              <div *ngIf="allUsers.length == 0">
                  <p>No registered users.</p>
              </div>
          </div>

          <!-- name users -->
          <div *ngIf="userGroup == 'name'" class="user-list">
              <div *ngFor="let user of nameUsers; index as i;">
                  <div class="row">
                      <div class="col-sm-9">
                          <h3>{{user.name}}</h3>
                          <p>{{user.email}} | {{user.id}}</p>
                          <p>{{user.grade}} </p>
                      </div>
                      <!-- <div class="col-sm-2">
                          <button class="btn email-btn" (click)="emailUser(nameUsers[i])"
                          >Email User</button>
                      </div> -->
                      <div class="col-sm-3">
                          <button class="btn update-btn" (click)="updateUser(nameUsers[i])" routerLink="../user-detail"
                          >Update User</button>
                      </div>
                  </div>
                  <br>
              </div>
              <!-- if no matching users -->
              <div *ngIf="nameUsers.length == 0">
                  <p>No users matching name '{{ nameNotFound }}'.</p>
              </div>
          </div>

          <!-- grade users -->
          <div *ngIf="userGroup == 'grade'" class="user-list">
              <div *ngFor="let user of gradeUsers; index as i;">
                  <div class="row">
                      <div class="col-sm-9">
                          <h3>{{user.name}}</h3>
                          <p>{{user.email}} | {{user.id}}</p>
                          <p>{{user.grade}} </p>
                      </div>
                      <!-- <div class="col-sm-2">
                          <button class="btn email-btn" (click)="emailUser(gradeUsers[i])"
                          >Email User</button>
                      </div> -->
                      <div class="col-sm-3">
                          <button class="btn update-btn" (click)="updateUser(gradeUsers[i])" routerLink="../user-detail"
                          >Update User</button>
                      </div>
                  </div>
                  <br>
              </div>
              <!-- if no matching users -->
              <div *ngIf="gradeUsers.length == 0">
                  <p>No users matching grade '{{ gradeNotFound }}'.</p>
              </div>
          </div>

          <!-- email users -->
          <div *ngIf="userGroup == 'email'" class="user-list">
              <div *ngFor="let user of emailUsers; index as i;">
                  <div class="row">
                      <div class="col-sm-9">
                          <h3>{{user.name}}</h3>
                          <p>{{user.email}} | {{user.id}}</p>
                          <p>{{user.grade}} </p>
                      </div>
                      <!-- <div class="col-sm-2">
                          <button class="btn email-btn" (click)="emailUser(gradeUsers[i])"
                          >Email User</button>
                      </div> -->
                      <div class="col-sm-3">
                          <button class="btn update-btn" (click)="updateUser(emailUsers[i])" routerLink="../user-detail"
                          >Update User</button>
                      </div>
                  </div>
                  <br>
              </div>
              <!-- if no matching users -->
              <div *ngIf="emailUsers.length == 0">
                  <p>No users matching email '{{ emailNotFound }}'.</p>
              </div>
          </div>
      </div>
    </div>
</body>
