<!-- XXX:

        © 2020 Vision Applications, Inc.
        All Rights Reserved.

 -->


 <html lang="en">
    <head>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
            <!-- <link rel="stylesheet" href="login.component.css"> -->
    
    </head>
    
    <body>
        <div class="container">
          <h1 class="title">Reset Password</h1>
    
            <form #loginForm="ngForm" (ngSubmit)="resetPassword()" *ngIf="!submitted">
                <!-- EMAIL INPUT -->
                <div class="form-group">
                    <label class="labelType" for="resetEmail">Email Address</label>
                    <br>
                    <input required email [(ngModel)]="email"
                    name="resetEmail" #resetEmail="ngModel"
                    id="resetEmail" type="email" class="textFieldInput"
                    placeholder="">
    
                    <!-- error messages -->
                    <div class="alert alert-danger" *ngIf="resetEmail.touched && !resetEmail.valid">
                        <div *ngIf="resetEmail.errors.required">Email address is required</div>
                        <div *ngIf="resetEmail.errors.email">Email address is not valid</div>
                    </div>
                </div>

                <br>
                <button type="submit" class="btn btn-submit" [disabled]="!loginForm.form.valid">Send Password Reset Email</button>
    
            </form>
            <div class="submitted" *ngIf="submitted">
                <p>Success! Check email {{email}} for your password reset link.</p>
            </div>
            <div class="alert alert-danger" *ngIf="error">
                <p>{{errormsg}}</p>
            </div>
            <!-- <button class="btn back-btn" (click)="backToLogin()">Back to Login</button> -->
        </div>
    
    <!-- BOOTSTRAP -->
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>
    
    </body>
    </html>
    