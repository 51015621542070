import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from './../auth.service';
import { AngularfireService } from './../angularfire.service';
import { Student } from './../objects/Student';
import { UserDetailsService } from './../user-details.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  umbrellaSub: Subscription
  userSub: Subscription
  schoolSub: Subscription
  adminSub: Subscription
  linkedSub: Subscription

  showFamilyMemberAccounts: boolean = false
  showGradeEdit: boolean = false
  showPermissionsInformation: boolean = false
  usersInAccount: boolean = false
  addNewUser: boolean = false

  showPersonalInformation: boolean = false





  // for merge
  allUsersSub: Subscription

  user: Student
  school: string;
  grades: number[] = [1,2,3,4,5,6,7,8,9,10,11,12]
  adminStatus: string

  // for updating user info
  updatedName: string
  updatedGrade: string;
  updatedComments: string;
  customGrade: string;
  umbrellaUsers: object[] = []

  // linked accounts
  linkedAccounts: string[] = []  // array of emails

  // for adding a new user to the umbrella
  newUserName: string
  newUserGrade: string
  newCustomGrade: string;
  newUserType:string

  // errors
  deleteFromAllError = false
  deleteUmbrellaError = false

  // alerts
  adminAssigned = false
  superadminAssigned = false
  adminRemoved = false

  // delete
  confirm_delete: boolean[] = []
  confirm_delete_linked: boolean[] = []
  confirm_delete_account: boolean = false
  merge_error = false;

  // merge
  confirm_merge_account: boolean = false
  allAccounts: Object[] = []
  merge_account_primary: string;
  // merge_primary_selction: string;
  

  constructor(
    private userservice: UserDetailsService,
    private fireservice: AngularfireService,
    private authservice: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userSub = this.userservice.user.subscribe(user => {
      // if there is a user already stored
      if(user || JSON.parse(localStorage.getItem('user-details')) ) {

        if(user) {
          this.user = new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments)
        }
        else {
          user = JSON.parse(localStorage.getItem('user-details'))
          this.user = new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments)
        }
        this.schoolSub = this.userservice.school.subscribe(school => {
          if(school){
            this.school = school.toString()
            this.getUmbrella()  // get info on other users in umbrella
            this.getLinked()    // get info on linked family member accounts

            // this.adminSub = this.fireservice.getUserFromID(user.id, this.school).subscribe(user => {
            //   console.log(user)
            //   let power = user["power"]
            //   if(power == "regular") this.adminStatus = "No Permissions"
            //   else if(power == "admin") this.adminStatus = "Admin"
            //   else if(power == "superadmin") this.adminStatus = "Super Admin"
            //   else this.adminStatus = "Error Permissions"
            //   console.log(this.adminStatus)
            // })
          }
        })

      }
    })
    
  }

  ngOnDestroy() {
    if(this.umbrellaSub){
      this.umbrellaSub.unsubscribe()
    }
    if(this.userSub){
      this.userSub.unsubscribe()
    }
    if(this.schoolSub){
      this.schoolSub.unsubscribe()
    }
    if(this.adminSub){
      this.adminSub.unsubscribe()
    }
    if(this.allUsersSub){
      this.allUsersSub.unsubscribe()
    }
  }

  getUmbrella(){
    this.umbrellaSub = this.fireservice.getUmbrellaUsers(this.school, this.user.umbrella).subscribe(umbrellaUsers => {
      this.umbrellaUsers = []
      this.confirm_delete = []
      umbrellaUsers.forEach((user, index, allUsers) => {
        this.umbrellaUsers.push({
          name: user["name"], 
          grade: user["grade"],
          id: user["id"],
          umbrella: user["umbrella"]
        })
        this.confirm_delete.push(false)
      })
    })
  }

  getLinked(){
    this.linkedSub = this.fireservice.getLinkedAccounts(this.user.umbrella).subscribe(accounts => {
      this.linkedAccounts = []
      this.confirm_delete_linked = []
      if(accounts){
        for(let account of accounts){
          if(account["email"] != this.user.email) { this.linkedAccounts.push(account["email"]); this.confirm_delete_linked.push(false)}
        }
      } 
      else {
        console.log("ERROR: no accounts listed with that umbrella")
      }
      
    })
  }

  updateName(){
    // update in users-> all
    this.fireservice.updateUserAll(this.school, this.user.id, {name: this.updatedName})
      .catch(error => console.log(error))
    // update in umbrella
    this.fireservice.updateUserUmbrella(this.school, this.user.umbrella, this.user.id, {name: this.updatedName})

    let updated = new Student(this.updatedName, this.user.email,this.user.grade,this.user.power, this.user.type, this.user.id, this.user.umbrella, this.user.comments)
    // update user in current page
    this.user = updated
    this.userservice.setUser(updated)
    // clear update textbox
    this.updatedName = ""
  }

  updateGrade(){
    // update in users->all 
    let newGrade = this.updatedGrade || this.customGrade
    this.fireservice.updateUserAll(this.school, this.user.id, {grade: newGrade})
      .catch(error => console.log(error))
    // update in umbrella
    this.fireservice.updateUserUmbrella(this.school, this.user.umbrella, this.user.id, {grade: newGrade})
    // update user in current page and in user-detail service
    let updated = new Student(this.user.name, this.user.email,newGrade,this.user.power, this.user.type, this.user.id, this.user.umbrella, this.user.comments)
    this.user = updated
    this.userservice.setUser(updated)

    // clear update textbox    
    this.updatedGrade = ""; this.customGrade = ""
  }

  updateComments(){
    if(this.updatedComments != this.user.comments){
      
      this.fireservice.addComments(this.school, this.user.id, this.updatedComments).catch(error => console.log(error))
      let updated = new Student(this.user.name, this.user.email,this.user.grade,this.user.power, this.user.type, this.user.id, this.user.umbrella, this.updatedComments)
      this.user = updated
      this.userservice.setUser(updated)
      this.updatedComments = ""
    }
    
  }

  // updateEmail(){
  //   // update in users->all
  //   this.fireservice.updateUserAll(this.school, this.user.id, {emails: [this.updatedEmail]})
  //     .catch(error => console.log(error))
  //   // update user in current page
  //   this.user = new Student(this.user.name, this.updatedEmail,this.user.grade,this.user.power, this.user.type, this.user.id, this.user.umbrella)
  //   // clear update textbox
  //   this.updatedEmail = ""
  // }

  addUser(){    // change email to current and disable field
    let newUser = new Student(this.newUserName, this.user.email, this.newUserGrade || this.newCustomGrade, "regular", this.newUserType, this.fireservice.generateID(), this.user.umbrella, "No current comments.")
    this.fireservice.addToUmbrella(this.school, newUser)
    this.newUserName = ""
    this.newUserGrade = ""
    this.newUserType = ""
  }

  removeUser(user:Student){
    this.fireservice.removeFromUmbrella(this.school, user.umbrella, user.id)
  }

  makeAdmin(){
    this.clearAlerts()
    this.fireservice.makeAdminUsersAll(this.school, this.user.id).catch(error => console.log(error)).then(() => {
      this.adminAssigned = true
    })
    this.fireservice.makeAdminGlobal(this.user.email, this.school).catch(error => console.log(error)).then(() => {
      this.adminAssigned = true
      this.clearAfter3Seconds()
    })
  }

  makeSuperAdmin(){
    this.clearAlerts()
    this.fireservice.makeSuperAdminUsersAll(this.school, this.user.id).catch(error => console.log(error)).then(() => {
      this.superadminAssigned = true
    })
    this.fireservice.makeSuperAdminGlobal(this.user.email, this.school).catch(error => console.log(error)).then(() => {
      this.superadminAssigned = true
      this.clearAfter3Seconds()
    })
  }

  private removeAdmin(){
    this.fireservice.removeAdminUsersAll(this.school, this.user.id).catch(error => console.log(error)).then(() => {
      this.adminRemoved = true
    })
    this.fireservice.removeAdminGlobal(this.user.email, this.school).catch(error => console.log(error)).then(() => {
      this.adminRemoved = true
      this.clearAfter3Seconds()
    })
  }

  private removeSuperAdmin(){
    this.fireservice.removeSuperAdminUsersAll(this.school, this.user.id).catch(error => console.log(error)).then(() => {
      this.adminRemoved = true
    })
    this.fireservice.removeSuperAdminGlobal(this.user.email, this.school).catch(error => console.log(error)).then(() => {
      this.adminRemoved = true
      this.clearAfter3Seconds()
    })
  }

  removePermissions(){
    this.clearAlerts()
    this.removeAdmin()
    this.removeSuperAdmin()
  }

  // test(){
  //   console.log("test called")
  //   //this.fireservice.removeAccountByID(this.user.id, this.school)
  //   this.fireservice.getAccountsFromID(this.user.id).pipe(first()).subscribe(data => {
  //     console.log(data)
  //   })
  // }

  deleteAccount(){
    // delete umbrella
    this.fireservice.getUmbrellaUsers(this.school, this.user.umbrella).pipe(first()).subscribe(umbrellaUsers => {

      // go through each user in umbrella
      for(let user of umbrellaUsers){
        let umbrella:string = user["umbrella"]
        let id:string = user["id"]

        // remove each user from umbrella
        this.fireservice.removeUserFromUmbrella(this.school, umbrella, id).catch(error => {
          console.log(error)
          this.deleteUmbrellaError = true
        })

        // delete each umbrella user from all
        this.fireservice.removeUserFromAll(this.school, id).catch(error => {
          console.log(error)
          this.deleteFromAllError = true
        })

        // error checking
        if(this.deleteUmbrellaError || this.deleteFromAllError){
          break
        }
      }

      // DELETE FROM ACCOUNTS
      this.fireservice.removeAccountByID(this.user.id, this.school)

      // UNAUTHENTICATE ALL LINKED ACCOUNTS
      if(this.linkedAccounts.length != 0){
        for(let i=0; i<this.linkedAccounts.length; i++){
          this.fireservice.unauthenticateUser(this.linkedAccounts[i], this.school)
            //.then(()=> console.log(this.linkedAccounts[i] + "removed successfully"))
            .catch(error => console.log(error))
        }
      } // else console.log("no linked accounts")
      

    })

    // unauthenticate --> write email to toDelete
    this.fireservice.unauthenticateUser(this.user.email, this.school)//.then(() => console.log("successfully written to unauthenticate"))
      .catch(error => console.log(error))

    // router back to use page
    this.router.navigateByUrl('admin/users')
  }

  deleteLinked(email){
      this.fireservice.unauthenticateUser(email, this.school).catch(error => console.log(error))
      this.fireservice.removeLinkedFromAccounts(this.user.umbrella, email)
  }

  async fetchMerged(){
    this.confirm_merge_account = true;

    // get all other accounts to populate merge select
    let allUsers = this.fireservice.getSchoolAccountsManage(this.school)
    this.userSub = allUsers.subscribe(users => {
      if(users){
        this.allAccounts = []
        users.forEach(doc => {
          let user = doc.payload.doc.data()
          let name = user["name"]

          user["emails"].forEach(email => {
            if(email != this.user.email){
              this.allAccounts.push({
                email: email,
                name: name
              })
            }
          })
        })

        // sort and get initial variables
        this.allAccounts.sort((a,b) => {
          let aname:string = a["name"]
          let bname:string = b["name"]
          let comp = aname.localeCompare(bname)
          return comp;
        }) 
        this.merge_account_primary = this.allAccounts[0]["name"] + ":  " + this.allAccounts[0]["email"]
      }
    })    
    
  }

  async mergeAccounts(primary: string, to_merge: string /* probably need to change these input types*/){

    primary = primary.substring(primary.indexOf(":")+1).trim()
    
    // primary: A
    // to_merge: B

    let oldUmbrella: string;
    let oldUID: string;
    let oldEmail = to_merge;
    let oldAccount = "regular"
    let oldName = 'n/a'
    let oldSchool = this.school
    
    // 1. get new umbrella from primary email account doc
    let new_umbrella;
    let getNewEmailAccounts = this.fireservice.getAccountFromEmail(primary).pipe(first())
    getNewEmailAccounts.subscribe(accounts => {
      if(accounts){
        let account = accounts[0] // get first returned document (should only be one bc only one doc per email)
        new_umbrella = account["umbrella"];
      } else {
        this.merge_error = true
        return
      }
    })
    await getNewEmailAccounts.toPromise()

    // 2. get account doc for to_merge
    //    get all user IDs (from people field)
    var oldIDs: string[] = []
    let getOldEmailAccounts = this.fireservice.getAccountFromEmail(to_merge).pipe(first())
    getOldEmailAccounts.subscribe(accounts => {
      if(accounts){
        oldIDs = []
        let account = accounts[0] // get first returned document (should only be one bc only one doc per email)
        // get IDS
        Object.keys(account["people"]).forEach(ID => {
          oldIDs.push(ID)
        });

        // get other info for update
        oldUmbrella = account["umbrella"]
        oldUID = account["UID"]
      } else {
        this.merge_error = true
        return
      }
    })
    await getOldEmailAccounts.toPromise()

    // 3. update account doc for to_merge
    //    change umbrella to new umbrella
    //    delete people field
    //    change name to 'n/a'
    this.fireservice.updateAccountSET(oldUID, 
      {
        UID: oldUID,
        account: oldAccount,
        email: oldEmail,
        name: oldName,
        school: oldSchool,
        umbrella: new_umbrella
      }
    ).catch(error => { console.log(error); this.merge_error = true; return })

    // 4. delete all people IDs from users/all
    // 5. delete old umbrella from users/umbrellas
    oldIDs.forEach(ID => {
      this.fireservice.removeUserFromAll(this.school, ID).catch(error => { console.log(error); this.merge_error = true; return })
      this.fireservice.removeUserFromUmbrella(this.school, oldUmbrella, ID).catch(error => { console.log(error); this.merge_error = true; return })
    })

    // update all linked accounts to correct new umbrella
    this.fireservice.getAccountsFromUmbrella(oldUmbrella).pipe(first()).subscribe(accounts => {
      if(accounts){
        accounts.forEach(doc => {
          let account = doc.payload.doc.data()
          let UID = account["UID"]
          this.fireservice.updateAccount(UID, { umbrella: new_umbrella })
        })
      } else {
        this.merge_error = true
        return;
      }
    })

    if(!this.merge_error){
      // close confirm button
      this.confirm_merge_account = false;

      // navigate back
      this.goBack()
      this.router.navigateByUrl('admin/users')
    }
  }

  goBack(){
    this.userservice.setUser(null)
    // router link is in html template
  }

  clearAlerts(){
    this.adminAssigned = false
    this.superadminAssigned = false
    this.adminRemoved = false
  }

  clearAfter3Seconds(){
    var timeout = setTimeout( () => {
      this.clearAlerts()
    }, 3000);
  }

  capitalize(string: string){
    return string[0].toUpperCase() + string.slice(1);
  }

}
