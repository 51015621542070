<!-- XXX:

        © 2020 Vision Applications, Inc.
        All Rights Reserved.

 -->


<html lang="en">
<head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
        <!-- <link rel="stylesheet" href="login.component.css"> -->

</head>

<body>
    <div class="container">

        <div class="form-group title-div">
            <img src="../../assets/mainLogo.png" alt="SWS logo" class="logo">
        </div>
        

        <form #loginForm="ngForm" (ngSubmit)="tryLogin()">
            <!-- EMAIL INPUT -->
            <div class="form-group">
                <label class="labelType" for="loginEmail">Email Address</label>
                <br>
                <input required email [(ngModel)]="email"
                name="loginEmail" #loginEmail="ngModel"
                id="loginEmail" type="email" class="textFieldInput"
                placeholder="">

                <!-- error messages -->
                <div class="alert alert-danger" *ngIf="loginEmail.touched && !loginEmail.valid">
                    <div *ngIf="loginEmail.errors.required">Email address entry is required</div>
                    <div *ngIf="loginEmail.errors.email">Email address is not valid</div>
                </div>
            </div>

            <!-- PASSWORD INPUT -->
            <div class="form-group">
                <label class="labelType" for="loginPassword">Password</label>
                <br>
                <input required password [(ngModel)]="password"
                name="loginPassword" #loginPassword="ngModel"
                id="loginPassword" type="password" class="textFieldInput"
                placeholder="">

                <!-- error messages -->
                <div class="alert alert-danger" *ngIf="loginPassword.touched && !loginPassword.valid">
                    <div *ngIf="loginPassword.errors.required">A password is required</div>
                </div>
            </div>

            <!-- ERRORS -->
            <div class="form-group">
                <div class="alert alert-danger" *ngIf="loginCredError">  Login credentials are incorrect.</div>
                <div class="alert alert-danger" *ngIf="loginAdminError"> This user does not have permission to view the web console.</div>
                <div class="alert alert-danger" *ngIf="loginSuperAdminError">This user does not have Super Admin permissions and cannot view the web console.</div>
            </div>
            

            <br>
            <div class="form-group">
                <button type="submit" class="btn btn-submit" [disabled]="!loginForm.form.valid">Login to Admin Console</button>
            </div>
            
            <div class="reset">Forgot your password? Click <a routerLink="../reset-pass">here</a> to send a password reset email.</div>


        </form>
    </div>

<!-- BOOTSTRAP -->
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.6/umd/popper.min.js" integrity="sha384-wHAiFfRlMFy6i5SRaxvfOCifBUQy1xHdJ/yoi7FRNXMRBu5WHdZYu1hA6ZOblgut" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" integrity="sha384-B0UglyR+jN6CkvvICOB2joaf5I4l3gm9GU6Hc1og6Ls7i6U/mkkaduKaBhlAXv9k" crossorigin="anonymous"></script>

</body>
</html>
