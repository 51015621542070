import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularfireService } from './../../angularfire.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-qs',
  templateUrl: './qs.component.html',
  styleUrls: ['./qs.component.scss']
})
export class QsComponent implements OnInit, OnDestroy {

  schoolID: string
  questionsArray: string[] = []
  updatedArray: string[] = []
  newQ: string
  cdcReccomended: string[] = []

  questionsSub: Subscription
  authSub: Subscription

  constructor(
    private fireservice: AngularfireService,
    private router: Router,
    private fireauth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.schoolID = localStorage.getItem('schoolID')
    // back to register page if no schoolID or auth
    if(!this.schoolID /* || !auth */ ){
      this.router.navigateByUrl('register')
    }
    this.loadQuestions()
  }

  ngOnDestroy() {
    if(this.questionsSub) this.questionsSub.unsubscribe()
    if(this.authSub) this.authSub.unsubscribe()
  }

  next(){
    this.router.navigateByUrl('setup/setup-account')
  }

  back(){
    this.router.navigateByUrl('setup/setup-info')
  }

  loadQuestions(){
    this.questionsSub = this.fireservice.getQuestions(this.schoolID).subscribe(questions => {
      this.questionsArray = []
      // go through each number question
      for(let i = 1; i <= questions.length; i++){

        // find corresponding document
        for(let j = 0; j < questions.length; j++){
          // get document snapshot values
          var doc_id = questions[j].payload.doc.id
          var question = questions[j].payload.doc.data()

          if( i.toString() == doc_id ){
            this.questionsArray.push(question.title)
            this.updatedArray.push(null)
          }
        }
      }
    })
  }

  updateQuestion(i: number, newQ: string){
    this.updatedArray[i-1] = null
    this.fireservice.updateQuestion(this.schoolID, i, newQ).catch(error => console.log(error))
  }

  newQuestion(newQ: string){
    this.newQ = ""
    let i = this.questionsArray.length + 1
    this.fireservice.updateQuestion(this.schoolID, i, newQ).catch(error => console.log(error))
  }

  addCDCQuestions() {

    const arrayOfCDCReccomended = 

    ["Do you have a fever with a temperature equal to or more than 100.4°F without taking fever reducing medication)?",
    "Are you experiencing shortness of breath or difficulty breathing?", 
    "Do you have a cough?",
    "Do you have the chills?",
    "Have you lost your sense of smell or taste?",
    "Do you have a headache/migrane?",
    "Do you have a sore throat?",
    "Do you have muscle aches?",
    "Have you experienced any GI symptoms such as nausea, vomiting, diarrhea or loss of appetite?",
    "Do you have a bacterial infection sucååh as pink eye or strep throat for which you have not been on an antibiotic for 24 hours?",
    "Have you been asked to self-isolate or instructed to quarantine by a medical professional or local health department official?",
    "Have you been in close contact with anyone who has tested positive for COVID-19 or been placed on quarantine for possible contact with COVID-19 in the last 14 days?"]

    arrayOfCDCReccomended.forEach(element => this.fireservice.updateQuestion(this.schoolID, arrayOfCDCReccomended.indexOf(element) + 1, element).catch(error => console.log(error)))
  }

  deleteQuestion(Qnum: number){

    if(confirm("You are about to delete this question: do you wish to proceed?")){
        if(this.questionsArray.length == 0 || Qnum == this.questionsArray.length){
          // just delete the question
          this.fireservice.deleteQuestion(this.schoolID, Qnum).catch(error => console.log(error))
        }
        else{
          // for each following number question
          for(let i = Qnum+1; i <= this.questionsArray.length; i++){
            let title = this.questionsArray[i-1]
            // make question with correct number title
            this.fireservice.updateQuestion(this.schoolID, i-1, title)
            // for last element -- delete
            if(i == this.questionsArray.length)
            this.fireservice.deleteQuestion(this.schoolID, i).catch(error => console.log(error))
          }
        }
    }
  }


}
