import { first } from 'rxjs/operators';
import { AngularfireService } from './angularfire.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from  "@angular/router";
import { AngularFireAuth } from  "@angular/fire/auth";
import { User, auth, firestore } from  'firebase';
import { Subscription, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //loggedIn: boolean = false
  authState: Observable<User>
  authType: string;

  // --- SCHOOL ID  --- //
  private schoolIDSource: BehaviorSubject<string> = new BehaviorSubject('[uninitialized preset]');
  public schoolID = this.schoolIDSource.asObservable();


  constructor(
    private fireauth: AngularFireAuth,
    private fireservice: AngularfireService,
    private router: Router
  ) { 
    this.authState = this.fireauth.authState;
    this.manageAuthState()
    this.authType = "admin"
  }

  // publish schoolID update
  public setSchoolID(id: string) {
    this.schoolIDSource.next(id);
  }

  public setAuthTypeAdmin(){
    this.authType = "admin"
  }

  public setAuthTypeScreener(){
    this.authType = "screener"
  }

  // manage subscription to auth state and update user when changed
  manageAuthState() {
    this.fireauth.authState.subscribe(user => {

      if (user){
        localStorage.setItem('user', JSON.stringify(user));
        this.fireservice.getAccountFromUID(user.uid).pipe(first()).subscribe(account => {
          if(account){
            this.setSchoolID(account["school"])
            // this.schoolID.pipe(first()).subscribe(id => console.log(id))
          } else {
            this.logout().then(() => {
              if(this.authType == "admin") {this.router.navigate(['login']); console.log("redirect to login")}
              else if(this.authType == "screener") {this.router.navigate(['screener-login']); console.log("redirect to SCREENER login")}
              else console.log("LOGIN ERROR: UNEXPECTED AUTH TYPE")
            })
          }
        })
      } else {
        localStorage.removeItem('user');
      }
    })
  }

  // log in the user
  async login(email: string, password: string) {
    await this.fireauth.setPersistence(auth.Auth.Persistence.SESSION)
    return this.fireauth.signInWithEmailAndPassword(email, password)//.then(cred => {
      //this.user = cred.user
      //console.log(this.user)
    //})
  }

  // send a password reset email
  sendPasswordResetEmail(email:string ){
    return this.fireauth.sendPasswordResetEmail(email)
  }

  // log out the user
  logout(){
    localStorage.removeItem('user');
    return this.fireauth.signOut();
  }

  // check if the user is currently logged in
  isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('user')) !==  null; // --> for not making log in each time
    // return this.loggedIn
  }

  isLoggedInDetails() {
    let user = JSON.parse(localStorage.getItem('user'))
    return this.fireservice.getUserDetails(user)
  }
  
  getUserUID(): string {
    return JSON.parse(localStorage.getItem('user')).uid
  }

}
