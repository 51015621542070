<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous"> -->
</head>
<body>
    <div class="secondaryContainer">
        
        <!-- <div class="progress-bar">
            <img src="../../../assets/step2of3.png" alt="step 2 of 3 progress bar" class="progress-bar-img">
        </div>

        <hr> -->

        <h2 style="text-align: left;" class="title">Application Screener Questions</h2>

        <p class="desc"> Please enter below the questions you would like to have on the screener. If you select the C.D.C. default questions option, you may still edit the questions
            provided. When completed, scroll to the button and press 'Next'  
        </p>
        
            <button class="btn cdc-btn"
            (click)=addCDCQuestions()>Upload C.D.C. reccomended questions
        
        </button>

        <!-- view and/or edit current questions -->
        <div class="row update" *ngFor="let question of questionsArray; index as i;">
            <div class="col-sm-10">
                <h3>Question {{i+1}}</h3>
                <p>{{question}}</p>
                <label>Update Question:</label><br>
                <textarea class="textFieldInput" [(ngModel)]="updatedArray[i]"></textarea>                                   
            </div>
            <!-- <div class="col-sm-2"> -->
                <!-- <div class="col-sm-1"> -->
                    <!-- <button class="btn delete-btn" (click)=deleteQuestion(i+1)>Delete</button> -->
                <!-- </div> --> 
                <button class="btn remove-btn" (click)="deleteQuestion(i+1)">Remove</button>
                <button class="btn add-btn" (click)="updateQuestion(i+1, updatedArray[i])" [disabled]=!updatedArray[i]>Update</button>
            <!-- </div> -->
        </div>

        <br><hr>

        <!-- add new question -->
        <div class="row update">
            <div class="col-sm-10">
                <h3>Enter New Question</h3>
                <br>
                <textarea class="textFieldInput" [(ngModel)]="newQ"></textarea>

                <button class="btn addNew-btn" (click)="newQuestion(newQ)" [disabled]=!newQ
>Add</button>
            </div>
            
                
        </div>

        <!-- next button -->
        <div class="row">
                <button class="btn cdc-btn" style="margin-top: 80px; margin-bottom: 0px;"
                (click)="back()">
            Back</button>
                <button class="btn update-btn" style=" margin-top: 80px; margin-bottom: 0px;" (click)=next() [disabled]="(!questionsArray)">Next</button>
        </div>

    </div>
</body>
</html>