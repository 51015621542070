import { AuthService } from './auth.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService implements CanActivate {

  private isMobileResolution: boolean;

  constructor(private router: Router) {
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  public getIsMobile(): boolean {
    return this.isMobileResolution;
  }

  // the Router call canActivate() method,
  // if canActivate is registered in Routes[]
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // here we check if user is logged in or not
    // the authService returs user object, or
    // it returns undefined/null when user is not logged in
    
    return new Promise((resolve, reject) => {

        // here we check if is mobile phone
        if (this.getIsMobile()) {
          // just return false - if IS mobile --> CANNOT use website
          this.router.navigate(['mobile'])
          return resolve(false);
        } else {
          // just return true - if user is logged in
          return resolve(true);
        }

    });
  }

}
