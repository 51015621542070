import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  // credentials to reset
  email: string;
 
  // submitted
  submitted = false;
  error = false;
  errormsg: string

  constructor(
    private router: Router,
    private fireauth: AngularFireAuth
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }


  resetPassword() {
    this.error = false
    this.fireauth.sendPasswordResetEmail(this.email).then(() => {
      this.submitted = true;
    }).catch((error) => {
      console.log(error)
      this.error = true
      this.errormsg = error
    });
  }

  backToLogin(){
    this.router.navigate(["login"])
  }

}

