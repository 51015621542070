<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="content">
        <!-- <div class="content"> -->
            <img src="../../assets/mainLogo.png" width="80%" alt="sws-logo">
            <br> <br>
            <p>This website is not accessible on a mobile device. Please try again on a desktop computer or use the mobile app.</p>
        <!-- </div> -->
    </div>
</body>