import { Subscription } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  schoolID: string
  questionsArray: string[] = []
  updatedArray: string[] = []
  newQ: string

  confirm_delete: boolean[] = []

  // subscriptions
  schoolSub: Subscription
  questionsSub: Subscription

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService
  ) { }

  ngOnInit(): void {
    this.schoolSub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID
      this.loadQuestions()
    })
  }

  loadQuestions(){
    this.questionsSub = this.fireservice.getQuestions(this.schoolID).subscribe(questions => {
      this.questionsArray = []
      this.confirm_delete = []
      // go through each number question
      for(let i = 1; i <= questions.length; i++){

        // find corresponding document
        for(let j = 0; j < questions.length; j++){
          // get document snapshot values
          var doc_id = questions[j].payload.doc.id
          var question = questions[j].payload.doc.data()

          if( i.toString() == doc_id ){
            this.questionsArray.push(question.title)
            this.updatedArray.push(null)
            this.confirm_delete.push(false)
          }
        }
      }
    })
  }

  updateQuestion(i: number, newQ: string){
    this.updatedArray[i-1] = null
    this.fireservice.updateQuestion(this.schoolID, i, newQ).catch(error => console.log(error))
  }

  newQuestion(newQ: string){
    this.newQ = ""
    let i = this.questionsArray.length + 1
    this.fireservice.updateQuestion(this.schoolID, i, newQ).catch(error => console.log(error))
  }

  deleteQuestion(Qnum: number){
    if(this.questionsArray.length == 0 || Qnum == this.questionsArray.length){
      // just delete the question
      this.fireservice.deleteQuestion(this.schoolID, Qnum).catch(error => console.log(error))
    }
    else{
      // for each following number question
      for(let i = Qnum+1; i <= this.questionsArray.length; i++){
        let title = this.questionsArray[i-1]
        // make question with correct number title
        this.fireservice.updateQuestion(this.schoolID, i-1, title)
        // for last element -- delete
        if(i == this.questionsArray.length)
        this.fireservice.deleteQuestion(this.schoolID, i).catch(error => console.log(error))
      }
    }
  }



}
