import { AngularFireStorage } from '@angular/fire/storage';
import { first, finalize } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  // admin details
  schoolID: string;

  // subscriptions
  currentSub: Subscription
  authsub: Subscription
  taskSub: Subscription
  urlSub: Subscription

  // variables to be able to change from site
  adminEmail: string;
  paragraph: string;
  clearedText: string;
  issueText: string;
  name: string;
  website: string;
 
  // current state of variables to update
  curr_adminEmail: string;
  curr_paragraph: string;
  curr_clearedText: string;
  curr_issueText: string;
  curr_website: string;
  curr_logo: string;
  curr_updated: string;

  // notifications
  notificationTime: string
  notificationsEnabled: boolean = true
  hours: number[] = []
  mins: number[] = []
  updateHours: string
  updateMins: string

  // Observables
  downloadURL: Observable<string>;

  logo_type_error = false
  

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
    private storage: AngularFireStorage
  ) { }
  

  ngOnInit(): void {
    this.authsub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID
      this.getCurrentInfo()
    })

    this.generateRanges()
    
  }

  ngOnDestroy(): void {
    if(this.currentSub) this.currentSub.unsubscribe()
  }

  generateRanges(){
    // populate hours
    let arrHour = []
    for(let i = 1; i<=12; i++){
      arrHour.push(i)
    }
    this.hours = arrHour

    //populate mins
    let arrMin = []
    for(let i = 1; i<=59; i++){
      arrMin.push(i)
    }
    this.mins = arrMin
  }
  getCurrentInfo(){
    this.currentSub = this.fireservice.getInfo(this.schoolID).subscribe(doc => {
      this.curr_adminEmail = doc["adminEmail"]
      this.curr_paragraph = doc["paragraph"]
      this.curr_clearedText = doc["cleared"]
      this.curr_issueText = doc["issue"]
      this.curr_updated = doc["updated"]
      this.curr_website = doc["website"]
      this.curr_logo = doc["image"]

      this.notificationsEnabled = doc["notifications"] || false 
      this.notificationTime = doc["time"] || ""

      document.getElementById("logo").setAttribute("src", this.curr_logo)
    })
  }

  disableNotifications(){
    this.notificationsEnabled = false
    this.fireservice.updateInfo(this.schoolID, {notifications: false} ).catch(error => console.log(error))
  }

  enableNotifications(){
    this.notificationsEnabled = true
    this.fireservice.updateInfo(this.schoolID, {notifications: true} ).catch(error => console.log(error))
  }

  updateNotifications(){
    this.fireservice.updateInfo(this.schoolID, {time: this.notificationTime} ).catch(error => console.log(error))
  }

  uploadFile(event) {
    this.logo_type_error = false
    const file = event.target.files[0];
    if(file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png"){
      const filePath = this.schoolID+"_logo";
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      // // observe percentage changes
      // this.uploadPercent = task.percentageChanges();

      // get notified when the download URL is available
      this.taskSub = task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL()
            this.urlSub = this.downloadURL.subscribe(url => {
              if(url){  this.updateInfo("image", url)  }
            })
          })
      )
      .subscribe()
    } else {
      this.logo_type_error = true
    }
  }

  updateInfo(type: string, updated: string){
    let updatedStr = this.getFormattedDate()

    //The point for the updated text is to show people when the paragraph has changed. 
    if(type == "paragraph") this.fireservice.updateInfo(this.schoolID, {paragraph: updated, updated: updatedStr})
      .then(() => { this.paragraph = '' })
      .catch(error => { console.log(error) })
    else if(type == "clearedText") this.fireservice.updateInfo(this.schoolID, {cleared: updated})
      .then(() => { this.clearedText = '' })
      .catch(error => { console.log(error) })
    else if(type == "issueText") this.fireservice.updateInfo(this.schoolID, {issue: updated})
      .then(() => { this.issueText = '' })
      .catch(error => { console.log(error) })
    else if(type == "website") this.fireservice.updateInfo(this.schoolID, {website: updated})
      .then(() => { this.website = '' })
      .catch(error => { console.log(error) })
    else if(type == "name") this.fireservice.updateInfo(this.schoolID, {name: updated})
      .then(() => { this.name = '' })
      .catch(error => { console.log(error) })
    else if(type == "image") this.fireservice.updateInfo(this.schoolID, {image: updated})
      .then(() => { this.name = '' })
      .catch(error => { console.log(error) })
    else if(type == "adminEmail") this.fireservice.updateInfo(this.schoolID, {adminEmail: updated})
      .then(() => { this.adminEmail = '' })
      .catch(error => { console.log(error) })
    else throw("unexpected type")
  }

  getFormattedDate(): string {
    let updatedStr = "Updated "
    let today = new Date()
    let month = (today.getMonth()+1).toString()
    if(month[0] == '0') month = month.substring(1)
    let day = (today.getDate()).toString()
    if(day[0] == '0') day = day.substring(1)
    let year = today.getFullYear().toString()
    updatedStr = updatedStr + month + "-" + day + "-" + year
    return updatedStr;
  }

}
