import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserDetailsService } from './../user-details.service';
import { Student } from './../objects/Student';
import { Subscription } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  // school details
  schoolID: string
  grades: number[] = [1,2,3,4,5,6,7,8,9,10,11,12]
  //day: string = this.downloadservice.getTodayDay()


  // subscriptions
  accountsSub: Subscription
  authsub: Subscription

  // user group to show
  userGroup = "all"           // 'all', 'name', or 'grade' depending on which version to show
  allUsers: Student[] = []
  nameUsers: Student[] = []
  gradeUsers: Student[] = []
  emailUsers: Student[] = []

  // search variable models
  searchName: string
  searchGrade: string
  searchEmail: string
  searchCustomGrade: string
  nameNotFound: string;
  gradeNotFound: string;
  emailNotFound: string;

  // update variable models
  updateName: string;
  updateEmail: string;
  updateGrade: string;

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
    private userservice: UserDetailsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authsub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID
      this.populateSchoolUsers()
    })
    
  }

  ngOnDestroy(): void {
    if(this.accountsSub) this.accountsSub.unsubscribe()
    if(this.authsub) this.authsub.unsubscribe()
  }

  populateSchoolUsers(){
    this.accountsSub = this.fireservice.getSchoolAccountsManage(this.schoolID).subscribe(docs => {
      this.allUsers = []
      docs.forEach((value, index, alldocs) => {
        // document info
        var doc_id = value.payload.doc.id
        var doc = value.payload.doc.data()

        // getting user info from doc
        let name = doc["name"]
        if(doc["emails"]){
          var email = doc["emails"][0]
        }
        let grade = doc["grade"]
        let power = doc["power"]
        let type = doc["type"]
        let id = doc["id"]
        let umbrella = doc["umbrella"]
        let comments = doc["comments"]

        // checking to make sure user isn't null
        if((name == null     || name == ""     || name == "n/a" ) &&
           (email == null    || email == ""    || email == "n/a") &&
           (grade == null    || grade == ""    || grade == "n/a") &&
           (type == null     || type == ""     || type == "n/a")  &&
           (id == null       || id == ""       || id == "n/a")    &&
           (umbrella == null || umbrella == "" || umbrella == "n/a") ){
          this.fireservice.removeUserFromAll(this.schoolID, doc_id)
        } else {
          this.allUsers.push(new Student(name, email, grade, power, type, id, umbrella, comments))
        }
      })
    })
  }

  searchUsersByName(): void {
    this.searchGrade = ""
    this.searchEmail = ""
    this.searchCustomGrade = ""
    this.nameUsers = []
    for(let user of this.allUsers){
      if(user.name.toLowerCase().indexOf(this.searchName.toLowerCase()) != -1 || 
         this.searchName.toLowerCase().indexOf(user.name.toLowerCase()) != -1 ){
        this.nameUsers.push(new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments))
      }
    }
    if(this.nameUsers.length == 0){
      this.nameNotFound = this.searchName
    }
    this.userGroup = "name"
  }

  searchUsersByGrade(): void {
    this.gradeUsers = []
    this.searchName = ""
    this.searchEmail = ""
    this.searchCustomGrade = ""
    for(let user of this.allUsers){
      if(user.grade === this.searchGrade ){
        this.gradeUsers.push(new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments))
      }
    }
    if(this.gradeUsers.length == 0){
      this.gradeNotFound = this.searchGrade
    }
    this.userGroup = "grade"
  }

  searchUsersByEmail(): void {
    this.emailUsers = []
    this.searchName = ""
    this.searchGrade = ""
    this.searchCustomGrade = ""
    for(let user of this.allUsers){
      if(user.email.toLowerCase().indexOf(this.searchEmail.toLowerCase()) != -1 ||
      this.searchEmail.toLowerCase().indexOf(user.email.toLowerCase()) != -1){
        this.emailUsers.push(new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments))
      }
    }
    if(this.emailUsers.length == 0){
      this.emailNotFound = this.searchEmail
    }
    this.userGroup = "email"
  }

  searchUsersByCustomGrade(): void {
    this.gradeUsers = []
    this.searchName = ""
    this.searchGrade = ""
    for(let user of this.allUsers){
      if(user.grade.toLowerCase().indexOf(this.searchCustomGrade.toLowerCase()) != -1 ||
         this.searchCustomGrade.toLowerCase().indexOf(user.grade.toLowerCase()) != -1){
        this.gradeUsers.push(new Student(user.name, user.email, user.grade, user.power, user.type, user.id, user.umbrella, user.comments))
      }
    }
    if(this.gradeUsers.length == 0){
      this.gradeNotFound = this.searchCustomGrade
    }
    this.userGroup = "grade"
  }

  clearSearch(): void {
    this.userGroup = "all"
    this.searchName = ""
    this.searchGrade = ""
    this.searchCustomGrade = ""
  }

  updateUser(user: Student){
    this.userservice.setUser(user)
  }

  emailUser(user: Student){
    let url = "mailto:" + user.email
    window.open(url, "_blank")
  }

  
  // ---- method versions without fetching ALL users ----- //

  searchUsersByNameNOALL(): void {
    this.nameUsers = []
    this.searchGrade = ""
    this.fireservice.getNameSearchUsers(this.schoolID, this.searchName).pipe(first()).subscribe(names => {
      names.forEach((name, index, names) => {
        let student = new Student(name.name, name.email, name.grade, name.isAdmin, name.type, name.id, name.umbrella, name.comments)
        this.nameUsers.push(student)
      })
      if(this.nameUsers.length == 0){ this.nameNotFound = this.searchName }
    })

    this.userGroup = "name"
  }

  searchUsersByGradeNOALL(): void {
    this.gradeUsers = []
    this.searchName = ""
    this.fireservice.getGradeSearchUsers(this.schoolID, this.searchGrade).pipe(first()).subscribe(grades => {
      grades.forEach((grade, index, grades) => {
        let student = new Student(grade.name, grade.email, grade.grade, grade.isAdmin, grade.type, grade.id, grade.umbrella, grade.comments)
        this.gradeUsers.push(student)
      })
      if(this.gradeUsers.length == 0){ this.gradeNotFound = this.searchGrade }
    })
    this.userGroup = "grade"
  }

}
