import { Router } from '@angular/router';
import { AngularfireService } from './../../angularfire.service';
import { finalize, first } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-initial-info',
  templateUrl: './initial-info.component.html',
  styleUrls: ['./initial-info.component.scss']
})
export class InitialInfoComponent implements OnInit {

  // admin details
  schoolID: string;

  // hide/show nav
  show: string

  // subscriptions
  currentSub: Subscription
  authsub: Subscription
  taskSub: Subscription
  urlSub: Subscription


  // variables to be able to change from site
  adminEmail: string;
  paragraph: string;
  clearedText: string;
  issueText: string;
  website: string;
  name: string;
  
  // current state of variables to update
  curr_adminEmail: string;
  curr_paragraph: string;
  curr_clearedText: string;
  curr_issueText: string;
  curr_website: string;
  curr_updated: string;
  curr_name: string;
  curr_logo: string;

  // upload observables
  downloadURL: Observable<string>;

  constructor(
    private fireservice: AngularfireService,
    private router: Router,
    private fireauth: AngularFireAuth,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    // setup variable initial states
    localStorage.setItem('show', 'name')

    this.schoolID = localStorage.getItem('schoolID')
    this.show = localStorage.getItem('show') || "name"
    this.clearedText = "You have been cleared to enter the building. Have a good day!"
    this.issueText = "You have selected 'Yes' to one or more symptoms. Please wait for further instructions from the school's health administration."
    // this.paragraph = `Hello everyone. We currently require all students and faculty to account for their wellness daily, we will let you know when there are any more updates. Stay safe out there!`

    // back to register page if no schoolID // or auth
    if(!this.schoolID /* || !auth */){
      this.router.navigateByUrl('register')
    }

    // load from firebase
    this.getCurrentInfo()
  }

  ngOnDestroy(): void {
    if(this.currentSub) this.currentSub.unsubscribe()
    if(this.authsub) this.authsub.unsubscribe()
    if(this.urlSub) this.urlSub.unsubscribe()
    if(this.taskSub) this.taskSub.unsubscribe()
  }

  next(curr_show: string){

    if(curr_show == "name"){
      this.show = "logo"
     // localStorage.setItem('show', 'logo')
      if(this.name || this.name == this.curr_name) this.updateInfo("name", this.name)
    }
    if(curr_show == "logo"){
      this.show = "website"
     // localStorage.setItem('show', 'website')
    }
    if(curr_show == "website") {
      this.show = "email"
     // localStorage.setItem('show', 'email')
      if(this.website || this.website == this.curr_website) this.updateInfo("website", this.website) 
    }
    if(curr_show == "email") {
      this.show = "main"
     // localStorage.setItem('show', 'main')
      if(this.adminEmail || this.adminEmail == this.curr_adminEmail) this.updateInfo("adminEmail", this.adminEmail)
    }
    if(curr_show == "main") {
      this.show = "cleared"
    //  localStorage.setItem('show', 'cleared')
      if(this.paragraph || this.paragraph == this.curr_paragraph) this.updateInfo("paragraph", this.paragraph)
    }
    if(curr_show == "cleared") {
      this.show = "issue"
   //   localStorage.setItem('show', 'issue')
      if(this.clearedText || this.clearedText == this.curr_clearedText) this.updateInfo("clearedText", this.clearedText)
    }
    if(curr_show == "issue") {
      this.router.navigateByUrl('setup/setup-questions')
   //   localStorage.removeItem('show')   // erase progress for next time
      if(this.issueText || this.issueText == this.curr_issueText) this.updateInfo("issueText", this.issueText)
    }
  }

  back(curr_show: string){
    if(curr_show == "name") this.router.navigateByUrl('register')
    if(curr_show == "logo") this.show = "name"
    if(curr_show == "website") this.show = "logo"
    if(curr_show == "email") this.show = "website"
    if(curr_show == "main") this.show = "email"
    if(curr_show == "cleared") this.show = "main"
    if(curr_show == "issue") this.show = "cleared"
  }

  disable(){
    if((this.show == "name" && (this.name || this.curr_name)) ||
      (this.show == "logo" && (this.curr_logo)) ||
      (this.show == "website" && (this.website || this.curr_website)) ||
      (this.show == "email" && (this.adminEmail || this.curr_adminEmail))  ||
      (this.show == "main" && (this.paragraph || this.curr_paragraph))  ||
      (this.show == "cleared" && (this.clearedText || this.curr_clearedText))  ||
      (this.show == "issue" && (this.issueText || this.curr_issueText))) return false
    return true
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = this.schoolID+"_logo";
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // // observe percentage changes
    // this.uploadPercent = task.percentageChanges();

    
    // get notified when the download URL is available
    this.taskSub = task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL()
          this.urlSub = this.downloadURL.subscribe(url => {
            if(url){  this.updateInfo("image", url)  }
          })
        })
     )
    .subscribe()
  }

  getCurrentInfo(){
    this.currentSub = this.fireservice.getInfo(this.schoolID).subscribe(doc => {
      this.curr_adminEmail = doc["adminEmail"]
      this.curr_paragraph = doc["paragraph"]
      this.curr_clearedText = doc["cleared"]
      this.curr_issueText = doc["issue"]
      this.curr_updated = doc["updated"]
      this.curr_website = doc["website"]
      this.curr_name = doc["name"]
      this.curr_logo = doc["image"]

      this.name = this.curr_name;
      this.adminEmail = this.curr_adminEmail;
      this.website = this.curr_website;
      
      //If it does not exist show default, otherwise show what it is. 
      if (this.curr_paragraph == undefined) {
        this.paragraph = "Hello everyone. We currently require all students and faculty to account for their wellness daily, we will let you know when there are any more updates. Stay safe out there!"
      } else {
        this.paragraph = this.curr_paragraph
      }

      if (this.curr_clearedText == undefined) {
        this.clearedText = "You have been cleared to enter the building. Have a good day!"
      } else {
        this.clearedText = this.curr_clearedText
      }

      if (this.curr_issueText == undefined) {
        this.issueText = "You have been cleared to enter the building. Have a good day!"
      } else {
        this.issueText = this.curr_issueText
      }

      // this.clearedText = this.curr_clearedText;
      
      // if(this.curr_paragraph) this.paragraph = ""
      // if(this.curr_clearedText) this.clearedText = ""
      // if(this.curr_issueText) this.issueText = ""
    })
  }

  updateInfo(type: string, updated: string){
    let updatedStr = this.getFormattedDate()

    if(type == "adminEmail") this.fireservice.updateInfo(this.schoolID, {adminEmail: updated, updated: updatedStr})
      .then(() => { this.adminEmail = '' })
      .catch(error => { console.log(error) })
    else if(type == "paragraph") this.fireservice.updateInfo(this.schoolID, {paragraph: updated, updated: updatedStr})
      .then(() => { this.paragraph = '' })
      .catch(error => { console.log(error) })
    else if(type == "clearedText") this.fireservice.updateInfo(this.schoolID, {cleared: updated, updated: updatedStr})
      .then(() => { this.clearedText = '' })
      .catch(error => { console.log(error) })
    else if(type == "issueText") this.fireservice.updateInfo(this.schoolID, {issue: updated, updated: updatedStr})
      .then(() => { this.issueText = '' })
      .catch(error => { console.log(error) })
    else if(type == "website") this.fireservice.updateInfo(this.schoolID, {website: updated, updated: updatedStr})
      .then(() => { this.website = '' })
      .catch(error => { console.log(error) })
    else if(type == "name") this.fireservice.updateInfo(this.schoolID, {name: updated, updated: updatedStr})
      .then(() => { this.name = '' })
      .catch(error => { console.log(error) })
    else if(type == "image") this.fireservice.updateInfo(this.schoolID, {image: updated, updated: updatedStr})
      .then(() => { this.name = '' })
      .catch(error => { console.log(error) })
    else throw("unexpected type")
  }

  getFormattedDate(): string {
    let updatedStr = "Updated "
    let today = new Date()
    let month = (today.getMonth()).toString()
    if(month[0] == '0') month = month.substring(1)
    let day = (today.getDate()).toString()
    if(day[0] == '0') day = day.substring(1)
    let year = today.getFullYear().toString()
    updatedStr = updatedStr + month + "-" + day + "-" + year
    return updatedStr;
  }

}
