import { AuthService } from './auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Student } from './objects/Student';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  // subscriptions
  schoolsub: Subscription


  // --- SCHOOL ID  --- //
  private userSource: BehaviorSubject<Student> = new BehaviorSubject(null);
  public user = this.userSource.asObservable();

  private schoolSource: BehaviorSubject<String> = new BehaviorSubject(null);
  public school = this.schoolSource.asObservable();

  // private commentsSource: BehaviorSubject<String> = new BehaviorSubject(null);
  // public comments = this.commentsSource.asObservable();

  constructor(
    private authservice: AuthService
  ) { 
      this.schoolsub = authservice.schoolID.subscribe(schoolID => {
        this.schoolSource.next(schoolID);
      }) 
    }

  // publish schoolID update
  public setUser(user: Student) {
    this.userSource.next(user);
    localStorage.setItem('user-details', JSON.stringify(user));
  }

  // publish comments update
  // public setComments(comments: string) {
  //   this.commentsSource.next(comments);
  // }

}
