import { combineAll, first } from 'rxjs/operators';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Student } from './../objects/Student';
import { Subscription, Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-uncompleted',
  templateUrl: './email-uncompleted.component.html',
  styleUrls: ['./email-uncompleted.component.scss']
})
export class EmailUncompletedComponent implements OnInit, OnDestroy {

    // school details
    schoolID: string
    grades: number[] = [1,2,3,4,5,6,7,8,9,10,11,12]

    schoolName: string
    schoolEmail: string

    // date 
    today = new Date()

    // user records
    uncompleted: Object[] = []
    uncompleted_emails: string[][] = []
    uncompleted_selected: boolean[] = []

    reminder_message: string = ""
    original_reminder: string = ""
    savedMessage = false;

    subject = ""
    message = ""

    // subscriptions
    accountsSub: Subscription
    authsub: Subscription
    infoSub: Subscription
  
    // user group to show
    userGroup = "all"           // 'all', 'name', or 'grade' depending on which version to show
    allUsers: Student[] = []
    nameUsers: Student[] = []

    manualSelect: boolean = false
    loading: boolean = false

    // search variable models
    searchName: string
    nameNotFound: boolean = true;
  
    // update variable models

    // email
    email_loading: boolean = false;
    email_error: boolean = false
    email_sent: boolean = false
    email_enabled: boolean = true
    confirm_email: boolean = false
    no_emails: boolean = false

    confirm_email_selected: boolean = false
    email_loading_selected: boolean = false
    email_error_selected: boolean = false
    email_sent_selected: boolean = false
    no_emails_selected: boolean = false

    show_email: boolean = false

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
  ) { }

  ngOnInit(): void {
    this.authsub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID
      this.infoSub = this.fireservice.getInfo(this.schoolID).subscribe(info => {
        this.schoolName = info["name"] || "Your School"
        this.schoolEmail = info["adminEmail"] || "your school"
        //this.message = this.message1 + this.schoolEmail + this.message2
        this.subject = this.schoolName + " - Complete Wellness Screener";
        this.original_reminder = info["reminder"]
        this.reminder_message = info["reminder"] || this.basicMessage();
      })
    })
    
  }

  // testMessage() {
  //   this.formatMessage()
  //   console.log(this.message)
  // }

  saveMessage(){
    if(this.original_reminder != this.reminder_message){ 
      this.saveEmail()
    } //else console.log("NOT changed")
    this.savedMessage = true;
    this.clearAfterXSeconds(2)
    this.show_email = false;
  }

  ngOnDestroy(): void {
    if(this.accountsSub) this.accountsSub.unsubscribe()
    if(this.authsub) this.authsub.unsubscribe()
    if(this.infoSub) this.infoSub.unsubscribe()
  }

  found(name: string){
    if (name.toLowerCase().indexOf(this.searchName.toLowerCase()) != -1 || this.searchName.toLowerCase().indexOf(name.toLowerCase()) != -1 ){ return true; }
    return false
  }

  clearSearch(){
    this.userGroup = "all"
    this.searchName = ""
    this.nameNotFound = true
  }

  // email ALL USERS -- no manual selection
  async emailUncompleted(){
    this.confirm_email = false
    this.email_loading = true
    this.email_sent = false
    this.email_error = false

    // LOAD ALL
    await this.loadUncompleted()

    // SEND EMAIL
    if(this.uncompleted_emails.length != 0){
      this.formatMessage()
      let emails = this.collectEmails()
      this.fireservice.sendUncompletedEmailArray(emails, this.subject, this.message).catch(error => {console.log(error); this.email_error = true}).then(() => this.saveEmail())
    }  else { 
      this.no_emails = true
      this.email_loading = false
      this.clearAfterXSeconds(5)
      return 
    }

    this.email_enabled = false
    this.email_loading = false
    this.email_sent = true
    this.clearAfterXSeconds(5)
    this.disableEmailForXSeconds(30)
    
  }

  collectEmails(){
    var emails: string[] = []
    for(let i = 0; i<this.uncompleted_emails.length; i++){
      let user_emails: string[] = this.uncompleted_emails[i]
      for(let j = 0; j<user_emails.length; j++){
        emails.push(this.uncompleted_emails[i][j])
      }
    }
    return emails;
  }

  emailSelected(){
    this.confirm_email_selected = false
    this.email_loading_selected = true
    this.email_sent_selected = false
    this.email_error_selected = false
    this.no_emails_selected = false

    // collect emails selected
    var emails: string[] = []
    for(let i = 0; i<this.uncompleted_emails.length; i++){
      if(this.uncompleted_selected[i]){ 
        for(let j = 0; j<this.uncompleted_emails[i].length; j++){
          emails.push(this.uncompleted_emails[i][j])
        }
      }
    }

    // SEND EMAIL
    if(emails.length != 0){
      this.formatMessage()
      this.fireservice.sendUncompletedEmailArray(emails, this.subject, this.message).catch(error => {console.log(error); this.email_error = true}).then(() => this.saveEmail())
    } else { 
      this.no_emails_selected = true
      this.email_loading = false
      this.clearAfterXSeconds(5)
      return 
    }

    this.email_enabled = false
    this.email_loading_selected = false
    this.email_sent_selected = true
    this.clearAfterXSeconds(5)
    this.disableEmailForXSeconds(30)
  }

  async loadUncompleted(){
    this.loading = true
    let date = this.formatDateString((this.today.getMonth() + 1).toString(), this.today.getDate().toString(), this.today.getFullYear().toString())

    // observables
    let archiveObs: Observable<any>
    let accountObs: Observable<any>

    // get all accounts
    accountObs = this.fireservice.getSchoolAccountsManage(this.schoolID).pipe(first())
    accountObs.subscribe(async accounts => {
      this.uncompleted_emails = []
      this.uncompleted = []
      this.uncompleted_selected = []

      // check for uncompleted
      archiveObs = this.fireservice.getArchive(this.schoolID, date).pipe(first())
      archiveObs.subscribe(async records => {

        // if there is at least one screener entry
        if(records.length != 0){

          // loop through all accounts
          accounts.forEach((doc, index, array) => {

            if(doc){

              // get document snapshot values
              var doc_id = doc.payload.doc.id
              var value = doc.payload.doc.data()

              // get firebase values
              let name = value["name"]
              let emails: string[] = value["emails"]
              let id = value["id"]

              // for each account check through screener records
              var found = false;
              for(let record of records){
                if(record.payload.doc.id == id){
                  found = true;
                  break;
                }
              }

              // if user is not found in the records, they have not filled it out
              if(!found){

                // add to uncompleted array
                this.uncompleted.push({
                  name: name,
                  // email: emails[0],
                  emails: emails
                }) 

                // load both parent and student emails
                // for(let i = 0; i<emails.length; i++){
                  // add both emails to just emails array
                  this.uncompleted_emails.push(emails)

                  // add to selected array
                  this.uncompleted_selected.push(true)
                // }
                
              }
            } 

            //ERROR
            else {
              this.email_error = true
              return
            }

          })
        }

        // if no one has filled out the screener, EVERYONE is uncompleted
        else { 
          accounts.forEach(doc => {

            if(doc){
              // get document snapshot values
              var doc_id = doc.payload.doc.id
              var record = doc.payload.doc.data()

              // if valid user --> add to array
              if(record.name && record.emails[0]){
                // add to uncompleted array
                // this.uncompleted.push({
                //   name: record["name"],
                //   email: record["emails"][0]
                // })

                // // add to just emails array
                // this.uncompleted_emails.push(record["emails"][0])
                
                // // add to selected array
                // this.uncompleted_selected.push(true)

                // add to uncompleted array
                this.uncompleted.push({
                  name: record.name,
                  // email: emails[0],
                  emails: record.emails
                }) 

                // load both parent and student emails
                // for(let i = 0; i<record.emails.length; i++){
                  // add both emails to just emails array
                  this.uncompleted_emails.push(record.emails)

                  // add to selected array
                  this.uncompleted_selected.push(true)
                // }

              }
            } 
            
            //ERROR
            else {
              this.email_error = true
              return
            }
            
          })
        }

      })
      await archiveObs.toPromise()
    })
    await accountObs.toPromise()
    await archiveObs.toPromise()
    this.loading = false
  }

  basicMessage(){
    return "Hello,\n\n" + "This is a reminder to complete the SWS daily wellness screener." + 
           "If you are having technical difficulties, please contact " + this.schoolEmail + "." +
           "\n\nThank you,\nSchool Wellness Screener (SWS)"
  }

  formatMessage(){
    this.message = this.reminder_message 
    this.message = this.message.replace(/\n/g, "<code><br /></code>")
  }

  formatDateString(month: string, day: string, year: string): string{
    return month + "-" + day + "-" + year;
  }

  disableEmailForXSeconds(x:number){
    var timeout = setTimeout( () => {
      this.email_enabled = true
    }, (x*1000));
  }

  saveEmail(){
    this.fireservice.saveEmailText(this.schoolID, this.reminder_message).catch(error => console.log(error));
  }

  clearAfterXSeconds(x:number){
    var timeout = setTimeout( () => {
      this.clearErrors()
    }, (x*1000));
  }

  clearErrors(){
    this.email_sent = false
    this.email_error = false
    this.no_emails = false
    this.email_sent_selected = false
    this.email_error_selected = false
    this.email_sent_selected = false
    this.savedMessage = false
  }

}
