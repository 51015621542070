<html lang="en">
<head>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>

    <div class="secondaryContainer">
            
        <div class="row">

        <div class="col-sm-12">
            <h3 class="title">Upload Vaccine Data</h3>
            <p class="desc">

                Please enter the data shown on your COVID-19 Vaccination Card shown in red. 
                The vaccine company and ID number may be reversed on your card. 


            </p>

            <img src="../../assets/vaccine-card.jpg" width="450px">



           <!-- <p><strong>Current: </strong>{{ curr_name }}</p>
            -->

            <div>
       <h3 class="miniTitle">Enter Lot 1 ID</h3>

        <input type="text" class="textFieldInput" placeholder="Example: EK3456">
          </div>

          <div>
            <h3 class="miniTitle">Enter Lot 1 Date</h3>

            <input type="text" class="textFieldInput" placeholder="Example: 12/05/20">
        </div>

        <div>
            <h3 class="miniTitle">Enter Lot 2 ID</h3>

            <input type="text" class="textFieldInput" placeholder="Example: 533QUF">
              </div>
    
              <div>
                <h3 class="miniTitle">Enter Lot 2 Date</h3>

                <input type="text" class="textFieldInput" placeholder="Example: 1/18/21">
            </div>
    



</div>

</div>

<button class="btn back-btn" (click)="goBack()"> Go Back</button>
<button class="btn update-btn" (click)="submit()"> Continue</button>
</div>


        
</body>
</html>