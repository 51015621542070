export class Student {
    name: string
    email: string
    grade: string
    power: string
    type: string
    id: string
    umbrella: string
    comments: string
  
    constructor(name:string, email: string, grade: string, power: string, type: string, id: string, umbrella: string, comments: string){
      this.name = name
      this.email = email
      this.grade = grade
      this.power = power
      this.type = type
      this.id = id
      this.umbrella = umbrella
      this.comments = comments
    }
  }