import { first } from 'rxjs/operators';
import { Student } from './objects/Student';
import { User, firestore } from 'firebase';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AngularfireService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getUserDetails(user: User) {
    if(user) return this.firestore.collection("accounts").doc(user.uid).valueChanges()
    else return new BehaviorSubject('EMPTY OBSERVABLE').asObservable()
  }

  getUserFromID(id: string, school: string){
    return this.firestore.collection(school).doc("users").collection("all").doc(id).valueChanges()
  }

  getAccountFromUID(uid: string){
    return this.firestore.collection("accounts").doc(uid).valueChanges()
  }

  getLinkedAccounts(umbrella: string){
    return this.firestore.collection("accounts", ref => ref.where("umbrella", "==", umbrella)).valueChanges()
  }

  getAccountsFromUmbrella(umbrella: string){
    return this.firestore.collection("accounts", ref => ref.where("umbrella", "==", umbrella)).snapshotChanges()
  }

  getAccountsFromID(id: string){
    return this.firestore.collection("accounts", ref => ref.where("people", 'array-contains', id)).snapshotChanges()
  }

  getAccountFromEmail(email:string){
    return this.firestore.collection("accounts", ref => ref.where("email", "==", email)).valueChanges()
  }

  // getSchoolAccounts(schoolPIN: string){
  //   return this.firestore.collection(schoolPIN).doc("users").collection("all").valueChanges()
  // }

  getSchoolAccountsManage(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").snapshotChanges()
  }

  getSchoolUsers(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").valueChanges()
  }

  getSchoolTeachers(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all", ref => ref.where("type", 'in', ["Other", "Faculty/Staff"])).valueChanges()
  }

  getNameSearchUsers(schoolPIN: string, name:string){
    let names = []
    names.push(name, name.toUpperCase(), name.toLowerCase(), (name[0].toUpperCase()+name.substring(1)))
    return this.firestore.collection(schoolPIN).doc("users").collection("all", ref => ref.where("name", "in", names)).valueChanges()
  }

  getGradeSearchUsers(schoolPIN: string, grade:string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all", ref => ref.where("grade", "==", grade)).valueChanges()
  }

  getAttendance(schoolPIN: string, date: string, type: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("attendance").doc(date).collection(type).valueChanges()
    // , ref => ref.orderBy("date")
  }

  getArchive(schoolPIN: string, date: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("archivedResults").doc(date).collection("all").snapshotChanges()
  }

  getArchiveNoIDAccess(schoolPIN: string, date: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("archivedResults").doc(date).collection("all").valueChanges()
  }

  getQuestions(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("questions").collection("titles").snapshotChanges()
  }

  getQuestionsNoNumAccess(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("questions").collection("titles").valueChanges()
  }

  getInfo(schoolPIN: string){
    return this.firestore.collection(schoolPIN).doc("information").valueChanges()
  }

  getUmbrellaUsers(schoolPIN: string, umbrella: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("umbrellas").doc("all").collection(umbrella).valueChanges()
  }
  
  getToLoginByEmail(schoolPIN: string, email:string){
    return this.firestore.collection(schoolPIN).doc("users").collection("toLogin", ref => ref.where("email", "==", email)).valueChanges()
  }

  getMailCollection(){
    return this.firestore.collection("mail")
  }

  // --------------------------------//

  updateAccount(UID:string, updates: object){
    return this.firestore.collection("accounts").doc(UID).update(updates)
  }

  updateAccountSET(UID: string, updates: object){
    return this.firestore.collection("accounts").doc(UID).set(updates)
  }

  updateInfo(schoolPIN:string, updates: object){
    return this.firestore.collection(schoolPIN).doc("information").update(updates)
  }

  updateUserAll(schoolPIN:string, id:string, updates: object){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).update(updates)
  }

  updateUserUmbrella(schoolPIN:string, umbrella:string, id:string, updates: object){
    return this.firestore.collection(schoolPIN).doc("users").collection("umbrellas").doc("all").collection(umbrella).doc(id).update(updates)
  }

  updateQuestion(schoolID: string, Qnum: number, newQ: string){
    return this.firestore.collection(schoolID).doc("questions").collection("titles").doc(Qnum.toString()).set({title: newQ})
  }

  // ------------------------------- //

  addComments(schoolID: string, userID: string, comments: string){
    return this.updateUserAll(schoolID, userID, {comments: comments});
  }

  // ------------------------------- //

  logScreenerArchive(schoolID: string, ID: string, results: string[]){
    let date = this.getTodayDate()
    return this.firestore.collection(schoolID).doc("users").collection('archivedResults').doc(date).collection("all").doc(ID).set({results: results})
  }

  logScreenerStatus(schoolID: string, ID: string, results: string[]){
    let date = this.getTodayDate()
    if(results.length == 0) var status = "OK"
    else var status = "Issue"
    return this.firestore.collection(schoolID).doc("users").collection('all').doc(ID).update({date: date, status: status})
  }

  // ------------------------------- //

  makeAdminUsersAll(schoolPIN:string, id: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).update({ power: "admin" })
  }

  makeAdminGlobal(email: string, schoolID: string){
    return this.firestore.collection("makeAdmin").doc(this.generateID()).set({email: email, type: "admin", schoolID: schoolID})
  }

  makeSuperAdminUsersAll(schoolPIN:string, id: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).update({ power: "superadmin" })
  }

  makeSuperAdminGlobal(email: string, schoolID: string){
    return this.firestore.collection("makeAdmin").doc(this.generateID()).set({email: email, type: "superadmin", schoolID: schoolID})
  }

  // ------------------------------- //

  private addUserToAll(schoolPIN:string, user: Student){
    var power = user.power
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(user.id).set({
      comments: "No current comments.",
      date: "n/a",
      emails: [user.email],
      grade: user.grade,
      id: user.id,
      name: user.name,
      power: power,
      status: "Issue",
      type: user.type,
      umbrella: user.umbrella
    })
  }

  private addUserToUmbrella(schoolPIN:string, user: Student){
    return this.firestore.collection(schoolPIN).doc("users").collection("umbrellas").doc("all").collection(user.umbrella).doc(user.id).set({
      grade: user.grade,
      id: user.id,
      name: user.name,
      umbrella: user.umbrella
    })
  }

  // ------------------------------- //

  removeAdminUsersAll(schoolPIN: string, id: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).update({ power: "regular" })
  }

  removeAdminGlobal(email: string, schoolID: string){
    return this.firestore.collection("makeAdmin").doc(this.generateID()).set({email: email, type: "regular", schoolID: schoolID})
  }

  removeSuperAdminUsersAll(schoolPIN: string, id: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).update({ power: "admin" })
  }

  removeSuperAdminGlobal(email: string, schoolID: string){
    return this.firestore.collection("makeAdmin").doc(this.generateID()).set({email: email, type: "admin", schoolID: schoolID})
  }

  removeUserFromAll(schoolPIN:string, id:string){
    return this.firestore.collection(schoolPIN).doc("users").collection("all").doc(id).delete()
  }
  removeUserFromUmbrella(schoolPIN:string, umbrella:string, id:string){
    return this.firestore.collection(schoolPIN).doc("users").collection("umbrellas").doc("all").collection(umbrella).doc(id).delete()
  }

  // deleteUmbrella(schoolPIN:string, umbrella:string){
  //   return this.firestore.collection(schoolPIN).doc("users").collection("umbrellas").doc("all").collection(umbrella)
  // }

  deleteFromToLogin(schoolPIN:string, uid: string){
    return this.firestore.collection(schoolPIN).doc("users").collection("toLogin").doc(uid).delete()
  }

  deleteQuestion(schoolID: string, Qnum: number){
    return this.firestore.collection(schoolID).doc("questions").collection("titles").doc(Qnum.toString()).delete()
  }

  private deleteAccountRecord(UID: string){
    return this.firestore.collection("accounts").doc(UID).delete().catch(error => console.log(error))
  }

  // -- heavier lifting methods -- //

  removeFromUmbrella(schoolPIN:string, umbrella:string, id:string){
    this.removeUserFromAll(schoolPIN, id).catch(error => console.log(error))
    this.removeUserFromUmbrella(schoolPIN, umbrella, id).catch(error => console.log(error))
  }

  addToUmbrella(schoolPIN:string, user:Student){
    this.addUserToAll(schoolPIN, user)
    this.addUserToUmbrella(schoolPIN, user)
  }

  // ---------------------------------------- //

  unauthenticateUser(email:string, schoolID:string){
    return this.firestore.collection("toDelete").doc(this.generateID()).set({email: email, schoolID: schoolID})
  }

  removeLinkedFromAccounts(umbrella: string, email: string){
    return this.getAccountsFromUmbrella(umbrella).pipe(first()).subscribe(data => {
      for(let i=0; i<data.length; i++){
        let account = data[i].payload.doc.data()
        let accountUID = data[i].payload.doc.id
        if(account["email"] == email) { this.deleteAccountRecord(accountUID) }
      }
    })
  }

  removeAccountByID(ID: string, school: string){
    this.getAccountsFromID(ID).pipe(first()).subscribe(data => {
      if(data){
        for(let i=0; i<data.length; i++){
          let account = data[i].payload.doc.data()
          let accountUID = data[i].payload.doc.id
          if(account["school"] == school) { console.log("delete " + accountUID); }//this.deleteAccountRecord(accountUID) }
        }
      } else console.log("ERROR NO DATA")
    })
  }

  // ---------------------------------------- //


  sendAccountConfirmationEmail(registeredEmail: string, school: string, subjectString: string, message: string){
    return this.getMailCollection().add({
      to: registeredEmail,
      message: {
        subject: subjectString,
        html: "mess"
      }
    })
  }


  sendErrorEmailAlert(id: string, school:string){
    return this.getMailCollection().add({
      to: "sarah@visionappsproducts.com",
      message: {
        subject: school + " error: blank user found",
        html: "Blank user found in school: " + school + " with id: " + id
      }
    })
  }

  sendUncompletedEmail(toEmail: string, subject: string, message: string){
    return this.getMailCollection().add({
      to: toEmail,
      message: {
        subject: subject,
        html: message
      }
    })
  }

  sendUncompletedEmailArray(toEmails: string[], subject: string, message: string){
    return this.getMailCollection().add({
      to: "noreply@schoolwellnessscreener.com",
      bcc: toEmails,
      message: {
        subject: subject,
        html: message
      }
    })
  }

  saveEmailText(school: string, message: string){
    return this.firestore.collection(school).doc("information").update({reminder: message});
  }

  // ---------------------------------------- //


  createAccountDoc(uid: string, account:string, email: string, name: string, school: string, umbrella:string, id: string){
    return this.firestore.collection("accounts").doc(uid).set({
      UID: uid,
      account: account,
      emails: [ email ],
      name: name,
      school: school,
      umbrella: umbrella,
      people: { [id]: id }
    })
  }

  createUsersAllDoc(school:string, id: string, name: string, email: string, grade:string, power: string, type: string, umbrella: string, comments?: string, date?: string, status?: string, ){
    comments = comments || "No Current Comments"
    date = date || "n/a"
    status = status || "OK"
    return this.firestore.collection(school).doc("users").collection("all").doc(id).set({
      comments: comments,
      date: date,
      emails: [ email ],
      grade: grade,
      id: id,
      name: name,
      power: power,
      status: status,
      type: type,
      umbrella: umbrella
    })
  }

  createUsersToLoginDoc(school:string, uid: string, email:string, power: string, umbrella: string){
    return this.firestore.collection(school).doc("users").collection("toLogin").doc(uid).set({
      UID: uid,
      email: email,
      power: power,
      umbrella: umbrella
    })
  }

  createUsersUmbrellaDoc(school:string, grade:string, id: string, name:string, umbrella:string){
    return this.firestore.collection(school).doc("users").collection("umbrellas").doc("all").collection(umbrella).doc(id).set({
      id: id,
      grade: grade,
      name: name,
      umbrella: umbrella
    })
  }

  // --- HELPERS --- //

    // FROM EFFIE --> generates a random unique ID for the new user
    generateID(){
      var uid = '', m = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx', i=0, rb=Math.random()*0xFFFFFFFF|0;
      while(i++<36) {
        var c=m[i-1],r=rb&0xf,v=c == 'x'?r:(r&0x3|0x8);
        uid+=(c=='-'||c=='4')?c:v.toString(16);rb=i%8==0?Math.random()*0xFFFFFFFF|0:rb>>4
  
        //I think the lowercase looks gross so I added this.
        var upperCase = uid.toUpperCase();
      }
      return upperCase
    }

    getTodayDate(): string {
      let today = new Date()
      let month = (today.getMonth()+1).toString()
      if(month[0] == '0') month = month.substring(1)
      let day = (today.getDate()).toString()
      if(day[0] == '0') day = day.substring(1)
      let year = today.getFullYear().toString()
      
      return (month + "-" + day + "-" + year);
    }

}
