import { AngularfireService } from './../../angularfire.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent implements OnInit, OnDestroy {

  schoolID:string

  authsub: Subscription

  constructor(
    private fireauth: AngularFireAuth,
    private router: Router,
    private fireservice: AngularfireService
  ) { }

  ngOnInit(): void {
    this.authsub = this.fireauth.authState.pipe(first()).subscribe(auth => {
      this.schoolID = localStorage.getItem('schoolID')

      // // back to register page if no schoolID or auth
      if(!this.schoolID || !auth ){
        this.router.navigateByUrl('register')
      }

      // set 'registered' bool on information page to true (prevents from attempting onboarding again)
      this.fireservice.updateInfo(this.schoolID, { registered: true }).catch(error => console.log(error))

    })
  }

  ngOnDestroy() {
    if(this.authsub) this.authsub.unsubscribe()
  }

  done(){
    localStorage.removeItem('schoolID')
    this.router.navigateByUrl('admin')
  }

}
