import { DownloadCsvService } from './../download-csv.service';
import { first } from 'rxjs/operators';
import { Observable, pipe, Subscription } from 'rxjs';
import { json2csv } from 'json-2-csv';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {

    // admin details
    schoolID: string;

    // auth sub
    authsub: Subscription

    // download users
    downloadUserDetails: string;

    // TODAY DATE INFO
    today = new Date()
  
    additionalInformation: boolean = false
    additionalInformationHW: boolean = false
    additionalInformationCU: boolean = false
    additionalInformationUNC: boolean = false


    months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    // download attendance
    month: string = this.downloadservice.getTodayMonth()
    month_str = this.months[parseInt(this.month)-1]
    day: string = this.downloadservice.getTodayDay()
    year: string = this.downloadservice.getTodayYear()
    date: string = this.downloadservice.getTodayDateString()
    type: string = "entering"
    sort: string = "time"
    recordExistsAttendance = true;
  
    // download archive
    archive_month: string = this.downloadservice.getTodayMonth()
    archive_month_str = this.months[parseInt(this.archive_month)-1]
    archive_day: string = this.downloadservice.getTodayDay()
    archive_year: string = this.downloadservice.getTodayYear()
    archive_date: string = this.downloadservice.getTodayDateString()
    archive_sort: string = "grade"
    recordExistsArchive = true;

    // download completed
    completed_month: string = this.downloadservice.getTodayMonth()
    completed_month_str = this.months[parseInt(this.completed_month)-1]
    completed_day: string = this.downloadservice.getTodayDay()
    completed_year: string = this.downloadservice.getTodayYear()
    completed_date: string = this.downloadservice.getTodayDateString()
    completed_sort: string = "grade"
    recordExistsCompleted = true;
    completed_length = 0;
    completed_loaded = 0;

    // download uncompleted
    uncompleted_month: string = this.downloadservice.getTodayMonth()
    uncompleted_month_str = this.months[parseInt(this.uncompleted_month)-1]
    uncompleted_day: string = this.downloadservice.getTodayDay()
    uncompleted_year: string = this.downloadservice.getTodayYear()
    uncompleted_date: string = this.downloadservice.getTodayDateString()
    uncompleted_sort: string = "grade"
    recordExistsUncompleted = true;

    // loading icons
    all_loading: boolean = false
    QR_loading: boolean = false
    screener_loading: boolean = false
    completed_loading: boolean = false
    uncompleted_loading: boolean = false

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
    private downloadservice: DownloadCsvService
  ) { }

  ngOnInit(): void {
    this.authsub = this.authservice.schoolID.subscribe(schoolID => this.schoolID = schoolID)
  }

  ngOnDestroy(){
    if(this.authsub) this.authsub.unsubscribe()
  }

  // DONE -- FIXED
  downloadAllUsers(){
    this.all_loading = true
    this.clearErrors()
    this.downloadservice.downloadAllUsers(this.schoolID)
    this.clearLoading()
  }


  // DONE
  downloadAttendance() {
    this.setMonthNums()
    this.clearErrors()
    this.QR_loading = true
    this.date = this.downloadservice.formatDateString(this.month, this.day, this.year)
    this.downloadservice.downloadAttendance(this.schoolID, this.date, this.type, this.sort).catch(error => { this.recordExistsAttendance = false })
    this.clearLoading()
  }

  // DONE
  async downloadArchive() {


   // variable setups
   this.clearErrors()
   this.setMonthNums()
    this.screener_loading = true
    this.archive_date = this.downloadservice.formatDateString(this.archive_month, this.archive_day, this.archive_year)

    //observable
    var observableNames: Observable<any>
    // var observableQuestions: Observable<any>

    // get school's questions questions
    // var questionsArray: string[] = []
    // observableQuestions = this.fireservice.getQuestions(this.schoolID).pipe(first())
    // observableQuestions.subscribe(questions => {

    //   // create and order questions array
    //   if(questions){
    //     for(let i = 1; i <= questions.length; i++){

    //       // find corresponding document
    //       for(let j = 0; j < questions.length; j++){
    //         // get document snapshot values
    //         var doc_id = questions[j].payload.doc.id
    //         var question = questions[j].payload.doc.data()

    //         if( i.toString() == doc_id ){
    //           questionsArray.push(question.title)
    //         }
    //       }
    //     }
    //   }

    // })
    // await observableQuestions.toPromise()

    // get records from ARCHIVEDRESULTS subcollection
    this.fireservice.getArchive(this.schoolID, this.archive_date).pipe(first()).subscribe(async records => {
      if(records && records.length != 0){

        // format object for conversion to CSV
        var Obj: Object[] = []
        
        records.forEach(async (value, index, array) => {
          let obj: Object = {}
          let id = value.payload.doc.id
          //obj["id"] = id
          obj["date"] = this.archive_date
          obj["name"] = ""
          obj["email"] = ""
          obj["grade"] = ""
          obj["symptoms"] = ""

          // //check each question
          // for(let i = 0; i<questionsArray.length; i++){
          //     var question = questionsArray[i];
          //     var present = false;
          //     // check each entry in result array
          //     for(let j=0; j<value.payload.doc.data().results.length; j++){
          //       if((value.payload.doc.data().results[j]).localeCompare(question) == 0) present = true
          //     } 
          //     if(present) obj[question] = "SYMPTOM PRESENT"
          //     else obj[question] = " "
          // }

          let doc = value.payload.doc.data()
          var symptoms = ""
          for(let i = 0; i< doc["results"].length; i++){
            symptoms += doc["results"][i] + '\n'
          }

          obj["symptoms"] = symptoms

          observableNames = this.fireservice.getUserFromID(id,this.schoolID).pipe(first())
          observableNames.subscribe(student => {
            if(student){
              if(student["name"]) obj["name"] = student["name"]
              else obj["name"] = "No Name"
              if(student["emails"]) { obj["email"] = student["emails"][0] }
              else obj["email"] = "No Email"
              if(student["grade"]) obj["grade"] = student["grade"]
              else obj["grade"] = "Nonexistent Grade"
            } else console.log("STUDENT NOT VALID")
          })
          await observableNames.toPromise()
          Obj.push(obj)
        })
        // await observableQuestions.toPromise()
        await observableNames.toPromise()
        
        // SORTING
        Obj = this.downloadservice.customSort(this.archive_sort, Obj)
        this.downloadservice.downloadObjectAsCSV(Obj)
        this.clearLoading()
        // this.downloadObjectAsCSV(Obj)
      } else {
        this.recordExistsArchive = false;
        this.screener_loading = false
      }
    })
  }

  downloadCompleted(){

    this.setMonthNums()
    this.completed_loading = true
    this.clearErrors()
    this.completed_date = this.downloadservice.formatDateString(this.completed_month, this.completed_day, this.completed_year)

    // get documents for everyone who filled out survey
    this.fireservice.getArchive(this.schoolID, this.completed_date).pipe(first()).subscribe(async records => {
      this.completed_length = records.length

      if(records.length != 0){
        var completed: Object[] = []
        var observableNames: Observable<any>

        // for each user completed
        for(let record of records){
          let obj: Object = {}
          let id = record.payload.doc.id
          this.completed_loaded++

          let valid = true;

          obj["name"] = ""
          obj["grade"] = ""
          obj["email"] = ''
          obj["id"] = id
          obj["status"] = ""

          // get names and grades
          observableNames = this.fireservice.getUserFromID(id,this.schoolID).pipe(first())
          observableNames.subscribe(student => {
            if(student){
              obj["name"] = student["name"]
              obj["grade"] = student["grade"]
              if(student["emails"])  {obj["email"] = student["emails"][0]}
              else obj["email"] = null
              if(student["status"]) obj["status"] = student["status"]
              else obj["status"] = null
            } else {
              valid = false;
              this.fireservice.sendErrorEmailAlert(id, this.schoolID)
            }
          })
          await observableNames.toPromise()
          if(valid) completed.push(obj)
        }
        // SORT
        completed = this.downloadservice.customSort(this.completed_sort, completed)
        this.downloadservice.downloadObjectAsCSV(completed)
        this.clearLoading()
        // this.downloadObjectAsCSV(completed)
      } 
      else {
        this.recordExistsCompleted = false;
        this.completed_loading = false
      }
    })
  }

  // FIXED
  downloadUncompleted(){
    
    this.uncompleted_loading = true
    this.clearErrors()
    this.setMonthNums()
    this.uncompleted_date = this.downloadservice.formatDateString(this.uncompleted_month, this.uncompleted_day, this.uncompleted_year)

    this.fireservice.getSchoolAccountsManage(this.schoolID).pipe(first()).subscribe(accounts => {
      var uncompleted: Object[] = []
      var observableNames: Observable<any>
      this.fireservice.getArchive(this.schoolID, this.uncompleted_date).pipe(first()).subscribe(records => {

        // if there is at least one screener entry
        if(records.length != 0){

          // loop through all accounts
          accounts.forEach((doc, index, array) => {

            // get document snapshot values
            var doc_id = doc.payload.doc.id
            var value = doc.payload.doc.data()

            // get firebase values
            let name = value["name"]
            let emails = value["emails"]
            let grade = value["grade"]
            let type = value["type"]
            let id = value["id"]
            let umbrella = value["umbrella"]
            let power = value["power"]

            // checking to make sure user isn't null --> if it is, delete
            if( (name == null     || name == ""     || name == "n/a" ) &&
                (emails == null   || emails == ""   || emails == "n/a") &&
                (grade == null    || grade == ""    || grade == "n/a") &&
                (type == null     || type == ""     || type == "n/a")  &&
                (id == null       || id == ""       || id == "n/a")    &&
                (power == null    || power == ""    || power == "n/a") &&
                (umbrella == null || umbrella == "" || umbrella == "n/a") ){
              this.fireservice.removeUserFromAll(this.schoolID, doc_id)//.then(() => console.log("user successfully deleted"))
            } 
            // if user ISNT null then check if it's completed a screener
            else {

              // for each account check through screener records
              var found = false;
              for(let record of records){
                if(record.payload.doc.id == id){
                  found = true;
                  break;
                }
              }

              // if user is not found in the records, they have not filled it out
              if(!found){
                // add to uncompleted array
                uncompleted.push({
                  name: name,
                  grade: grade,
                  email: emails[0],
                  id: id
                })
              }

            }
          })
        }

        // if no one has filled out the screener, EVERYONE is uncompleted
        else { 
          accounts.forEach(doc => {
            // get document snapshot values
            var doc_id = doc.payload.doc.id
            var record = doc.payload.doc.data()

            // if valid user --> add to array
            if(record.name && record.grade && record.emails[0]){
              uncompleted.push({
                name: record["name"],
                grade: record["grade"],
                email: record["emails"][0]
              })
            }
            // if not valid user --> delete
            else {
              this.fireservice.removeUserFromAll(this.schoolID, doc_id)//.then(() => console.log("user deleted successfully"))
            }
            
          })
        }
        // either way: download
          //SORT
          uncompleted = this.downloadservice.customSort(this.uncompleted_sort, uncompleted)
          this.downloadservice.downloadObjectAsCSV(uncompleted)
          this.clearLoading()
          // this.downloadObjectAsCSV(uncompleted)
      })
    })
  }

  clearAfterXSeconds(x:number){
    var timeout = setTimeout( () => {
      this.clearErrors()
    }, (x*1000));
  }

  clearErrors(){
    this.recordExistsArchive = true
    this.recordExistsAttendance = true
    this.recordExistsCompleted = true
    this.recordExistsUncompleted = true
  }

  clearLoading(){
    this.all_loading = false;
    this.QR_loading = false
    this.screener_loading = false
    this.completed_loading = false
    this.uncompleted_loading = false
  }

  loading(){
    return (this.all_loading || this.QR_loading || this.screener_loading || this.completed_loading || this.uncompleted_loading)
  }

  setMonthNums(){
    this.month = this.getMonthNum(this.month_str).toString()
    this.archive_month = this.getMonthNum(this.archive_month_str).toString()
    this.completed_month = this.getMonthNum(this.completed_month_str).toString()
    this.uncompleted_month = this.getMonthNum(this.uncompleted_month_str).toString()
  }
  
  getMonthNum(month:string){
    if(month=="January") return 1
    else if(month=="February") return 2
    else if(month=="March") return 3
    else if(month=="April") return 4
    else if(month=="May") return 5
    else if(month=="June") return 6
    else if(month=="July") return 7
    else if(month=="August") return 8
    else if(month=="September") return 9
    else if(month=="October") return 10
    else if(month=="November") return 11
    else if(month=="December") return 12
    else return -1
  }
}
