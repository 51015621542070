<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="newContainer">
        <!-- UPDATE INFO SECTION -->


        <!-- <div class="row">
            <button class="downloadAllUsers" (click)="downloadAllUsers()" [disabled]="loading()" *ngIf="!all_loading"
            >Download All Registered Users</button>
            <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="all_loading"> 
        </div> -->

     
        <br>
        <div class="update">

    
                
    

            <div class="row">
                <div class="col-sm-12">
                    <p>Information Last {{ curr_updated }}</p>
                </div>
            </div>

            <!-- MAIN MESSAGE -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update Main Message</h3>
                    <p><strong>Current: </strong>{{ curr_paragraph }}</p>
                    <textarea [(ngModel)]="paragraph"class="textFieldInput"></textarea>
                </div>
                <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn XL" 
                    (click)="updateInfo('paragraph', paragraph)"
                    [disabled]="!paragraph">Update</button>
                </div>
            </div> 

            <!-- CLEARED MESSAGE -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update 'Cleared' Message</h3>
                    <p><strong>Current: </strong>{{ curr_clearedText }}</p>
                    <textarea [(ngModel)]="clearedText"class="textFieldInput"></textarea>
                </div>
                <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn" 
                    (click)="updateInfo('clearedText', clearedText)"
                    [disabled]="!clearedText">Update</button>
                </div>
            </div> 

            <!-- ISSUE MESSAGE -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update 'Issue' Message</h3>
                    <p><strong>Current: </strong>{{ curr_issueText }}</p>
                    <textarea [(ngModel)]="issueText"class="textFieldInput"></textarea>
                </div>
                <div class="col-sm-2">
                    <button class="btn update-btn textarea-btn" 
                    (click)="updateInfo('issueText', issueText)"
                    [disabled]="!issueText">Update</button>
                </div>
            </div> 

            <!-- WEBSITE -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update Support Website</h3>
                    <p><strong>Current: </strong>{{ curr_website }}</p>
                    <input type="text" [(ngModel)]="website"class="singleFieldInput">
                </div>
                <div class="col-sm-2">
                    <button class="btn update-btn input-btn" 
                    (click)="updateInfo('website', website)"
                    [disabled]="!website">Update</button>
                </div>
            </div> 

            <!-- ADMIN EMAIL -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update Main Contact Email</h3>
                    <p><strong>Current: </strong>{{ curr_adminEmail }}</p>
                    <input type="text" [(ngModel)]="adminEmail"class="singleFieldInput">
                </div>
                <div class="col-sm-2">
                    <button class="btn update-btn input-btn" 
                    (click)="updateInfo('adminEmail', adminEmail)"
                    [disabled]="!adminEmail">Update</button>
                </div>
            </div> 

            <!-- LOGO -->
            <div class="row">
                <div class="col-sm-10">
                    <h3 class="title">Update School Logo</h3>
                    <p><strong>Current:</strong></p>
                    <img src="" alt="school logo" id="logo" width="100px">
                    <br><br>
                    <input type="file" name="logo_upload" (change)="uploadFile($event)">
                    <div class="alert alert-danger" *ngIf="logo_type_error">Please upload an image file (jpeg, jpg, or png).</div>
                </div>
            </div>

            <!-- <br>
            <hr> -->

            <!-- ENABLE NOTIFICATIONS -->
            <!-- <div class="row">
                <div class="col-sm-10">
                    <h3>Update Notification Preferences</h3>
                    <p *ngIf="notificationsEnabled">Currently: enabled.</p>
                    <p *ngIf="!notificationsEnabled">Currently: disabled.</p>
                </div>
                <div class="col-sm-2">
                    <button *ngIf="notificationsEnabled" class="btn update-btn" (click)="disableNotifications()" style="margin-top: 20px;">Disable</button>
                    <button *ngIf="!notificationsEnabled" class="btn update-btn" (click)="enableNotifications()" style="margin-top: 20px;">Enable</button>
                </div>
            </div> -->

            <!-- NOTIFICATION TIME -->
            <!-- <div class="row" *ngIf="notificationsEnabled">
                <div class="col-sm-12">
                    <h3>Update Notification Time</h3>
                    <div class="row">
                        <div class="col-sm-10">
                            <input type="time" [(ngModel)]="notificationTime" style="margin-top: 5px;">
                        </div>
                        <div class="col-sm-2">
                            <button *ngIf="notificationsEnabled" class="btn update-btn" (click)="updateNotifications()">Update</button>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</body>
</html>