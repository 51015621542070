<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">
        <h1 style="margin-bottom: 10px;">Send Reminder</h1>

        <!-- SHOW EMAIL BUTTON -->
        <div class="row" style="margin-bottom: 30px; margin-top: auto;">
            <br>
            <button class="btn" (click)="show_email = true" style="padding: 0px; margin: 0px; margin-left: 13px; background: none; color: black;" *ngIf="!show_email">View/Edit Email Text</button>
            <button class="btn" (click)="show_email = false" style="padding: 0px; margin: 0px; margin-left: 0px; background: none; color: black;" *ngIf="show_email">Hide Email Text</button>
        </div>

        <!-- email text -->
        <div class="row" *ngIf="show_email" style="width: 100%; margin-left: 0px; margin-bottom: 30px;">
            <!-- <div class="col-sm-10">

            </div>
            <div class="col-sm-2">

            </div> -->
            <p><strong>Subject: </strong>{{ subject }}</p>
            <p><strong>Message: </strong></p>
            <textarea [(ngModel)]="reminder_message" class="textarea"></textarea>

            <button (click)="saveMessage()" class="btn update-btn">Save Email</button>
            <div *ngIf="savedMessage">Saved!</div>
        </div>

         <!-- BIG headings buttons -->
         <div class="row email-all">
             <div class="col-sm-12">


                <!-- confirm send all -->
                <div class="row confirm" *ngIf="confirm_email">
                    <div class="col-sm-10" style="padding-top: 10px;">
                        <p>You are about to send a reminder email to every user in the school who has not yet filled out the screener today. Do you wish to proceed?</p>
                    </div>
                    <div class="col-sm-2">
                        <button (click)="confirm_email = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px; color: black;">Cancel</button>
                        <button (click)="emailUncompleted()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                    </div>
                </div>
                <!-- send all alerts/error messages -->
                <div class="row success" style="background: #348C80; color: white;" *ngIf="email_sent && !email_error">
                    The emails have successfully been sent. They will be delivered in 1-2 minutes.</div>
                <div class="row alert alert-danger" *ngIf="email_error">Error! Something went wrong.</div>
                <div class="row alert alert-danger" *ngIf="no_emails">No emails sent: all users have completed the daily screener.</div>
    
                <br>

                <!-- SELECT USERS BUTTON -->
                <div class="row">
                    <p class="h3" style="margin-right: 593px; margin-left: 15px;">Select Uncompleted Users to Remind</p>
                    <button class="btn download-btn" (click)="manualSelect = true && loadUncompleted()" *ngIf="!email_loading &&!manualSelect" style="background: #ff6b41;" [disabled]="manualSelect"
                    >View Users</button>
                    <button class="btn download-btn" (click)="manualSelect = false" *ngIf="manualSelect" style="background: #ff6b41;"
                    >Hide Users</button>
                </div>

                <br>
             </div> 
        </div>

        


        <div class="search-section" *ngIf="manualSelect">
            <hr>
            <div class="row">
                <div class="col-sm-10">
                    <label>Search by Name:</label>
                    <input type="text" [(ngModel)]="searchName">
                    <img src="../../assets/black_x.png" width="20px" alt="clear search" (click)="clearSearch()" >
                </div>
                <div class="col-sm-2" style="text-align: right;">
                    <button class="btn" style="background: #348C80; margin-top: 30px;" [disabled]="!searchName" (click)="userGroup='name'">Search</button>
                </div>  
            </div>

            <!-- <div class="row">
                <div class="col-sm-10">
                    <label>Search by Email:</label>
                    <input type="text" [(ngModel)]="searchEmail">
                </div>
                <div class="col-sm-2">
                    <button class="btn search-btn" [disabled]="!searchEmail" (click)="searchUsersByEmail()">Search</button>
                </div>  
            </div> -->

            <!-- <div class="row">
                <div class="col-sm-2">
                    <button class="btn clear-btn" (click)="clearSearch()" style="background: none; padding: 0px; margin:0px; color: black;">Clear Search</button>
                </div>
            </div> -->
            <br>

        </div>


        <!-- SEND BUTTON -->
        <div *ngIf="manualSelect">

            <div class="send-button" style="text-align: center;" >
                <hr><br>
                <button class="btn" (click)="confirm_email_selected = true" *ngIf="loading==false" [disabled]="!email_enabled" style="background: #ff6b41;">Send Reminder</button>
            </div>
    
            <div class="row confirm" *ngIf="confirm_email_selected">
                <div class="col-sm-10" style="padding-top: 10px;">
                    <p>You are about to send a reminder email to all of the selected users. Do you wish to proceed?</p>
                </div>
                <div class="col-sm-2">
                    <button (click)="confirm_email_selected = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px; color: black;">Cancel</button>
                    <button (click)="emailSelected()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                </div>
            </div>
            <div class="row success" style="background: #348C80; color: white;" *ngIf="email_sent_selected && !email_error_selected">
                The emails have successfully been sent. They will be delivered in 1-2 minutes.
            </div>
            <div class="row alert alert-danger" *ngIf="email_error_selected">Error! Something went wrong.</div>
            <div class="row alert alert-danger" *ngIf="no_emails_selected">No emails sent: please select users to email.</div>    

        </div>
        


        <!-- SELECTED -->
        <div class="users-section" *ngIf="manualSelect && userGroup == 'all'">
            <!-- all users -->
            <div class="user-list">
                <div *ngFor="let user of uncompleted; index as i;">
                    <div class="row">
                        <div class="col-sm-9">
                            <h3>{{user.name}}</h3>
                            <div *ngFor="let email of user['emails']">
                                <p>{{email}}</p>
                            </div>
                        </div>
                        <div class="col-sm-3" style="text-align: right;">
                            <button class="btn" *ngIf="uncompleted_selected[i]" (click)="uncompleted_selected[i]=false" style="background: #348C80; margin-top: 30px;">
                                Deselect</button>
                            <button class="btn" *ngIf="!uncompleted_selected[i]" (click)="uncompleted_selected[i]=true" style="color: black; margin-top: 30px;">
                                Select</button>
                        </div>
                    </div>
                    <br>
                </div>
                <!-- if no matching users -->
                <div *ngIf="uncompleted.length == 0">
                    <p *ngIf="loading==false">All users have completed the daily screener.</p>
                    <p *ngIf="loading==true">Loading uncompleted users...</p>
                </div>
            </div>

            <!-- SEND BUTTON -->
            <!-- <div class="send-button" style="text-align: center;">
                <hr><br>
                <button class="btn" (click)="confirm_email_selected = true" *ngIf="loading==false" [disabled]="!email_enabled" style="background: #ff6b41;">Send Email</button>
            </div>

            <div class="row confirm" *ngIf="confirm_email_selected">
                <div class="col-sm-10" style="padding-top: 10px;">
                    <p>You are about to send a reminder email to all of the selected users. Do you wish to proceed?</p>
                </div>
                <div class="col-sm-2">
                    <button (click)="confirm_email_selected = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px; color: black;">Cancel</button>
                    <button (click)="emailSelected()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                </div>
            </div>
            <div class="row success" style="background: #348C80; color: white;" *ngIf="email_sent_selected && !email_error_selected">
                The emails have successfully been sent. They will be delivered in 1-2 minutes.
            </div>
            <div class="row alert alert-danger" *ngIf="email_error_selected">Error! Something went wrong.</div>
            <div class="row alert alert-danger" *ngIf="no_emails_selected">No emails sent: please select users to email.</div> -->

        </div>



        <!-- SELECTED SEARCH -->
        <div class="users-section" *ngIf="manualSelect && userGroup == 'name'">
            <!-- all users -->
            <div class="user-list">
                <hr>
                <div *ngFor="let user of uncompleted; index as i;">
                    <div *ngIf="found(user.name)">
                        <div class="row">
                            <div class="col-sm-9">
                                <h3>{{user.name}}</h3>
                                <p>{{user.email}}</p>
                            </div>
                            <div class="col-sm-3" style="text-align: right;">
                                <button class="btn" *ngIf="uncompleted_selected[i]" (click)="uncompleted_selected[i]=false" style="background: #348C80; margin-top: 30px;">
                                    Deselect</button>
                                <button class="btn" *ngIf="!uncompleted_selected[i]" (click)="uncompleted_selected[i]=true" style="color: black; margin-top: 30px;">
                                    Select</button>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <!-- if no matching users -->
                <!-- <div *ngIf="nameNotFound==true">
                    <p>No matching users.</p>
                </div> -->
            </div>

        </div>


    </div>
</body>