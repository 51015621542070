<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">
        <h1>Manage Admins</h1>

        <div class="search-section">
            <div class="search">
                <label>Search by Name:</label>
                <input type="text" [(ngModel)]="searchName">
                <button class="btn search-btn" [disabled]="!searchName" (click)="searchUsersByName()">Search</button>
            </div>
        </div>
        <div class="search-section">
            <div class="search">
                <label>View Faculty Type:</label>
                <select [(ngModel)]="userGroup">
                    <option>All Faculty/Staff</option>
                    <option>Admins</option>
                    <option>Super Admins</option>
                </select>
            </div>
            <button class="btn clear-btn" (click)="clearSearch()">Clear Search</button>
            <br>
        </div>
        
        <div class="users-section">
            <!-- all users -->
            <div *ngIf="userGroup == 'All Faculty/Staff'" class="user-list">
                <div *ngFor="let user of allUsers; index as i;">
                    <div class="row">
                        <div class="col-sm-5">
                            <div *ngIf="allUsers[i].power == 'regular' " class="nonadmin-title">
                                <h3>{{user.name}}</h3>
                                <p>{{user.email}} | {{ user.type }} </p>
                                <p>Not an Admin</p>
                            </div>
                            <div *ngIf="allUsers[i].power == 'admin' " class="admin-title">
                                <h3>{{user.name}}</h3>
                                <p>{{user.email}} | {{ user.type }}</p>
                                <p>Admin Status</p>
                            </div>
                            <div *ngIf="allUsers[i].power == 'superadmin' " class="superadmin-title">
                                <h3>{{user.name}} </h3>
                                <p>{{user.email}} | {{ user.type }}</p>
                                <p>Super Admin Status</p>
                            </div>
                            
                        </div>
                        <div class="col-sm-3">
                            <button *ngIf="allUsers[i].power != 'superadmin'" class="btn super-btn" (click)="makeSuperAdmin(allUsers[i])"
                            >Make Super Admin</button>
                            <button *ngIf="allUsers[i].power == 'superadmin'" class="btn super-remove-btn" (click)="removeSuperAdmin(allUsers[i])"
                            >Remove Super Admin</button>

                            <button *ngIf="allUsers[i].power == 'regular'" class="btn update-btn" (click)="makeAdmin(allUsers[i])"
                            >Make Admin</button>
                            <button *ngIf="allUsers[i].power == 'admin' || allUsers[i].power == 'superadmin' " class="btn delete-btn" (click)="removeAdmin(allUsers[i])"
                            >Remove Admin</button>
                        </div>
                    </div>
                    <br>
                </div>
                <!-- if no matching users -->
                <div *ngIf="allUsers.length == 0">
                    <p>No registered users.</p>
                </div>
            </div>

            <!-- admin users -->
            <div class="users-section">
                <!-- all users -->
                <div *ngIf="userGroup == 'Admins'" class="user-list">
                    <div *ngFor="let user of adminUsers">
                        <div class="row">
                            <div class="col-sm-5">
                                <div *ngIf="allUsers[user].power == 'regular' " class="nonadmin-title">
                                    <h3>{{allUsers[user].name}}</h3>
                                    <p>{{allUsers[user].email}} | {{ allUsers[user].type }} </p>
                                    <p>Not an Admin</p>
                                </div>
                                <div *ngIf="allUsers[user].power == 'admin' " class="admin-title">
                                    <h3>{{allUsers[user].name}}</h3>
                                    <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                    <p>Admin Status</p>
                                </div>
                                <div *ngIf="allUsers[user].power == 'superadmin' " class="superadmin-title">
                                    <h3>{{allUsers[user].name}} </h3>
                                    <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                    <p>Super Admin Status</p>
                                </div>
                                
                            </div>
                            <div class="col-sm-3">
                                <button *ngIf="allUsers[user].power != 'superadmin'" class="btn super-btn" (click)="makeSuperAdmin(allUsers[user])"
                                >Make Super Admin</button>
                                <button *ngIf="allUsers[user].power == 'superadmin'" class="btn super-remove-btn" (click)="removeSuperAdmin(allUsers[user])"
                                >Remove Super Admin</button>

                                <button *ngIf="allUsers[user].power == 'regular'" class="btn update-btn" (click)="makeAdmin(allUsers[user])"
                                >Make Admin</button>
                                <button *ngIf="allUsers[user].power == 'admin' || allUsers[user].power == 'superadmin' " class="btn delete-btn" (click)="removeAdmin(allUsers[user])"
                                >Remove Admin</button>
                            </div>
                        </div>
                        <br>
                    </div>
                    <!-- if no matching users -->
                    <div *ngIf="adminUsers.length == 0">
                        <p>No registered admins.</p>
                    </div>
                </div>
            </div>

            <!-- name users -->
            <div *ngIf="userGroup == 'name'" class="user-list">
                <div *ngFor="let user of nameUsers">
                    <div class="row">
                        <div class="col-sm-5">
                            <div *ngIf="allUsers[user].power == 'regular'" class="nonadmin-title">
                                <h3>{{allUsers[user].name}}</h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }} </p>
                                <p>Not an Admin</p>
                            </div>
                            <div *ngIf="allUsers[user].power == 'admin'" class="admin-title">
                                <h3>{{allUsers[user].name}}</h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                <p>Admin Status</p>
                            </div>
                            <div *ngIf="allUsers[user].power == 'superadmin' " class="superadmin-title">
                                <h3>{{allUsers[user].name}} </h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                <p>Super Admin Status</p>
                            </div>
                            
                        </div>
                        <div class="col-sm-3">
                            <button *ngIf="allUsers[user].power != 'superadmin'" class="btn super-btn" (click)="makeSuperAdmin(allUsers[user])"
                            >Make Super Admin</button>
                            <button *ngIf="allUsers[user].power == 'superadmin'" class="btn super-remove-btn" (click)="removeSuperAdmin(allUsers[user])"
                            >Remove Super Admin</button>

                            <button *ngIf="allUsers[user].power == 'regular'" class="btn update-btn" (click)="makeAdmin(allUsers[user])"
                            >Make Admin</button>
                            <button *ngIf="allUsers[user].power == 'admin' || allUsers[user].power == 'superadmin' " class="btn delete-btn" (click)="removeAdmin(allUsers[user])"
                            >Remove Admin</button>
                        </div>
                    </div>
                    <br>
                </div>
                <!-- if no matching users -->
                <div *ngIf="nameUsers.length == 0">
                    <p>No users matching name '{{ nameNotFound }}'.</p>
                </div>
            </div>

            <!-- super admin users -->
            <div *ngIf="userGroup == 'Super Admins'" class="user-list">
                <div *ngFor="let user of superAdminUsers">
                    <div class="row">
                        <div class="col-sm-5">
                            <div *ngIf="allUsers[user].power == 'regular' " class="nonadmin-title">
                                <h3>{{allUsers[user].name}}</h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }} </p>
                                <p>Not an Admin</p>
                            </div>
                            <div *ngIf="allUsers[user].power == 'admin' " class="admin-title">
                                <h3>{{allUsers[user].name}}</h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                <p>Admin Status</p>
                            </div>
                            <div *ngIf="allUsers[user].power == 'superadmin' " class="superadmin-title">
                                <h3>{{allUsers[user].name}} </h3>
                                <p>{{allUsers[user].email}} | {{ allUsers[user].type }}</p>
                                <p>Super Admin Status</p>
                            </div>
                            
                        </div>
                        <div class="col-sm-3">
                            <button *ngIf="allUsers[user].power != 'superadmin'" class="btn super-btn" (click)="makeSuperAdmin(allUsers[user])"
                            >Make Super Admin</button>
                            <button *ngIf="allUsers[user].power == 'superadmin'" class="btn super-remove-btn" (click)="removeSuperAdmin(allUsers[user])"
                            >Remove Super Admin</button>

                            <button *ngIf="allUsers[user].power == 'regular'" class="btn update-btn" (click)="makeAdmin(allUsers[user])"
                            >Make Admin</button>
                            <button *ngIf="allUsers[user].power == 'admin' || allUsers[user].power == 'superadmin' " class="btn delete-btn" (click)="removeAdmin(allUsers[user])"
                            >Remove Admin</button>
                        </div>
                    </div>
                    <br>
                </div>
                <!-- if no matching users -->
                <div *ngIf="superAdminUsers.length == 0">
                    <p>No registered superadmins.</p>
                </div>
            </div>

        </div>

    </div>
</body>