<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">
        
        <!-- <div class="progress-bar">
            <img src="../../../assets/step3of3.png" alt="step 1 of 3 progress bar" class="progress-bar-img">
        </div> -->

        <hr>
        <div class="row">
            <div class="col-lg-12">
                <h2 style="text-align: center;">Next Steps</h2>
                <h5 style="text-align: center;"><strong>Congratulations! Your school's information has been all set up. You are now ready to access the SWS mobile app and Web Admin Console.</strong></h5>
            </div>
            
        </div>
        
        <div class="row">
            <div class="col-lg-12">
                <h3>Log In to the SWS App</h3>
                <p>1. Search 'School Wellness Screener' on the App Store</p>
                <p>2. Download the app</p>
                <p>3. Open the app and click 'I already have an account'</p>
                <p>4. Enter the email and password you registered on the previous page</p>
                <p>5. Start using SWS to keep your community safe.</p>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-12">
                <h3>Log In to Web Admin Console</h3>
                <p>Superadmin accounts can log in to the Web Console to view graphs of current data, access screener/attandance information, send reminders, update questions/app display, manage users, and more.</p> 
                <p>Log in <a routerLink="../../login" target="_blank">here,</a> or simply click the button at the bottom of this page.</p>
                <p>Your login credentials for the Web Admin Console are the same as those for the mobile app: the email and password registered on the previous page.</p>
                <p>If you forget your password, you can always <a routerLink='../../reset-pass' target="_blank">reset it.</a></p>
                
                <br>

                <p>To learn more about how to use the admin side of the app and the Web Admin Console, please watch the following videos:</p>
                
                <br>

                <div class="row">
                    <div class="col-lg-6">
                        <!-- app admin walthrough -->
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ipHxw1Yjz4g" frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                        </iframe>
                    </div>
                    <div class="col-lg-6">
                        <!-- web admin walkthrough -->
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/NGT7VimY2hU" frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                        </iframe>
                    </div>
                </div>  

            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-lg-12">
                <h3>Instructions for Student/Parent/Faculty Registration:</h3>
                <p>1. Search 'School Wellness Screener' on the App Store</p>
                <p>2. Download the app</p>
                <p>3. Open the app and enter your school ID: <strong>{{ schoolID }}</strong></p>
                <p>5. Enter the prompted information and complete registration</p>
                <p>6. Take the daily screener by pressing the green button that says 'Take Screener', and selecting the proper user from the account</p>
                <p>7. Access your personal QR code for scanning into the building by pressing the gray button that says 'See QR code', and selecting the proper user from the account.</p>
                <p>8. Give another parent or guardian access to the account by pressing 'Settings', then 'Share with Family'.</p>
                <br>
                <p><em>Feel free to use the above text in an email to your school's students, parents, and faculty to help them get signed up smoothly.</em></p>
            </div>
        </div>

        <hr><br>

        <div class="row">
            <div class="col-lg-12">
                <p><strong>Thank you for choosing SWS!</strong></p>
                <p>We strive to provide the best customer service and welcome any questions you might have. Contact <a style="text-decoration: underline;">help@visionappsproducts.com</a> to get in touch with the team.</p>
            </div>
        </div>
        
        <!-- next button -->
        <div class="row">
            <div class="col-lg-12">
                <button class="btn update-btn" style="width: 100%; margin-top: 80px; margin-bottom: 0px;"
                (click)=done()
                >Go to Web Console</button>
            </div>
        </div>

    </div>
</body>
</html>