<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="newContainer">
        <!-- <div class="row">
            <h1>Update Questions</h1>
        </div> -->

        <!-- view and/or edit current questions -->
        <div class="row" *ngFor="let question of questionsArray; index as i;">

            <!-- question -->
            <div class="row update">
                <div class="col-sm-10">
                    <h3>Question {{i+1}}</h3>
                    <p>{{question}}</p>
                    <label>Update Question:</label><br>
                    <textarea class="textFieldInput" [(ngModel)]="updatedArray[i]"></textarea>                                   
                </div>
                <div class="col-sm-2">
                    <!-- <div class="col-sm-1"> -->
                        <!-- <button class="btn delete-btn" (click)=deleteQuestion(i+1)>Delete</button> -->
                    <!-- </div> --> 
                    <button class="btn delete-btn" (click)="confirm_delete[i] = true"
                    >Delete</button>
                    <button class="btn update-btn" (click)="updateQuestion(i+1, updatedArray[i])" [disabled]=!updatedArray[i]
                    >Update</button>
                </div>
            </div>

             <!-- confirm -->
            <div class="row confirm" *ngIf="confirm_delete[i]">
                <div class="col-sm-10" style="padding-top: 13px; padding-bottom: auto;">
                    <p>You are about to delete Question {{ i + 1 }}. Do you wish to proceed?</p>
                </div>
                <div class="col-sm-2">
                    <button (click)="confirm_delete[i] = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px;">Cancel</button>
                    <button (click)="deleteQuestion(i+1)" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                </div>
            </div>
            
        </div>

        <br><hr>

        <!-- add new question -->
        <div class="row update">
            <div class="col-sm-10">
                <h3>Add Question</h3>
                <textarea class="textFieldInput" [(ngModel)]="newQ"></textarea>
            </div>
            <div class="col-sm-2">
                <button class="btn update-btn" style="margin-top: 60px;" (click)="newQuestion(newQ)" [disabled]=!newQ
                >Submit</button>
            </div>
        </div>
    </div>
</body>