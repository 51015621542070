<html lang="en">
<head>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>

    <div class="secondaryContainer">
            
        <div class="row">

        <div class="col-sm-12">
            <h3 class="title">Login</h3>
            <p class="desc">Please login to the SWS account that you 
            currently use on the mobile application.
        
            </p>
           <!-- <p><strong>Current: </strong>{{ curr_name }}</p>
            -->


            <form #loginForm="ngForm" (ngSubmit)="tryLogin()">

      <div>
        <input type="text" class="textFieldInput" required email [(ngModel)]="email" 
        name="loginEmail" #loginEmail="ngModel" id="loginEmail" placeholder="Email Address">
    
        <div class="alert alert-danger" *ngIf="loginEmail.touched && !loginEmail.valid">
          <div *ngIf="loginEmail.errors.required">Email address entry is required</div>
          <div *ngIf="loginEmail.errors.email">Email address is not valid</div>
      </div>
    
      </div>

          <div>
            <input 
            required password [(ngModel)]="password"
            name="loginPassword" #loginPassword="ngModel"
            id="loginPassword" type="password" class="textFieldInput" placeholder="Password">
            <div class="alert alert-danger" *ngIf="loginPassword.touched && !loginPassword.valid">
              <div *ngIf="loginPassword.errors.required">A password is required</div>
          </div>
       
          </div>

          <div class="reset">Don't remember your Click <a routerLink="../reset-pass">here</a> to send a password reset email.</div>


  <!-- ERRORS -->
  <div class="form-group">
    <div class="alert alert-danger" *ngIf="loginError">  Login credentials are incorrect.</div>
</div>


<button class="btn back-btn"> <a style="color: #525252;" (click)="goBack()">Go Back</a></button>
<button class="btn update-btn"  type="submit" [disabled]="!loginForm.form.valid" (click)="tryLogin()"> Continue</button>

        </form>

        

</div>
</div>
</div>


        
</body>
</html>