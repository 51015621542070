<html lang="en">
<head>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>

    <div class="secondaryContainer">
            
        <div class="row">
            <div class="col-sm-12">
                <h3 class="title">Choose Account Type</h3>
                <p class="desc">
                    Welcome to the SWS COVID-19 vaccine attestation upload.
                    Please read the provided instructions on each page carefully. 
                    This proccess should only take 5-8 minutes. 
                </div>
            </div>

            <button class="btn back-btn" (click)="login()"> I already have an SWS account</button>
            <button class="btn update-btn" (click)="createAccount()"> I do not have an SWS account</button>

        </div>


        
</body>
</html>