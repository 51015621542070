import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './../../auth.service';
import { Router } from '@angular/router';
import { AngularfireService } from './../../angularfire.service';
import { Component, OnInit } from '@angular/core';
import { auth } from  'firebase';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  schoolID: string;

  loginEmail: string;
  loginPassword: string;
  loginPasswordConfirm: string;  
  emailContent: "Hey!";

  userName: string;
  userID: string;

  passMatchError = false;
  emailFormatError = false;
  passStrengthError = false;

  constructor(
    private fireservice: AngularfireService,
    private fireauth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.schoolID = localStorage.getItem('schoolID')
  }

  back(){
    this.router.navigateByUrl('setup/setup-questions')
  }

  next(){
    this.createUser()
  }

  createUser(){
    this.clearErrors()

    // check inputs
    if(this.isValidEmail(this.loginEmail)){
      if(this.loginPassword.length >= 6){
        if(this.loginPassword == this.loginPasswordConfirm){

          // create authentication and log in
          this.createFirebaseUser(this.loginEmail, this.loginPassword).then(() => {
            this.fireauth.signInWithEmailAndPassword(this.loginEmail, this.loginPassword) .then(()=> { 
  
              // retrieve auth state to get UID
              this.fireauth.authState.pipe(first()).subscribe(user => {
                let UID = user.uid
                this.userID = this.fireservice.generateID()
                let umbrella = this.fireservice.generateID()

                // create accounts doc
                this.fireservice.createAccountDoc(UID, "superadmin", this.loginEmail, this.userName, this.schoolID, umbrella, this.userID)
                  .catch(error => console.log(error))

                // create users/all doc
                this.fireservice.createUsersAllDoc(this.schoolID, this.userID, this.userName, this.loginEmail, "No Grade", "superadmin", "Other", umbrella, "No current comments.", "n/a", "OK")
                  .catch(error => console.log(error))

                // create users/toLogin doc
                this.fireservice.createUsersToLoginDoc(this.schoolID, UID, this.loginEmail, "superadmin", umbrella)
                  .catch(error => console.log(error))

                // create users/umbrellas doc
                this.fireservice.createUsersUmbrellaDoc(this.schoolID, "No Grade", this.userID, this.userName, umbrella)
                  .catch(error => console.log(error))

                // set schoolID
                localStorage.setItem('schoolID', this.schoolID)

                this.fireservice.sendAccountConfirmationEmail(this.loginEmail, this.schoolID, 'SWS Account Confirmation', this.emailContent)

                // to DONE page
                this.router.navigateByUrl('setup/setup-done')
              })

            }).catch(error => console.log(error))
          }).catch(error => console.log(error))

        } else { this.passMatchError = true; }
      } else { this.passStrengthError = true; }
    } else { this.emailFormatError = true; }
  }

  clearErrors() {
    this.passMatchError = false;
    this.emailFormatError = false;
    this.passStrengthError = false;
  }


  //Obviously this need to be changed
  isValidEmail(test:string): boolean{
    return test.includes('@') && 
    (test.endsWith('.edu') || test.endsWith('.com') || test.endsWith('.org') || test.endsWith('.net') || test.endsWith('.co') || test.endsWith('.us'))
  }

  // FIREAUTH METHODS
  createFirebaseUser(email:string, password: string){
    return this.fireauth.createUserWithEmailAndPassword(email, password);
  }

  createUserAndLogIn(email:string, password: string){
    return this.createFirebaseUser(email, password).then(async () => {
      await this.fireauth.setPersistence(auth.Auth.Persistence.SESSION)
      this.fireauth.signInWithEmailAndPassword(email, password).catch(error => console.log(error))
    }).catch(error => console.log(error))
  }

}
