import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  schoolID:string

  constructor(
    private router: Router
  ) { }


  //CHANGE BACK
  ngOnInit(): void {
    this.schoolID = localStorage.getItem('schoolID')
    if(!this.schoolID){
   //   this.router.navigateByUrl('register')
    } else {
   //   this.router.navigateByUrl('setup/setup-info')
    }
  }

}
