<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">
        
        <div class="progress-bar">
            <img src="../../../assets/step3of3.png" alt="step 3 of 3 progress bar" class="progress-bar-img">
        </div>

        <hr>
        <div class="row">
            <div class="col-lg-12">
                <h2 style="text-align: center;">Create Admin Account</h2>
                <!-- <h5 style="text-align: center;"><strong>Congratulations! Your school's information has been all set up. You are now ready to access the SWS mobile app and web admin console.</strong></h5> -->
            </div>
            
        </div>
        

        <div class="row">
            <div class="col-lg-12">
                <h3>Create Login</h3>
                <label for="name">Full Name</label> <br>
                <input name="name" type="text" [(ngModel)]="userName"> 
                <br>
                <label for="email">Email</label> <br>
                <input name="email" type="text" [(ngModel)]="loginEmail"> 
                <br>
                <label for="password">Password</label> <br>
                <input name="password" type="password" [(ngModel)]="loginPassword">
                <br>
                <label for="passwordConfirm">Confirm Password</label> <br>
                <input name="passwordConfirm" type="password" [(ngModel)]="loginPasswordConfirm">

                <!-- ERRORS -->
                <div class="alert alert-danger" *ngIf="passMatchError">Please ensure the inputted passwords match.</div>
                <div class="alert alert-danger" *ngIf="passStrengthError">Please use a password with at least 6 characters.</div>
                <div class="alert alert-danger" *ngIf="emailFormatError">Please ensure the inputted email is a valid email address.</div>
            </div>
        </div>
        

        
        <!-- next button -->
        <div class="row">
            <div class="col-sm-6">
                <button class="btn" style="width: 100%; margin-top: 80px; margin-bottom: 0px;"
                (click)=back()
                >Back</button>
            </div>
            <div class="col-sm-6">
                <button class="btn update-btn" style="width: 100%; margin-top: 80px; margin-bottom: 0px;"
                (click)=next() [disabled]="!loginEmail || !loginPassword || !loginPasswordConfirm || !userName"
                >Next</button>
            </div>
        </div>

    </div>
</body>
</html>