import { AngularfireService } from './../angularfire.service';
import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { pipe, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  // login credentials
  email: string;
  password: string;

  // error logging in
  loginCredError = false;
  loginAdminError = false;
  loginSuperAdminError = false;
 
  // subscriptions
  authStateSub: Subscription;

  constructor(
    private authservice: AuthService,
    private router: Router,
    private fireservice: AngularfireService
  ) { }

  ngOnInit(): void {
    this.authStateSub = this.authservice.authState.pipe(first()).subscribe(user => {if(user) this.router.navigate(['admin']) })
  }

  ngOnDestroy(): void {
    if(this.authStateSub) this.authStateSub.unsubscribe()
  }

  manageAuthState() {
    this.authStateSub = this.authservice.authState.pipe(first()).subscribe((user) => {
      // check if a user correctly logged in
      if(user){
        // check if the logged in user is an admin
        this.fireservice.getUserDetails(user).pipe(first()).subscribe(userDetails => {
          // if an account exists for this 
          if(userDetails){
            if(userDetails["account"] == "superadmin") { 
              this.loginAdminError = false;
              this.authservice.setSchoolID(userDetails["school"])
              this.router.navigate(['admin']); 
            } else if(userDetails["account"] == "admin"){
              this.loginSuperAdminError = true;
              this.authservice.logout();
            } else {
              this.loginAdminError = true;
              this.authservice.logout();
            }
          }
          else {
            this.loginCredError = true;
          }

          // ------ NEW ADMIN LOGIN FUNCTIONALITY---- NOW OBSOLETE ----- //
          
          // // school and user details
          // var schoolPIN = userDetails["school"] 
          // var peopleObject: Object = userDetails["people"] as Object
          // console.log(schoolPIN)
          // console.log(peopleObject)

          // let people = Object.keys(peopleObject)
          // console.log(people)

          // // check if any people in account are admins
          // people.forEach((person, index, people) => {
          //   console.log(person)
          //   this.fireservice.getUserFromID(person, schoolPIN).pipe(first()).subscribe(userInfo => {
          //     console.log(userInfo)
          //       if(userInfo["power"] == "admin" || userInfo["power"] == "superadmin") {  /// DELETE "ADMIN" TEST TO MIGRATE TO NEW SYSTEM
          //         console.log(userInfo["power"])
          //         this.loginAdminError = false;
          //         this.authservice.setSchoolID(schoolPIN)
          //         this.router.navigate(['admin']); 
          //       } 
          //       else if(userInfo["power"] == "admin"){
          //         console.log("not a super admin")
          //         this.loginSuperAdminError = true;
          //         this.authservice.logout();
          //       }
          //       else {
          //         console.log("not an admin")
          //         this.loginAdminError = true;
          //         this.authservice.logout();
          //       }
          //   })
          // })

        })
      }
    })
  }

  tryLogin() {
    this.clearErrors()
    this.authservice.login(this.email, this.password)
      .then( () => this.manageAuthState() )
      .catch(error => {
        console.log(error);
        this.loginCredError = true;
          this.authservice.logout().then(() => console.log("error --> logged out"))
      })
  }

  clearErrors(){
    this.loginCredError = false
    this.loginSuperAdminError = false
    this.loginAdminError = false
  }

}
