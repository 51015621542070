import { first } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
    private authservice: AuthService,
    private router: Router
  ) { }

  // the Router call canActivate() method,
  // if canActivate is registered in Routes[]
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // here we check if user is logged in or not
    // the authService returs user object, or
    // it returns undefined/null when user is not logged in
    
    return new Promise((resolve, reject) => {

        // here we check if user is logged in or not
        // the authService returs user object, or
        // it returns undefined/null when user is not logged in

        this.authservice.isLoggedInDetails().pipe(first()).subscribe(details => {
          if(details){
            if(details["account"] != "superadmin" ){
              // just return false - if user is not logged in
              this.router.navigate(['login'])
              this.authservice.logout()
              return resolve(false);
            } else {
              // just return true - if user is logged in
              return resolve(true);
            }
          } else {
            this.authservice.logout()
            this.router.navigate(['login']);
            return resolve(false);
          }
        })

    });
  }

}
