import { first } from 'rxjs/operators';
import { Student } from './../objects/Student';
import { Subscription } from 'rxjs';
import { AngularfireService } from './../angularfire.service';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-manage-admins',
  templateUrl: './manage-admins.component.html',
  styleUrls: ['./manage-admins.component.scss']
})
export class ManageAdminsComponent implements OnInit, OnDestroy {

  schoolID: string;
  grades: number[] = [1,2,3,4,5,6,7,8,9,10,11,12]

  // subscriptions
  accountsSub: Subscription
  schoolSub: Subscription

  // user group to show
  userGroup = "All Faculty/Staff"        // 'All Faculty/Staff', 'name', or 'grade' depending on which version to show
  allUsers: Student[] = []
  nameUsers: number[] = []
  adminUsers: number[] = []
  superAdminUsers: number[] = []

  // search variable models
  searchName: string
  nameNotFound: string;
  adminsPresent: boolean = true


  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService
  ) { }

  ngOnInit(): void {
    this.schoolSub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID
      this.populateSchoolTeachers()
    })
  }

  ngOnDestroy(): void {
    if(this.accountsSub) this.accountsSub.unsubscribe()
    if(this.schoolSub) this.schoolSub.unsubscribe()
  }

  populateSchoolTeachers(){
    this.accountsSub = this.fireservice.getSchoolTeachers(this.schoolID).subscribe(docs => {
      this.allUsers = []
      this.adminUsers = []
      this.superAdminUsers = []
      docs.forEach((doc, index, allDocs) => {
        let name = doc["name"]
        let email = doc["emails"][0]
        let grade = doc["grade"]
        let power = doc["power"]
        let type = doc["type"]
        let id = doc["id"]
        let umbrella = doc["umbrella"]
        let comments = doc["comments"]
        this.allUsers.push(new Student(name, email, grade, power, type, id, umbrella, comments))
        if(doc["power"] == "admin"){
          this.adminUsers.push(index)
        }
        if(doc["power"] == "superadmin"){
          this.superAdminUsers.push(index)
        }
      })
    })
  }

  searchUsersByName(): void {
    this.nameUsers = []
    for(let i = 0; i< this.allUsers.length; i++){
      if(this.allUsers[i].name.toLowerCase().indexOf(this.searchName.toLowerCase()) != -1 || 
         this.searchName.toLowerCase().indexOf(this.allUsers[i].name.toLowerCase()) != -1 ){
        this.nameUsers.push(i)
      }
    }
    if(this.nameUsers.length == 0){
      this.nameNotFound = this.searchName
    }
    this.userGroup = "name"
  }

  // displayOnlyAdmins(): void {
  //   this.adminUsers = []
  //   this.adminsPresent = true
  //   for(let i = 0; i<this.allUsers.length; i++){
  //     if(this.allUsers[i].power == "admin" ){
  //       this.adminUsers.push(i)
  //     }
  //   }
  //   if(this.adminUsers.length == 0){
  //     this.adminsPresent = false
  //   }
  //   this.userGroup = "admin"
  // }

  clearSearch(): void {
    this.userGroup = "All Faculty/Staff"
    this.searchName = ""
  }

  makeAdmin(user: Student){
    console.log(user)
    this.fireservice.makeAdminUsersAll(this.schoolID, user.id).catch(error => console.log(error)).then(() => console.log("updated successfully"))
    this.fireservice.makeAdminGlobal(user.email, this.schoolID).catch(error => console.log(error)).then(() => console.log("updated successfully"))
  }

  makeSuperAdmin(user: Student){
    console.log(user)
    this.fireservice.makeSuperAdminUsersAll(this.schoolID, user.id).catch(error => console.log(error)).then(() => console.log("updated successfully"))
    this.fireservice.makeSuperAdminGlobal(user.email, this.schoolID).catch(error => console.log(error)).then(() => console.log("updated successfully"))
  }

  removeAdmin(user: Student){
    console.log(user)
    this.fireservice.removeAdminUsersAll(this.schoolID, user.id).catch(error => console.log(error)).then(() => console.log("updated successfully"))
    this.fireservice.removeAdminGlobal(user.email, this.schoolID).catch(error => console.log(error)).then(() => console.log("updated successfully"))
  }

  removeSuperAdmin(user: Student){
    console.log(user)
    this.fireservice.removeSuperAdminUsersAll(this.schoolID, user.id).catch(error => console.log(error)).then(() => console.log("updated successfully"))
    this.fireservice.removeSuperAdminGlobal(user.email, this.schoolID).catch(error => console.log(error)).then(() => console.log("updated successfully"))
  }

}
