<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>
    <div class="container">
        <!-- DOWNLOAD SECTION -->
    <div class="download">
        <!-- <div class="row">
            <h1>Download Data</h1>
            <p class="subtitle">Downloading and selection format is Month/Date/Year, e.g (8/1/2020)</p>
        </div> -->
        
        <!-- <div class="live-stats">
            <div class="row">
                <div class="col-lg-4">
                    <div class="stat-group">
                        <p>Total Users</p>
                        <p class="stat-num">120</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="stat-group">
                        <p>Percent Completed</p>
                        <p class="stat-num">50%</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="stat-group">
                        <p>Issues Logged</p>
                        <p class="stat-num">4</p>
                    </div>
                </div>
            </div>
        </div> -->

        <br>

        <div class="row">
            <!-- <p class="subtitle" style="margin-left: 0px; padding: 0px;">For questions or concerns regarding duplicate users, downloading formats and session time-outs, please go to our FAQ section
            
            
                <a href="hello" class="rightArrow">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                      </svg>
                
                    </a>
            </p> -->
        </div>

        <br>

        <!-- download user account data -->
        <div class="download-section">
            <div class="row">
                <button class="downloadAllUsers" (click)="downloadAllUsers()" [disabled]="loading()" *ngIf="!all_loading"
                >Download All Registered Users</button>
                <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="all_loading"> 
            </div>
            
        </div>

        <!-- download attendance data -->
        <div class="download-section">
            <div class="row">
                <h3 class="header">QR Scanning Data

                    <button (click)="additionalInformation=true"class="questionMark" *ngIf="!additionalInformation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                     </button>


                     <button (click)="additionalInformation=false"class="questionMark" *ngIf="additionalInformation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                     </button>
                    </h3>   





                    
                    <div *ngIf="additionalInformation">
                        <p style="width: 70%;">
                            Some schools opt-in to the QR code scanning feature with SWS. With that, they can see an archive of users entering the building. There is also a feature to mark users as "exiting the building", which schools are also able to see. 
                        </p>

                        <div class="col-sm-10">    
                        </div>
                        <div class="col-sm-2">
                            <!-- <button (click)="additionalInformation=false" class="btn">Cancel</button>
                            <br>
                            <button (click)="deleteAccount()" class="btn confirm-btn">Confirm</button> -->
                        </div>
                    </div>
                    
                    

                    <select [(ngModel)]="month_str"class="textFieldInput">
                        <option *ngFor="let month of months">{{month}}</option>
                    </select>
                
                <div class="breaker">
                </div>

                <select [(ngModel)]="day" class="textFieldInput">
                    <option>1</option> <option>2</option> <option>3</option> <option>4</option> <option>5</option>
                    <option>6</option> <option>7</option> <option>8</option> <option>9</option> <option>10</option>
                    <option>11</option> <option>12</option> <option>13</option> <option>14</option> <option>15</option>
                    <option>16</option> <option>17</option> <option>18</option> <option>19</option> <option>20</option>
                    <option>21</option> <option>22</option> <option>23</option> <option>24</option> <option>25</option>
                    <option>26</option> <option>27</option> <option>28</option> <option>29</option> <option>30</option> <option>31</option>
                </select>
                <div class="breaker">
                </div>

                <select [(ngModel)]="year" class="textFieldInput">
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                </select>
                <select [(ngModel)]="type" style="margin-left:5px; margin-right: 18px; width: 12%;"class="textFieldInput">
                    <option>entering</option>
                    <option>exiting</option>
                </select>
                Sort by:
                <select [(ngModel)]="sort" style="margin-left:5px; width: 12%;" class="textFieldInput"> <!--  -->
                    <option>time</option>
                    <option>alphabetical</option>
                </select>
                <button class="btn download-btn" (click)="downloadAttendance()" [disabled]="(!month || !year || !day || !type || !sort) || loading()" *ngIf="!QR_loading"
                >Download</button>
                <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="QR_loading">
            </div>
            <div class="row alert alert-danger" *ngIf="!recordExistsAttendance">No attendance information available for {{ date }}.</div>
        </div>

        <!-- download archive data -->
        <div class="download-section">
            <div class="row">

                <h3 class="header">Health & Wellness Results
                    <button (click)="additionalInformationHW=true"class="questionMark" *ngIf="!additionalInformationHW">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                        </button>

                        <button (click)="additionalInformationHW=false"class="questionMark" *ngIf="additionalInformationHW">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                              </svg>
    
    
                            </button>

                    </h3> 


                    <div *ngIf="additionalInformationHW">
                        <p style="width: 70%;">
                            With the health and wellness results, you can download the screener results of every user who has completed the app. Via simple spreadsheet mechanics, you can parse through data and have a readily-available archive of each user's screener results.
                        </p>

                        <div class="col-sm-10">    
                        </div>
                        <div class="col-sm-2">
                            <!-- <button (click)="additionalInformation=false" class="btn">Cancel</button>
                            <br>
                            <button (click)="deleteAccount()" class="btn confirm-btn">Confirm</button> -->
                        </div>
                    </div>


                    <select [(ngModel)]="archive_month_str"class="textFieldInput width3">
                        <option *ngFor="let month of months">{{month}}</option>
                    </select>
                    <div class="breaker">
                    </div>
    
                <select [(ngModel)]="archive_day"class="textFieldInput width3">
                    <option>1</option> <option>2</option> <option>3</option> <option>4</option> <option>5</option>
                    <option>6</option> <option>7</option> <option>8</option> <option>9</option> <option>10</option>
                    <option>11</option> <option>12</option> <option>13</option> <option>14</option> <option>15</option>
                    <option>16</option> <option>17</option> <option>18</option> <option>19</option> <option>20</option>
                    <option>21</option> <option>22</option> <option>23</option> <option>24</option> <option>25</option>
                    <option>26</option> <option>27</option> <option>28</option> <option>29</option> <option>30</option> <option>31</option>
                </select>
                <div class="breaker">
                </div>

                <select [(ngModel)]="archive_year"class="textFieldInput width3">
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                </select>

                Sort by:
                <select [(ngModel)]="archive_sort" class="textFieldInput sort">
                    <option>alphabetical</option>
                    <option>grade</option>
                </select>

                <button class="btn download-btn" (click)="downloadArchive()" [disabled]="(!archive_month || !archive_year || !archive_day) || loading()" *ngIf="!screener_loading"
                >Download</button>
                <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="screener_loading">
            </div>
            <div class="row alert alert-danger" *ngIf="!recordExistsArchive">No archive information available for {{ archive_date }}.</div>
        </div>

        <!-- download attendance data -->
        <div class="download-section">
            <div class="row">
                


                <h3 class="header">Completed List of Users
                    <button (click)="additionalInformationCU=true"class="questionMark" *ngIf="!additionalInformationCU">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>

                        </button>

                        <button (click)="additionalInformationCU=false"class="questionMark" *ngIf="additionalInformationCU">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
    
                          </button>
                    </h3> 


                    <div *ngIf="additionalInformationCU">
                        <p style="width: 70%;">
                            Get a list of all the individuals who completed the screener on the specified date -- whether or not they selected an issue.

                        </p>

                        <div class="col-sm-10">    
                        </div>
                        <div class="col-sm-2">
                            <!-- <button (click)="additionalInformation=false" class="btn">Cancel</button>
                            <br>
                            <button (click)="deleteAccount()" class="btn confirm-btn">Confirm</button> -->
                        </div>
                    </div>


                    <select [(ngModel)]="completed_month_str"class="textFieldInput width3">
                        <option *ngFor="let month of months">{{month}}</option>
                    </select>
                <div class="breaker">
                </div>

                <select [(ngModel)]="completed_day"class="textFieldInput width3">
                    <option>1</option> <option>2</option> <option>3</option> <option>4</option> <option>5</option>
                    <option>6</option> <option>7</option> <option>8</option> <option>9</option> <option>10</option>
                    <option>11</option> <option>12</option> <option>13</option> <option>14</option> <option>15</option>
                    <option>16</option> <option>17</option> <option>18</option> <option>19</option> <option>20</option>
                    <option>21</option> <option>22</option> <option>23</option> <option>24</option> <option>25</option>
                    <option>26</option> <option>27</option> <option>28</option> <option>29</option> <option>30</option> <option>31</option>
                </select>
                <div class="breaker">
                </div>

                <select [(ngModel)]="completed_year"class="textFieldInput width3">
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                </select>

                Sort by:
                <select [(ngModel)]="completed_sort" class="textFieldInput sort">
                    <option>alphabetical</option>
                    <option>grade</option>
                </select>

                <button class="btn download-btn" (click)="downloadCompleted()" [disabled]="(!completed_month || !completed_year || !completed_day) || loading()" *ngIf="!completed_loading"
                >Download</button>
                <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="completed_loading">
                <p *ngIf="completed_loading"> {{ completed_loaded }} / {{ completed_length }} </p>
            </div>
            <div class="row alert alert-danger" *ngIf="!recordExistsCompleted">No attendance information available for {{ completed_date }}.</div>
        </div>

        <!-- download attendance data -->
        <div class="download-section">
            <div class="row">

                <h3 class="header">Uncompleted List of Users
                    <button (click)="additionalInformationUNC=true"class="questionMark" *ngIf="!additionalInformationUNC">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                        </button>

                        <button (click)="additionalInformationUNC=false"class="questionMark" *ngIf="additionalInformationUNC">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                              </svg>
                            </button>


                    </h3> 


                    <div *ngIf="additionalInformationUNC">
                        <p style="width: 70%;">
                            Get a list of all the individuals who did not complete the screener on the specified date.      

                        </p>

                        <div class="col-sm-10">    
                        </div>
                        <div class="col-sm-2">
                            <!-- <button (click)="additionalInformation=false" class="btn">Cancel</button>
                            <br>
                            <button (click)="deleteAccount()" class="btn confirm-btn">Confirm</button> -->
                        </div>
                    </div>


                <select [(ngModel)]="uncompleted_month_str"class="textFieldInput width3">
                    <option *ngFor="let month of months">{{month}}</option>
                </select>
                <div class="breaker">
                </div>

                <select [(ngModel)]="uncompleted_day"class="textFieldInput width3">
                    <option>1</option> <option>2</option> <option>3</option> <option>4</option> <option>5</option>
                    <option>6</option> <option>7</option> <option>8</option> <option>9</option> <option>10</option>
                    <option>11</option> <option>12</option> <option>13</option> <option>14</option> <option>15</option>
                    <option>16</option> <option>17</option> <option>18</option> <option>19</option> <option>20</option>
                    <option>21</option> <option>22</option> <option>23</option> <option>24</option> <option>25</option>
                    <option>26</option> <option>27</option> <option>28</option> <option>29</option> <option>30</option> <option>31</option>
                </select>
                 
                <div class="breaker">
                </div>

                <select [(ngModel)]="uncompleted_year"class="textFieldInput width3">
                    <option>2020</option>
                    <option>2021</option>
                    <option>2022</option>
                </select>

                Sort by:
                <select [(ngModel)]="uncompleted_sort" class="textFieldInput sort">
                    <option>alphabetical</option>
                    <option>grade</option>
                </select>

                <button class="btn download-btn" (click)="downloadUncompleted()" 
                        [disabled]="(!uncompleted_month || !uncompleted_day || !uncompleted_year) || loading()" *ngIf="!uncompleted_loading"
                >Download</button>
                <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="uncompleted_loading">
            </div>
            <div class="row alert alert-danger" *ngIf="!recordExistsUncompleted">No attendance information available for {{ uncompleted_date }}.</div>
        </div>


    </div>
    </div>
</body>

</html>
