<html lang="en">
<body>
    <header class="mainHeaderContent">
        <ul class="listContent">
          <h1 class="largeTitle">SWS Dashboard</h1>
          <p class="secondaryTitle">Vaccine Upload</p>

        </ul>
  
    </header>
    <router-outlet></router-outlet>
</body>