<html lang="en">
<head>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>

    <div class="secondaryContainer">
            
        <div class="row">

        <div class="col-sm-12">
            <h3 class="title">Completed!</h3>
            <p class="desc">  
                You have successfully uploaded the COVID-19 vaccination details for Ephraim Zimmerman. 
                Click below to close this window or add attest for another individual. 

            </p>

</div>

</div>

<button class="btn back-btn" (click)="addAnother()"> Add Another Student</button>
<button class="btn update-btn" (click)="closeWindow()"> Close Window</button>
</div>


        
</body>
</html>