import { AngularfireService } from './../angularfire.service';
import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { json2csv } from 'json-2-csv';
import { first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Url } from 'url';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  // admin details
  schoolID: string;
  schoolName: string;
  schoolIcon: Url;

  // auth sub
  authsub: Subscription
  infosub: Subscription

  constructor(
    private authservice: AuthService,
    private router: Router,
    private fireservice: AngularfireService
  ) { }

  ngOnInit(): void {
    this.authsub = this.authservice.schoolID.subscribe(schoolID => {
      this.schoolID = schoolID; 
      this.getSchoolInfo()
    })
    
  }

  ngOnDestroy(): void {
    if(this.authsub) this.authsub.unsubscribe()
    if(this.infosub) this.infosub.unsubscribe()
  }

  getSchoolInfo(){
    if(this.schoolID != '[uninitialized preset]'){
      this.infosub = this.fireservice.getInfo(this.schoolID).subscribe(info => {
        this.schoolName = info["name"]
        this.schoolIcon = info["image"]
      })
    }
  }

  logout(){
    this.authservice.logout()
    this.router.navigate(["login"])
  }

}
