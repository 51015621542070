import { CreateAccountComponent } from './setup/create-account/create-account.component';
import { HomeComponent } from './home/home.component';
import { EmailUncompletedComponent } from './email-uncompleted/email-uncompleted.component';
import { ApplicationStateService } from './application-state.service';
import { MobileMsgComponent } from './mobile-msg/mobile-msg.component';
import { AuthGuardScreenerService } from './auth-guard-screener.service';
import { ScreenerLoginComponent } from './screener-login/screener-login.component';
import { WebScreenerComponent } from './web-screener/web-screener.component';
import { DoneComponent } from './setup/done/done.component';
import { InitialInfoComponent } from './setup/initial-info/initial-info.component';
import { QsComponent } from './setup/qs/qs.component';
import { SetupComponent } from './setup/setup.component';
import { RegisterComponent } from './register/register.component';
import { QuestionsComponent } from './questions/questions.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { ManageAdminsComponent } from './manage-admins/manage-admins.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './users/users.component';
import { DownloadComponent } from './download/download.component';
import { InfoComponent } from './info/info.component';
import { AuthGuardService } from './auth-guard.service';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginVaComponent } from './va/login-va/login-va.component';
import { HeadComponent } from './va/head/head.component';
import { PremadeVaComponent } from './va/premade-va/premade-va.component';
import { UploadCardVaComponent } from './va/upload-card-va/upload-card-va.component';
import { SuccessVaComponent } from './va/success-va/success-va.component';
import { CreateAccountVaComponent } from './va/create-account-va/create-account-va.component';


const routes: Routes = [

  {
    path: 'mobile',
    component: MobileMsgComponent
  },

  // ACCESS POINT PAGES
  {
    path: "",
    redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [ApplicationStateService]
  },
  {
    path: 'reset-pass',
    component: ResetPassComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [ApplicationStateService]
  },


  // WEB SCREENER
  {
    path: 'screener-login',
    component: ScreenerLoginComponent
  },
  {
    path: 'web-screener',
    component: WebScreenerComponent,
    canActivate: [AuthGuardScreenerService] 
  },



  // VACCINE PAGES
  {
    path: 'vaccine-upload',
    component: HeadComponent,
    children: [
      {
        path: '',
        component: LoginVaComponent
      },
      {
        path: 'login',
        component: PremadeVaComponent
      },
      {
        path: 'enter-information',
        component: UploadCardVaComponent
      },
      {
        path: 'create-account',
        component: CreateAccountVaComponent
      },
      {
        path: 'success',
        component: SuccessVaComponent
      }
    ]
  },
  

  // SETUP
  {
    path: 'setup',
    component: SetupComponent,
    canActivate: [ApplicationStateService],
    children: [
      {
        path: 'setup-info',
        component: InitialInfoComponent
      },
      {
        path: 'setup-questions',
        component: QsComponent
      },
      {
        path: 'setup-account',
        component: CreateAccountComponent
      },
      {
        path: 'setup-done',
        component: DoneComponent
      }
    ]
  },




  // ADMIN PAGES
  {
    path: 'admin',
    component: AdminComponent, 
    canActivate: [AuthGuardService, ApplicationStateService],
    children: [
      {
        path: '',
        redirectTo: 'home', pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'download',
        component: DownloadComponent
      },
      {
        path: 'info',
        component: InfoComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'user-detail',
        component: UserDetailComponent
      },
      {
        path: 'manage-admins',
        component: ManageAdminsComponent
      },
      {
        path: 'questions',
        component: QuestionsComponent
      },
      // {
      //   path: 'email-uncompleted',
      //   component: EmailUncompletedComponent,
      //   canActivate: [ApplicationStateService]
      // },
    ] 
  }, 
  {
    path: '**',
    redirectTo: 'login'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
