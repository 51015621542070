import { AngularfireService } from './../../angularfire.service';
import { Router } from '@angular/router';
import { AuthService } from './../../auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { pipe, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-premade-va',
  templateUrl: './premade-va.component.html',
  styleUrls: ['./premade-va.component.scss']
})
export class PremadeVaComponent implements OnInit, OnDestroy {


  authStateSub: Subscription
  loginError = false
  email: string
  password: string

  constructor(
    private authservice: AuthService,
    private fireservice: AngularfireService,
    private router: Router
  ) { 
    authservice.setAuthTypeScreener()
  }

  ngOnInit(): void {
    console.log("Hello")
  }

  ngOnDestroy(): void {
    if(this.authStateSub) this.authStateSub.unsubscribe()
  }

  manageAuthState() {
    this.authStateSub = this.authservice.authState.pipe(first()).subscribe((user) => {
      if(user){
        this.fireservice.getUserDetails(user).pipe(first()).subscribe(userDetails => {
          // if an account exists for this 
          if(userDetails){
              //console.log("user in an admin")
              this.loginError = false;
              this.authservice.setSchoolID(userDetails["school"])
              this.router.navigate(['vaccine-upload/enter-information']); 
          } //else console.log("No user details")
        })
      } //else console.log("no user")
    })
  }

  tryLogin() {
    console.log("Test!!")
    this.loginError = false
    // this.authservice.login(this.email, this.password).then(
    //   (fulfilled) => {
    //     this.manageAuthState()
    //   }, 
    //   (reason) => {
    //     console.log(reason)
    //     this.loginError = true;
    //     this.authservice.logout()
    // })

    console.log("Login pressed.... implement security!!")
    this.router.navigateByUrl("/vaccine-upload/enter-information")
  }

  goBack(){ this.router.navigateByUrl("/vaccine-upload") }

}
