<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">


</head>
<body>

    <div class="mainContainer">
        <div class="row" style="text-align: center;">

            <!-- TODAY -->
            <div class="card">

                <!-- TITLE -->
                <div class="row col-sm-12">
                    <h3 class="mainTitle">TODAY</h3>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <h1 class="big">{{ completedUsers }}</h1> 
                        <h1 class="subLine"> of {{ totalUsers }}</h1>
                        <h3 class="subLineTitle">Screeners completed ({{ ((completedUsers * 1.0 / totalUsers)*100).toFixed(1) }}%)</h3>

                        <br>
                    
                        <h1 class="big">{{ issueUsersDay }}</h1>
                        <h3 href="#" class="subLineTitle">People reporting issues</h3>

                        <br>
               
                        <h1 class="big">{{ scannedUsers }} </h1>
                        <h1 class="subLine"> of {{ totalUsers }}</h1>


                        <div></div>

                        <h3 class="subLineLast">Total scanned into the building ({{ ((scannedUsers * 1.0 / totalUsers)*100).toFixed(1) }}%)</h3>
                       
                        <button (click)="additionalInformation=true"class="questionMark" *ngIf="!additionalInformation" style="margin-top: 50px;">Important Notes
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                              </svg>
                            </button>
    
                         <button (click)="additionalInformation=false"class="questionMark" *ngIf="additionalInformation" style="margin-top: 50px;">Important Notes
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                  </svg>
        
                         </button>

                    <div *ngIf="additionalInformation!">
                        <p style="width: 70%;">
                            The 'registered users' are the total registered accounts, which may include duplicates. The 'total scanned in' includes total users scanned as ‘entering’, this number may be over the total amount of users registered since an individual can be scanned more than once. 

 

                        </p>

                        <div class="col-sm-10">    
                        </div>
                        <div class="col-sm-2">
                            <!-- <button (click)="additionalInformation=false" class="btn">Cancel</button>
                            <br>
                            <button (click)="deleteAccount()" class="btn confirm-btn">Confirm</button> -->
                        </div>
                    </div>


                    </div>


                

                    <div class="col-lg-4" style="margin-left: -200px;">

                        <div class="chart">
                            <canvas baseChart  style="align-items: left;" width="800px"
                            [data]="pieChartData"
                            [labels]="pieChartLabels"
                            [chartType]="pieChartType"
                            [options]="pieChartOptions"
                            [colors]="pieChartColors"
                            [legend]="pieChartLegend">
                            </canvas>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4" style="margin-left: 0px;" *ngIf="noIssueUsersDay != 0"> -->
                    <div class="col-lg-4" style="margin-left: 0px;">
                        <div class="chart" >
                            <canvas baseChart  style="align-items: left;" width="800px"
                            [data]="pieChartData2"
                            [labels]="pieChartLabels2"
                            [chartType]="pieChartType2"
                            [options]="pieChartOptions2"
                            [colors]="pieChartColors2"
                            [legend]="pieChartLegend2">
                            </canvas>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4" *ngIf="noIssueUsersDay==0" style="margin-left: 200px; margin-top: 100px;">
                        <h1 class="big">No cleared users today.</h1>
                    </div> -->

                </div>


            </div>

            <br>

            <!-- <div class="row" style="width: 92%;">

                <div class="col-lg-6" >

                    <div class="card" style="width: 100%;">

                        <div class="row col-sm-12">
                            <h3 class="mainTitle">PAST 7 DAYS</h3><br>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <h1 class="big">{{ issueUsersWeek }}</h1>
                                <h1 href="#" class="subTitle">People have selected one or more symptoms in the past week.</h1>
                                <br><br>
                                <h1 class="bigish">'{{ mostCommonIssueWeek }}'</h1>
                                <h1 href="#" class="subTitle">Was the most frequently selected symptom.</h1>
                            </div>
                        </div>
                        
                        <br>
                        <a (click)="downloadLastWeek()" class="subTitle" style="text-decoration: underline; cursor: pointer;">Download symptoms data from past 14 days -></a>
                        <br>
                        <img src="../../assets/loading_cropped.gif" alt="loading icon" *ngIf="symptomsTwoWeeksLoading">

                    </div>

                </div>

                <div class="col-lg-6" style="margin-left: 0px; margin-right: 0px;">
                    
                    <div class="card" style="width: 100%;">

                        <div class="row col-sm-12">
                            <h3 class="mainTitle">PAST 30 DAYS</h3><br>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <h1 class="big">{{ issueUsersMonth }}</h1>
                                <h1 href="#" class="subTitle">People have selected one or more symptoms in the past month.</h1>
                                <br><br>
                                <h1 class="bigish">'{{ mostCommonIssueMonth }}'</h1>
                                <h1 href="#" class="subTitle">Was the most frequently selected symptom.</h1>
                            </div>
                        </div>

                        <br>

                        <a (click)="downloadLastMonth()" class="subTitle" style="text-decoration: underline; cursor: pointer;">Download symptoms data from past 30 days -></a>
                        <br>
                        <img src="../../assets/loading_cropped.gif" alt="loading icon" *ngIf="symptomsMonthLoading">
                    </div>

                </div>

            </div> -->
            
        </div>

    </div>

    <!-- <div class="mainContainer">
        <div class="row" style="text-align: center;">

            <div class="col-lg-6">
                <div style="display: block">
                    <canvas baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [legend]="barChartLegend"
                        [chartType]="barChartType">
                    </canvas>
                </div>
                <button class="btn" routerLink="../download">Download Screener Data</button>
            </div>

            <div class="col-lg-6">
                <div class="chart">
                    <canvas baseChart
                      [data]="pieChartData"
                      [labels]="pieChartLabels"
                      [chartType]="pieChartType"
                      [options]="pieChartOptions"
                      [colors]="pieChartColors"
                      [legend]="pieChartLegend">
                    </canvas>
                </div>
                <button class="btn" routerLink="../download">Download Screener Data</button>
            </div>

        </div>

        <hr>

        <div class="row">

            <div class="col-sm-4">
                <h3>School Info</h3>
                <p><strong>Name: </strong>{{ schoolInfo["name"] }}</p>
                <p><strong>Admin Email: </strong>{{ schoolInfo["email"] }}</p>
                <p><strong>COVID Policy: </strong>{{ schoolInfo["website"] }}</p>
                <button class="btn" routerLink="../info">Update School Info</button>
            </div>
            
            <div class="col-sm-4">
                <h3>Uncompleted Users</h3>
                <p class="big-text">32</p>
                <button class="btn" routerLink="../email-uncompleted">Send Reminder</button>
            </div>
           
            <div class="col-sm-4">
                <h3>Screener Questions</h3>
                <div *ngFor="let q of screenerQuestions; index as i">
                    <p><strong>Question {{i}}: </strong>{{ q }}</p>
                </div>
                <button class="btn" routerLink="../questions">Update Screener Questions</button>
            </div>

        </div>
    </div> -->

    <div class="mainContainer">
        <div class="row cardEmail">
            <h1 class="mainTitle">SEND REMINDER</h1>

            <!-- SHOW EMAIL BUTTON -->
            <!-- <div class="row" style="margin-bottom: 30px; margin-top: auto;">
                <br>
                <button class="btn" (click)="show_email = true" style="padding: 0px; margin: 0px; margin-left: 13px; background: none; color: black;" *ngIf="!show_email">View/Edit Email Text</button>
                <button class="btn" (click)="show_email = false" style="padding: 0px; margin: 0px; margin-left: 0px; background: none; color: black;" *ngIf="show_email">Hide Email Text</button>
            </div> -->

           <!-- email text -->
            <div class="row" style="width: 100%; margin-left: 0px; margin-bottom: 30px;">
                <p><strong>Subject: </strong>{{ subject }}</p>
                <p><strong>Message: </strong></p>
                <textarea [(ngModel)]="reminder_message" class="textFieldInput"></textarea>

                <button (click)="saveMessage()" class="buttonSend">Save Email</button>


                <div>
                <button class="buttonSend" (click)="confirm_email = true" [disabled]="!email_enabled" *ngIf="!email_loading"
                        >Remind All Uncompleted Users

                     
                    </button>
                        <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="email_loading"> 



                        <button style="margin-top: 25px;" class="buttonSend" (click)="manualSelect = true && loadUncompleted()" *ngIf="!email_loading &&!manualSelect" [disabled]="manualSelect"
                        >Select Uncompleted Users to Remind
               
                        
                        </button>
                        <button style="margin-top: 25px;"  class="buttonSend" (click)="manualSelect = false" *ngIf="manualSelect" >Hide Users</button>









                    </div>

                
                <div *ngIf="savedMessage">Saved!</div>
            </div>

            <!-- BIG headings buttons -->
            <div class="row email-all">
                <div class="col-sm-12">

                    <!-- SEND ALL BUTTON -->
                    <!-- <div class="row">
                        <button class="sendButton" (click)="confirm_email = true" [disabled]="!email_enabled" *ngIf="!email_loading"
                        >Remind All Uncompleted Users

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="topButton" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                          </svg>
                        

                          
                    </button>
                        <img src="../../assets/loading_cropped.gif"  width=100px alt="loading" *ngIf="email_loading"> 
                  
                    </div> -->

                    <!-- confirm send all -->
                    <div *ngIf="confirm_email">
                            <p>You are about to send a reminder email to every user in the school who has not yet filled out the screener today. Do you wish to proceed?</p>
                        <div class="col-sm-2">
                            <button (click)="confirm_email = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px; color: black;">Cancel</button>
                            <button (click)="emailUncompleted()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                        </div>
                    </div>
                    <!-- send all alerts/error messages -->
                    <div class="row success" style="background: #348C80; color: white;" *ngIf="email_sent && !email_error">
                        The emails have successfully been sent. They will be delivered in 1-2 minutes.</div>
                    <div class="row alert alert-danger" *ngIf="email_error">Error! Something went wrong.</div>
                    <div class="row alert alert-danger" *ngIf="no_emails">No emails sent: all users have completed the daily screener.</div>
        
                    <br>

                    <!-- SELECT USERS BUTTON -->
                    <!-- <div class="row">
                        <button class="sendButton" (click)="manualSelect = true && loadUncompleted()" *ngIf="!email_loading &&!manualSelect" [disabled]="manualSelect"
                        >Select Uncompleted Users to Remind
                    
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="topButton" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                          </svg>
                        
                        </button>
                        <button class="sendButton" (click)="manualSelect = false" *ngIf="manualSelect" >Hide Users</button>
                    </div> -->

                    <br>
                </div> 
            </div>


            <div class="search-section" *ngIf="manualSelect">
                <hr>
                <div class="row">
                    <div class="col-sm-10">
                        <label>Search by Name:</label>
                        <input type="text" [(ngModel)]="searchName">
                        <img src="../../assets/black_x.png" width="20px" alt="clear search" (click)="clearSearch()" >
                    </div>
                    <div class="col-sm-2" style="text-align: right;">
                        <button class="btn" style="background: #348C80; margin-top: 30px;" [disabled]="!searchName" (click)="userGroup='name'">Search</button>
                    </div>  
                </div>

                <br>

            </div>


            <!-- SEND BUTTON -->
            <div *ngIf="manualSelect">

                <div class="send-button" style="text-align: center;" >
                    <hr><br>
                    <button class="btn" (click)="confirm_email_selected = true" *ngIf="loading==false" [disabled]="!email_enabled" style="background: #ff6b41;">Send Reminder</button>
                </div>
        
                <div class="row confirm" *ngIf="confirm_email_selected">
                    <div class="col-sm-10" style="padding-top: 10px;">
                        <p>You are about to send a reminder email to all of the selected users. Do you wish to proceed?</p>
                    </div>
                    <div class="col-sm-2">
                        <button (click)="confirm_email_selected = false" class="btn" style="width: 75px; margin: 2px; margin-top: 5px; color: black;">Cancel</button>
                        <button (click)="emailSelected()" class="btn confirm-btn" style="width: 75px; margin: 2px; margin-top: 5px;">Confirm</button>
                    </div>
                </div>
                <div class="row success" style="background: #348C80; color: white;" *ngIf="email_sent_selected && !email_error_selected">
                    The emails have successfully been sent. They will be delivered in 1-2 minutes.
                </div>
                <div class="row alert alert-danger" *ngIf="email_error_selected">Error! Something went wrong.</div>
                <div class="row alert alert-danger" *ngIf="no_emails_selected">No emails sent: please select users to email.</div>    

            </div>
            

            <!-- SELECTED -->
            <div class="users-section" *ngIf="manualSelect && userGroup == 'all'">
                <!-- all users -->
                <div class="user-list">
                    <div *ngFor="let user of uncompleted; index as i;">
                        <div class="row">
                            <div class="col-sm-9">
                                <h3>{{user.name}}</h3>
                                <div *ngFor="let email of user['emails']">
                                    <p>{{email}}</p>
                                </div>
                            </div>
                            <div class="col-sm-3" style="text-align: right;">
                                <button class="btn" *ngIf="uncompleted_selected[i]" (click)="uncompleted_selected[i]=false" style="background: #348C80; margin-top: 30px;">
                                    Deselect</button>
                                <button class="btn" *ngIf="!uncompleted_selected[i]" (click)="uncompleted_selected[i]=true" style="color: black; margin-top: 30px;">
                                    Select</button>
                            </div>
                        </div>
                        <br>
                    </div>
                    <!-- if no matching users -->
                    <div *ngIf="uncompleted.length == 0">
                        <p *ngIf="loading==false">All users have completed the daily screener.</p>
                        <p *ngIf="loading==true">Loading uncompleted users...</p>
                    </div>
                </div>

            </div>



            <!-- SELECTED SEARCH -->
            <div class="users-section" *ngIf="manualSelect && userGroup == 'name'">
                <!-- all users -->
                <div class="user-list">
                    <hr>
                    <div *ngFor="let user of uncompleted; index as i;">
                        <div *ngIf="found(user.name)">
                            <div class="row">
                                <div class="col-sm-9">
                                    <h3>{{user.name}}</h3>
                                    <p>{{user.email}}</p>
                                </div>
                                <div class="col-sm-3" style="text-align: right;">
                                    <button class="btn" *ngIf="uncompleted_selected[i]" (click)="uncompleted_selected[i]=false" style="background: #348C80; margin-top: 30px;">
                                        Deselect</button>
                                    <button class="btn" *ngIf="!uncompleted_selected[i]" (click)="uncompleted_selected[i]=true" style="color: black; margin-top: 30px;">
                                        Select</button>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</body>