<html lang="en">
<head>
    <!-- Latest compiled and minified Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" 
    integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">
</head>
<body>

    <header class="mainHeaderContent">
        <ul class="listContent">
          <h1 class="largeTitle">SWS Dashboard</h1>
          <p class="secondaryTitle">School Setup</p>

        </ul>
  
    </header>
  
    <router-outlet></router-outlet>


    <!-- <div class="mainHeaderContent">
        <h1 class="largeTitle">SWS Dashboard</h1>
        <h1 class="secondaryTitle">School Setup</h1>

    </div> -->
</body>